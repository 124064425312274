/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 public/MShredder/MShredder.gltf -t -r public 
*/

// @ts-ignore
import * as THREE from 'react-three/fiber'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    Body3008_1: THREE.Mesh
    Body3008_2: THREE.Mesh
    Body4008_1: THREE.Mesh
    Body4008_2: THREE.Mesh
    Body1620: THREE.Mesh
    Body1573: THREE.Mesh
    Body1574: THREE.Mesh
    Body1578: THREE.Mesh
    Body1579: THREE.Mesh
    Body1576: THREE.Mesh
    Body1577: THREE.Mesh
    Body1570: THREE.Mesh
    Body1575: THREE.Mesh
    Body1571: THREE.Mesh
    Body1572: THREE.Mesh
    Body1569: THREE.Mesh
    Body1568: THREE.Mesh
    Body1563: THREE.Mesh
    Body1566: THREE.Mesh
    Body1562: THREE.Mesh
    Body1567: THREE.Mesh
    Body1565: THREE.Mesh
    Body1561: THREE.Mesh
    Body1564: THREE.Mesh
    Body1560: THREE.Mesh
    Body1533: THREE.Mesh
    Body1534: THREE.Mesh
    Body1537: THREE.Mesh
    Body1538: THREE.Mesh
    Body1535: THREE.Mesh
    Body10008: THREE.Mesh
    Body11008: THREE.Mesh
    Body12008: THREE.Mesh
    Body13008: THREE.Mesh
    Body14007: THREE.Mesh
    Body15007: THREE.Mesh
    Body16007: THREE.Mesh
    Body2033: THREE.Mesh
    Body3012: THREE.Mesh
    Body4012: THREE.Mesh
    Body5012: THREE.Mesh
    Body6012: THREE.Mesh
    Body7012: THREE.Mesh
    Body8012: THREE.Mesh
    Body9008: THREE.Mesh
    Body1536: THREE.Mesh
    Body1530: THREE.Mesh
    Body1531: THREE.Mesh
    Body1532: THREE.Mesh
    Body1539: THREE.Mesh
    Body1521: THREE.Mesh
    Body1524: THREE.Mesh
    Body10005: THREE.Mesh
    Body11005: THREE.Mesh
    Body12005: THREE.Mesh
    Body13005: THREE.Mesh
    Body2030: THREE.Mesh
    Body3009: THREE.Mesh
    Body4009: THREE.Mesh
    Body5009: THREE.Mesh
    Body6009: THREE.Mesh
    Body7009: THREE.Mesh
    Body8009: THREE.Mesh
    Body9005: THREE.Mesh
    Body1523: THREE.Mesh
    Body1525: THREE.Mesh
    Body1526: THREE.Mesh
    Body1527: THREE.Mesh
    Body1522: THREE.Mesh
    Body1542: THREE.Mesh
    Body1544: THREE.Mesh
    Body1185_1: THREE.Mesh
    Body1185_2: THREE.Mesh
    Body1185_3: THREE.Mesh
    Body1185_4: THREE.Mesh
    Body1185_5: THREE.Mesh
    Body10009: THREE.Mesh
    Body11006_2: THREE.Mesh
    Body11006_3: THREE.Mesh
    Body12006_1: THREE.Mesh
    Body12006_2: THREE.Mesh
    Body2012_1: THREE.Mesh
    Body2012_2: THREE.Mesh
    Body2012_3: THREE.Mesh
    Body3007_1: THREE.Mesh
    Body3007_2: THREE.Mesh
    Body4007_1: THREE.Mesh
    Body4007_2: THREE.Mesh
    Body4007_3: THREE.Mesh
    Body5007_1: THREE.Mesh
    Body5007_2: THREE.Mesh
    Body5007_3: THREE.Mesh
    Body6013: THREE.Mesh
    Body7013: THREE.Mesh
    Body8007_1: THREE.Mesh
    Body8007_2: THREE.Mesh
    Body8007_3: THREE.Mesh
    Body9009: THREE.Mesh
    Body1422: THREE.Mesh
    Body1433: THREE.Mesh
    Body1434: THREE.Mesh
    Body1437: THREE.Mesh
    Body1439: THREE.Mesh
    Body1440: THREE.Mesh
    Body1428: THREE.Mesh
    Body1443: THREE.Mesh
    Body1431: THREE.Mesh
    Body1442: THREE.Mesh
    Body1441: THREE.Mesh
    Body1423: THREE.Mesh
    Body1444: THREE.Mesh
    Body1446: THREE.Mesh
    Body1447: THREE.Mesh
    Body1438: THREE.Mesh
    Body1445: THREE.Mesh
    Body1452: THREE.Mesh
    Body1453: THREE.Mesh
    Body1448: THREE.Mesh
    Body1454: THREE.Mesh
    Body1455: THREE.Mesh
    Body1424: THREE.Mesh
    Body1449: THREE.Mesh
    Body1450: THREE.Mesh
    Body1458: THREE.Mesh
    Body1451: THREE.Mesh
    Body1456: THREE.Mesh
    Body1459: THREE.Mesh
    Body1462: THREE.Mesh
    Body1460: THREE.Mesh
    Body1457: THREE.Mesh
    Body1464: THREE.Mesh
    Body1425: THREE.Mesh
    Body1467: THREE.Mesh
    Body1461: THREE.Mesh
    Body1465: THREE.Mesh
    Body1466: THREE.Mesh
    Body1470: THREE.Mesh
    Body1471: THREE.Mesh
    Body1469: THREE.Mesh
    Body1473: THREE.Mesh
    Body1468: THREE.Mesh
    Body1474: THREE.Mesh
    Body1426: THREE.Mesh
    Body1476: THREE.Mesh
    Body1477: THREE.Mesh
    Body1435: THREE.Mesh
    Body1480: THREE.Mesh
    Body1481: THREE.Mesh
    Body1482: THREE.Mesh
    Body1479: THREE.Mesh
    Body1483: THREE.Mesh
    Body1484: THREE.Mesh
    Body1486: THREE.Mesh
    Body1427: THREE.Mesh
    Body1487: THREE.Mesh
    Body1488: THREE.Mesh
    Body1485: THREE.Mesh
    Body1489: THREE.Mesh
    Body1490: THREE.Mesh
    Body1491: THREE.Mesh
    Body1492: THREE.Mesh
    Body1494: THREE.Mesh
    Body1495: THREE.Mesh
    Body1493: THREE.Mesh
    Body1429: THREE.Mesh
    Body1496: THREE.Mesh
    Body1498: THREE.Mesh
    Body1499: THREE.Mesh
    Body1500: THREE.Mesh
    Body1497: THREE.Mesh
    Body1501: THREE.Mesh
    Body1503: THREE.Mesh
    Body1504: THREE.Mesh
    Body1505: THREE.Mesh
    Body1472: THREE.Mesh
    Body1430: THREE.Mesh
    Body1506: THREE.Mesh
    Body1508: THREE.Mesh
    Body1509: THREE.Mesh
    Body1475: THREE.Mesh
    Body1507: THREE.Mesh
    Body1502: THREE.Mesh
    Body1513: THREE.Mesh
    Body1514: THREE.Mesh
    Body1512: THREE.Mesh
    Body1510: THREE.Mesh
    Body1432: THREE.Mesh
    Body1516: THREE.Mesh
    Body1515: THREE.Mesh
    Body1519: THREE.Mesh
    Body1478: THREE.Mesh
    Body1463: THREE.Mesh
    Body1511: THREE.Mesh
    Body1520: THREE.Mesh
    Body1518: THREE.Mesh
    Body1517: THREE.Mesh
    Body1436: THREE.Mesh
    Body1421: THREE.Mesh
    Body1529: THREE.Mesh
    Body10007: THREE.Mesh
    Body11007: THREE.Mesh
    Body12007: THREE.Mesh
    Body13007: THREE.Mesh
    Body14006: THREE.Mesh
    Body15006: THREE.Mesh
    Body16006: THREE.Mesh
    Body2032: THREE.Mesh
    Body3011: THREE.Mesh
    Body4011: THREE.Mesh
    Body5011: THREE.Mesh
    Body6011: THREE.Mesh
    Body7011: THREE.Mesh
    Body8011: THREE.Mesh
    Body9007: THREE.Mesh
    Body1553: THREE.Mesh
    Body1417: THREE.Mesh
    Body1418: THREE.Mesh
    Body1419: THREE.Mesh
    Body1414: THREE.Mesh
    Body1415: THREE.Mesh
    Body1416: THREE.Mesh
    Body1420: THREE.Mesh
    Body1528: THREE.Mesh
    Body10006: THREE.Mesh
    Body100001: THREE.Mesh
    Body101001: THREE.Mesh
    Body102001: THREE.Mesh
    Body103001: THREE.Mesh
    Body104001: THREE.Mesh
    Body105001: THREE.Mesh
    Body106001: THREE.Mesh
    Body107001: THREE.Mesh
    Body108001: THREE.Mesh
    Body109001: THREE.Mesh
    Body11003_2: THREE.Mesh
    Body11003_3: THREE.Mesh
    Body110_1: THREE.Mesh
    Body110_2: THREE.Mesh
    Body111: THREE.Mesh
    Body112_1: THREE.Mesh
    Body112_2: THREE.Mesh
    Body112_3: THREE.Mesh
    Body113: THREE.Mesh
    Body114_1: THREE.Mesh
    Body114_2: THREE.Mesh
    Body115: THREE.Mesh
    Body116: THREE.Mesh
    Body117_1: THREE.Mesh
    Body117_2: THREE.Mesh
    Body118: THREE.Mesh
    Body119: THREE.Mesh
    Body12003_1: THREE.Mesh
    Body12003_2: THREE.Mesh
    Body120: THREE.Mesh
    Body121: THREE.Mesh
    Body122: THREE.Mesh
    Body123: THREE.Mesh
    Body124: THREE.Mesh
    Body125: THREE.Mesh
    Body126: THREE.Mesh
    Body127: THREE.Mesh
    Body128: THREE.Mesh
    Body129: THREE.Mesh
    Body13006: THREE.Mesh
    Body130: THREE.Mesh
    Body131: THREE.Mesh
    Body132: THREE.Mesh
    Body133: THREE.Mesh
    Body134_1: THREE.Mesh
    Body134_2: THREE.Mesh
    Body135: THREE.Mesh
    Body136: THREE.Mesh
    Body137: THREE.Mesh
    Body138: THREE.Mesh
    Body139_1: THREE.Mesh
    Body139_2: THREE.Mesh
    Body14005: THREE.Mesh
    Body140: THREE.Mesh
    Body141: THREE.Mesh
    Body142: THREE.Mesh
    Body143_1: THREE.Mesh
    Body143_2: THREE.Mesh
    Body144: THREE.Mesh
    Body145: THREE.Mesh
    Body146: THREE.Mesh
    Body147: THREE.Mesh
    Body148: THREE.Mesh
    Body149: THREE.Mesh
    Body15002_1: THREE.Mesh
    Body15002_2: THREE.Mesh
    Body150: THREE.Mesh
    Body151: THREE.Mesh
    Body152: THREE.Mesh
    Body153_1: THREE.Mesh
    Body153_2: THREE.Mesh
    Body154: THREE.Mesh
    Body155: THREE.Mesh
    Body156: THREE.Mesh
    Body157: THREE.Mesh
    Body158: THREE.Mesh
    Body159: THREE.Mesh
    Body16005: THREE.Mesh
    Body160: THREE.Mesh
    Body161: THREE.Mesh
    Body162: THREE.Mesh
    Body163: THREE.Mesh
    Body164: THREE.Mesh
    Body165: THREE.Mesh
    Body166: THREE.Mesh
    Body167_1: THREE.Mesh
    Body167_2: THREE.Mesh
    Body168: THREE.Mesh
    Body169_1: THREE.Mesh
    Body169_2: THREE.Mesh
    Body17005: THREE.Mesh
    Body170: THREE.Mesh
    Body171: THREE.Mesh
    Body172: THREE.Mesh
    Body173: THREE.Mesh
    Body174: THREE.Mesh
    Body175: THREE.Mesh
    Body176: THREE.Mesh
    Body177: THREE.Mesh
    Body178: THREE.Mesh
    Body179: THREE.Mesh
    Body18001: THREE.Mesh
    Body180_1: THREE.Mesh
    Body180_2: THREE.Mesh
    Body181: THREE.Mesh
    Body182: THREE.Mesh
    Body183: THREE.Mesh
    Body184: THREE.Mesh
    Body185: THREE.Mesh
    Body186: THREE.Mesh
    Body187: THREE.Mesh
    Body188: THREE.Mesh
    Body189: THREE.Mesh
    Body19001: THREE.Mesh
    Body190: THREE.Mesh
    Body191: THREE.Mesh
    Body192: THREE.Mesh
    Body193: THREE.Mesh
    Body194: THREE.Mesh
    Body195: THREE.Mesh
    Body196: THREE.Mesh
    Body197: THREE.Mesh
    Body198: THREE.Mesh
    Body199: THREE.Mesh
    Body2031: THREE.Mesh
    Body20001: THREE.Mesh
    Body200: THREE.Mesh
    Body201: THREE.Mesh
    Body202: THREE.Mesh
    Body203: THREE.Mesh
    Body204: THREE.Mesh
    Body205: THREE.Mesh
    Body206: THREE.Mesh
    Body207: THREE.Mesh
    Body208: THREE.Mesh
    Body209: THREE.Mesh
    Body21001: THREE.Mesh
    Body210: THREE.Mesh
    Body211: THREE.Mesh
    Body212: THREE.Mesh
    Body213: THREE.Mesh
    Body214: THREE.Mesh
    Body215: THREE.Mesh
    Body216: THREE.Mesh
    Body217: THREE.Mesh
    Body218: THREE.Mesh
    Body219: THREE.Mesh
    Body22001_1: THREE.Mesh
    Body22001_2: THREE.Mesh
    Body220: THREE.Mesh
    Body221: THREE.Mesh
    Body222: THREE.Mesh
    Body223_1: THREE.Mesh
    Body223_2: THREE.Mesh
    Body224: THREE.Mesh
    Body225: THREE.Mesh
    Body226: THREE.Mesh
    Body227: THREE.Mesh
    Body228: THREE.Mesh
    Body229: THREE.Mesh
    Body23001: THREE.Mesh
    Body230: THREE.Mesh
    Body231: THREE.Mesh
    Body232: THREE.Mesh
    Body233: THREE.Mesh
    Body234: THREE.Mesh
    Body235: THREE.Mesh
    Body236_1: THREE.Mesh
    Body236_2: THREE.Mesh
    Body237_1: THREE.Mesh
    Body237_2: THREE.Mesh
    Body238: THREE.Mesh
    Body239: THREE.Mesh
    Body24001: THREE.Mesh
    Body240_1: THREE.Mesh
    Body240_2: THREE.Mesh
    Body241: THREE.Mesh
    Body242: THREE.Mesh
    Body243: THREE.Mesh
    Body244_1: THREE.Mesh
    Body244_2: THREE.Mesh
    Body245: THREE.Mesh
    Body246: THREE.Mesh
    Body247: THREE.Mesh
    Body248: THREE.Mesh
    Body249: THREE.Mesh
    Body25001: THREE.Mesh
    Body250: THREE.Mesh
    Body251: THREE.Mesh
    Body252: THREE.Mesh
    Body253: THREE.Mesh
    Body254: THREE.Mesh
    Body255: THREE.Mesh
    Body256: THREE.Mesh
    Body257: THREE.Mesh
    Body258: THREE.Mesh
    Body259_1: THREE.Mesh
    Body259_2: THREE.Mesh
    Body26001: THREE.Mesh
    Body260: THREE.Mesh
    Body261: THREE.Mesh
    Body262: THREE.Mesh
    Body263: THREE.Mesh
    Body264: THREE.Mesh
    Body265: THREE.Mesh
    Body266_1: THREE.Mesh
    Body266_2: THREE.Mesh
    Body267: THREE.Mesh
    Body268_1: THREE.Mesh
    Body268_2: THREE.Mesh
    Body269: THREE.Mesh
    Body27001: THREE.Mesh
    Body270: THREE.Mesh
    Body271: THREE.Mesh
    Body272: THREE.Mesh
    Body273_1: THREE.Mesh
    Body273_2: THREE.Mesh
    Body274: THREE.Mesh
    Body275: THREE.Mesh
    Body276: THREE.Mesh
    Body277: THREE.Mesh
    Body278: THREE.Mesh
    Body279: THREE.Mesh
    Body28001: THREE.Mesh
    Body280_1: THREE.Mesh
    Body280_2: THREE.Mesh
    Body281: THREE.Mesh
    Body282: THREE.Mesh
    Body283: THREE.Mesh
    Body284: THREE.Mesh
    Body285: THREE.Mesh
    Body286: THREE.Mesh
    Body287: THREE.Mesh
    Body288: THREE.Mesh
    Body289: THREE.Mesh
    Body29001: THREE.Mesh
    Body290: THREE.Mesh
    Body291: THREE.Mesh
    Body292: THREE.Mesh
    Body293: THREE.Mesh
    Body294: THREE.Mesh
    Body295: THREE.Mesh
    Body296: THREE.Mesh
    Body297: THREE.Mesh
    Body298_1: THREE.Mesh
    Body298_2: THREE.Mesh
    Body299: THREE.Mesh
    Body3010: THREE.Mesh
    Body30001: THREE.Mesh
    Body300: THREE.Mesh
    Body301: THREE.Mesh
    Body302: THREE.Mesh
    Body303: THREE.Mesh
    Body304: THREE.Mesh
    Body305_1: THREE.Mesh
    Body305_2: THREE.Mesh
    Body306: THREE.Mesh
    Body307: THREE.Mesh
    Body308: THREE.Mesh
    Body309: THREE.Mesh
    Body31001: THREE.Mesh
    Body310: THREE.Mesh
    Body311: THREE.Mesh
    Body312: THREE.Mesh
    Body313_1: THREE.Mesh
    Body313_2: THREE.Mesh
    Body314: THREE.Mesh
    Body315: THREE.Mesh
    Body316: THREE.Mesh
    Body317: THREE.Mesh
    Body318_1: THREE.Mesh
    Body318_2: THREE.Mesh
    Body319: THREE.Mesh
    Body32001_1: THREE.Mesh
    Body32001_2: THREE.Mesh
    Body320: THREE.Mesh
    Body321_1: THREE.Mesh
    Body321_2: THREE.Mesh
    Body322: THREE.Mesh
    Body323: THREE.Mesh
    Body324: THREE.Mesh
    Body325: THREE.Mesh
    Body326: THREE.Mesh
    Body327: THREE.Mesh
    Body328: THREE.Mesh
    Body329_1: THREE.Mesh
    Body329_2: THREE.Mesh
    Body33001: THREE.Mesh
    Body330: THREE.Mesh
    Body331: THREE.Mesh
    Body332: THREE.Mesh
    Body333: THREE.Mesh
    Body334: THREE.Mesh
    Body335: THREE.Mesh
    Body336: THREE.Mesh
    Body337: THREE.Mesh
    Body338: THREE.Mesh
    Body339: THREE.Mesh
    Body34001: THREE.Mesh
    Body340: THREE.Mesh
    Body341_1: THREE.Mesh
    Body341_2: THREE.Mesh
    Body342: THREE.Mesh
    Body343: THREE.Mesh
    Body344: THREE.Mesh
    Body345: THREE.Mesh
    Body346: THREE.Mesh
    Body347: THREE.Mesh
    Body348: THREE.Mesh
    Body349: THREE.Mesh
    Body35001: THREE.Mesh
    Body350: THREE.Mesh
    Body351: THREE.Mesh
    Body352: THREE.Mesh
    Body353: THREE.Mesh
    Body354: THREE.Mesh
    Body355: THREE.Mesh
    Body356: THREE.Mesh
    Body357: THREE.Mesh
    Body358: THREE.Mesh
    Body359: THREE.Mesh
    Body36001: THREE.Mesh
    Body360: THREE.Mesh
    Body361: THREE.Mesh
    Body362: THREE.Mesh
    Body363: THREE.Mesh
    Body364: THREE.Mesh
    Body365: THREE.Mesh
    Body366: THREE.Mesh
    Body367_1: THREE.Mesh
    Body367_2: THREE.Mesh
    Body368: THREE.Mesh
    Body369: THREE.Mesh
    Body37001: THREE.Mesh
    Body370: THREE.Mesh
    Body371: THREE.Mesh
    Body372: THREE.Mesh
    Body373: THREE.Mesh
    Body374: THREE.Mesh
    Body375: THREE.Mesh
    Body376: THREE.Mesh
    Body377: THREE.Mesh
    Body378: THREE.Mesh
    Body379: THREE.Mesh
    Body38001: THREE.Mesh
    Body380: THREE.Mesh
    Body381: THREE.Mesh
    Body382: THREE.Mesh
    Body383: THREE.Mesh
    Body384: THREE.Mesh
    Body385: THREE.Mesh
    Body386: THREE.Mesh
    Body387: THREE.Mesh
    Body388: THREE.Mesh
    Body389: THREE.Mesh
    Body39001: THREE.Mesh
    Body390: THREE.Mesh
    Body391: THREE.Mesh
    Body392: THREE.Mesh
    Body393: THREE.Mesh
    Body394: THREE.Mesh
    Body395: THREE.Mesh
    Body396: THREE.Mesh
    Body397: THREE.Mesh
    Body398: THREE.Mesh
    Body399: THREE.Mesh
    Body4010: THREE.Mesh
    Body40001_1: THREE.Mesh
    Body40001_2: THREE.Mesh
    Body400: THREE.Mesh
    Body401: THREE.Mesh
    Body402: THREE.Mesh
    Body403: THREE.Mesh
    Body41001: THREE.Mesh
    Body42001: THREE.Mesh
    Body43001: THREE.Mesh
    Body44001: THREE.Mesh
    Body45001: THREE.Mesh
    Body46001_1: THREE.Mesh
    Body46001_2: THREE.Mesh
    Body47001: THREE.Mesh
    Body48001: THREE.Mesh
    Body49001: THREE.Mesh
    Body5010: THREE.Mesh
    Body50001: THREE.Mesh
    Body51001: THREE.Mesh
    Body52001: THREE.Mesh
    Body53001: THREE.Mesh
    Body54001: THREE.Mesh
    Body55001_1: THREE.Mesh
    Body55001_2: THREE.Mesh
    Body56001: THREE.Mesh
    Body57001: THREE.Mesh
    Body58001_1: THREE.Mesh
    Body58001_2: THREE.Mesh
    Body59001: THREE.Mesh
    Body6010: THREE.Mesh
    Body60001: THREE.Mesh
    Body61001: THREE.Mesh
    Body62001: THREE.Mesh
    Body63001: THREE.Mesh
    Body64001: THREE.Mesh
    Body65001: THREE.Mesh
    Body66001: THREE.Mesh
    Body67001: THREE.Mesh
    Body68001: THREE.Mesh
    Body69001: THREE.Mesh
    Body7010: THREE.Mesh
    Body70001_1: THREE.Mesh
    Body70001_2: THREE.Mesh
    Body71001: THREE.Mesh
    Body72001: THREE.Mesh
    Body73001: THREE.Mesh
    Body74001: THREE.Mesh
    Body75001: THREE.Mesh
    Body76001: THREE.Mesh
    Body77001: THREE.Mesh
    Body78001: THREE.Mesh
    Body79001: THREE.Mesh
    Body8010: THREE.Mesh
    Body80001: THREE.Mesh
    Body81001: THREE.Mesh
    Body82001: THREE.Mesh
    Body83001: THREE.Mesh
    Body84001: THREE.Mesh
    Body85001: THREE.Mesh
    Body86001: THREE.Mesh
    Body87001: THREE.Mesh
    Body88001: THREE.Mesh
    Body89001_1: THREE.Mesh
    Body89001_2: THREE.Mesh
    Body9006: THREE.Mesh
    Body90001: THREE.Mesh
    Body91001: THREE.Mesh
    Body92001_1: THREE.Mesh
    Body92001_2: THREE.Mesh
    Body93001: THREE.Mesh
    Body94001: THREE.Mesh
    Body95001_1: THREE.Mesh
    Body95001_2: THREE.Mesh
    Body96001: THREE.Mesh
    Body97001: THREE.Mesh
    Body98001_1: THREE.Mesh
    Body98001_2: THREE.Mesh
    Body99001: THREE.Mesh
    Body1413: THREE.Mesh
    Body10004: THREE.Mesh
    Body100: THREE.Mesh
    Body101: THREE.Mesh
    Body102: THREE.Mesh
    Body103: THREE.Mesh
    Body104: THREE.Mesh
    Body105: THREE.Mesh
    Body106: THREE.Mesh
    Body107: THREE.Mesh
    Body108: THREE.Mesh
    Body109: THREE.Mesh
    Body11004: THREE.Mesh
    Body12004: THREE.Mesh
    Body13004: THREE.Mesh
    Body14004: THREE.Mesh
    Body15004: THREE.Mesh
    Body16004: THREE.Mesh
    Body17004: THREE.Mesh
    Body18: THREE.Mesh
    Body19: THREE.Mesh
    Body2029: THREE.Mesh
    Body20: THREE.Mesh
    Body21: THREE.Mesh
    Body22: THREE.Mesh
    Body23: THREE.Mesh
    Body24: THREE.Mesh
    Body25: THREE.Mesh
    Body26: THREE.Mesh
    Body27: THREE.Mesh
    Body28: THREE.Mesh
    Body29: THREE.Mesh
    Body3008: THREE.Mesh
    Body30: THREE.Mesh
    Body31: THREE.Mesh
    Body32: THREE.Mesh
    Body33: THREE.Mesh
    Body34: THREE.Mesh
    Body35: THREE.Mesh
    Body36: THREE.Mesh
    Body37: THREE.Mesh
    Body38: THREE.Mesh
    Body39: THREE.Mesh
    Body4008: THREE.Mesh
    Body40: THREE.Mesh
    Body41: THREE.Mesh
    Body42: THREE.Mesh
    Body43: THREE.Mesh
    Body44: THREE.Mesh
    Body45: THREE.Mesh
    Body46: THREE.Mesh
    Body47: THREE.Mesh
    Body48: THREE.Mesh
    Body49: THREE.Mesh
    Body5008: THREE.Mesh
    Body50: THREE.Mesh
    Body51: THREE.Mesh
    Body52: THREE.Mesh
    Body53: THREE.Mesh
    Body54: THREE.Mesh
    Body55: THREE.Mesh
    Body56: THREE.Mesh
    Body57: THREE.Mesh
    Body58: THREE.Mesh
    Body59: THREE.Mesh
    Body6008: THREE.Mesh
    Body60: THREE.Mesh
    Body61: THREE.Mesh
    Body62: THREE.Mesh
    Body63: THREE.Mesh
    Body64: THREE.Mesh
    Body65: THREE.Mesh
    Body66: THREE.Mesh
    Body67: THREE.Mesh
    Body68: THREE.Mesh
    Body69: THREE.Mesh
    Body7008: THREE.Mesh
    Body70: THREE.Mesh
    Body71: THREE.Mesh
    Body72: THREE.Mesh
    Body73: THREE.Mesh
    Body74: THREE.Mesh
    Body75: THREE.Mesh
    Body76: THREE.Mesh
    Body77: THREE.Mesh
    Body78: THREE.Mesh
    Body79: THREE.Mesh
    Body8008: THREE.Mesh
    Body80: THREE.Mesh
    Body81: THREE.Mesh
    Body82: THREE.Mesh
    Body83: THREE.Mesh
    Body84: THREE.Mesh
    Body85: THREE.Mesh
    Body86: THREE.Mesh
    Body87: THREE.Mesh
    Body88: THREE.Mesh
    Body89: THREE.Mesh
    Body9004: THREE.Mesh
    Body90: THREE.Mesh
    Body91: THREE.Mesh
    Body92: THREE.Mesh
    Body93: THREE.Mesh
    Body94: THREE.Mesh
    Body95: THREE.Mesh
    Body96: THREE.Mesh
    Body97: THREE.Mesh
    Body98: THREE.Mesh
    Body99: THREE.Mesh
    Body1558: THREE.Mesh
    Body1557: THREE.Mesh
    Body1552: THREE.Mesh
    Body1551: THREE.Mesh
    Body1545: THREE.Mesh
    Body10010: THREE.Mesh
    Body11010: THREE.Mesh
    Body12010: THREE.Mesh
    Body13009: THREE.Mesh
    Body14008: THREE.Mesh
    Body15005_1: THREE.Mesh
    Body15005_2: THREE.Mesh
    Body16008: THREE.Mesh
    Body17006: THREE.Mesh
    Body18002: THREE.Mesh
    Body2035: THREE.Mesh
    Body21002: THREE.Mesh
    Body22002: THREE.Mesh
    Body23002: THREE.Mesh
    Body24002: THREE.Mesh
    Body25002: THREE.Mesh
    Body5014: THREE.Mesh
    Body6014: THREE.Mesh
    Body7014: THREE.Mesh
    Body8014: THREE.Mesh
    Body9010: THREE.Mesh
    Body1556: THREE.Mesh
    Body10018: THREE.Mesh
    Body11018: THREE.Mesh
    Body12018: THREE.Mesh
    Body13017: THREE.Mesh
    Body14016: THREE.Mesh
    Body16016: THREE.Mesh
    Body17014: THREE.Mesh
    Body18010: THREE.Mesh
    Body2043: THREE.Mesh
    Body21010: THREE.Mesh
    Body22010: THREE.Mesh
    Body23010: THREE.Mesh
    Body24010: THREE.Mesh
    Body25010: THREE.Mesh
    Body5022: THREE.Mesh
    Body6022: THREE.Mesh
    Body7022: THREE.Mesh
    Body8022: THREE.Mesh
    Body9018: THREE.Mesh
    Body1548: THREE.Mesh
    Body10013: THREE.Mesh
    Body11013: THREE.Mesh
    Body12013: THREE.Mesh
    Body13012: THREE.Mesh
    Body14011: THREE.Mesh
    Body16011: THREE.Mesh
    Body17009: THREE.Mesh
    Body18005: THREE.Mesh
    Body2038: THREE.Mesh
    Body21005: THREE.Mesh
    Body22005: THREE.Mesh
    Body23005: THREE.Mesh
    Body24005: THREE.Mesh
    Body25005: THREE.Mesh
    Body5017: THREE.Mesh
    Body6017: THREE.Mesh
    Body7017: THREE.Mesh
    Body8017: THREE.Mesh
    Body9013: THREE.Mesh
    Body1549: THREE.Mesh
    Body10014: THREE.Mesh
    Body11014: THREE.Mesh
    Body12014: THREE.Mesh
    Body13013: THREE.Mesh
    Body14012: THREE.Mesh
    Body16012: THREE.Mesh
    Body17010: THREE.Mesh
    Body18006: THREE.Mesh
    Body2039: THREE.Mesh
    Body21006: THREE.Mesh
    Body22006: THREE.Mesh
    Body23006: THREE.Mesh
    Body24006: THREE.Mesh
    Body25006: THREE.Mesh
    Body5018: THREE.Mesh
    Body6018: THREE.Mesh
    Body7018: THREE.Mesh
    Body8018: THREE.Mesh
    Body9014: THREE.Mesh
    Body1550: THREE.Mesh
    Body10015: THREE.Mesh
    Body11015: THREE.Mesh
    Body12015: THREE.Mesh
    Body13014: THREE.Mesh
    Body14013: THREE.Mesh
    Body16013: THREE.Mesh
    Body17011: THREE.Mesh
    Body18007: THREE.Mesh
    Body2040: THREE.Mesh
    Body21007: THREE.Mesh
    Body22007: THREE.Mesh
    Body23007: THREE.Mesh
    Body24007: THREE.Mesh
    Body25007: THREE.Mesh
    Body5019: THREE.Mesh
    Body6019: THREE.Mesh
    Body7019: THREE.Mesh
    Body8019: THREE.Mesh
    Body9015: THREE.Mesh
    Body1547: THREE.Mesh
    Body10012: THREE.Mesh
    Body11012: THREE.Mesh
    Body12012: THREE.Mesh
    Body13011: THREE.Mesh
    Body14010: THREE.Mesh
    Body16010: THREE.Mesh
    Body17008: THREE.Mesh
    Body18004: THREE.Mesh
    Body2037: THREE.Mesh
    Body21004: THREE.Mesh
    Body22004: THREE.Mesh
    Body23004: THREE.Mesh
    Body24004: THREE.Mesh
    Body25004: THREE.Mesh
    Body5016: THREE.Mesh
    Body6016: THREE.Mesh
    Body7016: THREE.Mesh
    Body8016: THREE.Mesh
    Body9012: THREE.Mesh
    Body1546: THREE.Mesh
    Body10011: THREE.Mesh
    Body11011: THREE.Mesh
    Body12011: THREE.Mesh
    Body13010: THREE.Mesh
    Body14009: THREE.Mesh
    Body16009: THREE.Mesh
    Body17007: THREE.Mesh
    Body18003: THREE.Mesh
    Body2036: THREE.Mesh
    Body21003: THREE.Mesh
    Body22003: THREE.Mesh
    Body23003: THREE.Mesh
    Body24003: THREE.Mesh
    Body25003: THREE.Mesh
    Body5015: THREE.Mesh
    Body6015: THREE.Mesh
    Body7015: THREE.Mesh
    Body8015: THREE.Mesh
    Body9011: THREE.Mesh
    Body1559: THREE.Mesh
    Body10019: THREE.Mesh
    Body11019: THREE.Mesh
    Body12019: THREE.Mesh
    Body13018: THREE.Mesh
    Body14017: THREE.Mesh
    Body16017: THREE.Mesh
    Body17015: THREE.Mesh
    Body18011: THREE.Mesh
    Body2044: THREE.Mesh
    Body21011: THREE.Mesh
    Body22011: THREE.Mesh
    Body23011: THREE.Mesh
    Body24011: THREE.Mesh
    Body25011: THREE.Mesh
    Body5023: THREE.Mesh
    Body6023: THREE.Mesh
    Body7023: THREE.Mesh
    Body8023: THREE.Mesh
    Body9019: THREE.Mesh
    Body1554: THREE.Mesh
    Body10016: THREE.Mesh
    Body11016: THREE.Mesh
    Body12016: THREE.Mesh
    Body13015: THREE.Mesh
    Body14014: THREE.Mesh
    Body16014: THREE.Mesh
    Body17012: THREE.Mesh
    Body18008: THREE.Mesh
    Body2041: THREE.Mesh
    Body21008: THREE.Mesh
    Body22008: THREE.Mesh
    Body23008: THREE.Mesh
    Body24008: THREE.Mesh
    Body25008: THREE.Mesh
    Body5020: THREE.Mesh
    Body6020: THREE.Mesh
    Body7020: THREE.Mesh
    Body8020: THREE.Mesh
    Body9016: THREE.Mesh
    Body1555: THREE.Mesh
    Body10017: THREE.Mesh
    Body11017: THREE.Mesh
    Body12017: THREE.Mesh
    Body13016: THREE.Mesh
    Body14015: THREE.Mesh
    Body16015: THREE.Mesh
    Body17013: THREE.Mesh
    Body18009: THREE.Mesh
    Body2042: THREE.Mesh
    Body21009: THREE.Mesh
    Body22009: THREE.Mesh
    Body23009: THREE.Mesh
    Body24009: THREE.Mesh
    Body25009: THREE.Mesh
    Body5021: THREE.Mesh
    Body6021: THREE.Mesh
    Body7021: THREE.Mesh
    Body8021: THREE.Mesh
    Body9017: THREE.Mesh
    Body1543: THREE.Mesh
    Body1541: THREE.Mesh
    Body1582: THREE.Mesh
    Body1581: THREE.Mesh
    Body1583: THREE.Mesh
    Body1580: THREE.Mesh
    Body1602: THREE.Mesh
    Body1603: THREE.Mesh
    Body1605: THREE.Mesh
    Body1607: THREE.Mesh
    Body1614: THREE.Mesh
    Body1612: THREE.Mesh
    Body1611: THREE.Mesh
    Body1610: THREE.Mesh
    Body1609: THREE.Mesh
    Body1613: THREE.Mesh
    Body1606: THREE.Mesh
    Body1608: THREE.Mesh
    Body1617: THREE.Mesh
    Body1619: THREE.Mesh
    Body1618: THREE.Mesh
    Body1616: THREE.Mesh
    Body1615: THREE.Mesh
    Body1604: THREE.Mesh
    Body1584: THREE.Mesh
    Body1599: THREE.Mesh
    Body1592: THREE.Mesh
    Body1593: THREE.Mesh
    Body1594: THREE.Mesh
    Body1595: THREE.Mesh
    Body1596: THREE.Mesh
    Body1601: THREE.Mesh
    Body1597: THREE.Mesh
    Body1588: THREE.Mesh
    Body1591: THREE.Mesh
    Body1600: THREE.Mesh
    Body1585: THREE.Mesh
    Body1587: THREE.Mesh
    Body1586: THREE.Mesh
    Body1589: THREE.Mesh
    Body1590: THREE.Mesh
    Body1598: THREE.Mesh
    Body1412: THREE.Mesh
    Body1410: THREE.Mesh
    Body1409: THREE.Mesh
    Body1411: THREE.Mesh
    Body2: THREE.Mesh
    Body1003: THREE.Mesh
    Body1004: THREE.Mesh
    Body1005: THREE.Mesh
    Body1007: THREE.Mesh
    Body1006: THREE.Mesh
    Body1: THREE.Mesh
    Body2025: THREE.Mesh
    Body2026: THREE.Mesh
    Body2027: THREE.Mesh
    Body2028: THREE.Mesh
    Body1008: THREE.Mesh
    Body1407: THREE.Mesh
    Body1408: THREE.Mesh
    Body1313: THREE.Mesh
    Body1388: THREE.Mesh
    Body1389: THREE.Mesh
    Body1314: THREE.Mesh
    Body1329: THREE.Mesh
    Body1323: THREE.Mesh
    Body1321: THREE.Mesh
    Body2021: THREE.Mesh
    Body3006: THREE.Mesh
    Body4006: THREE.Mesh
    Body5006: THREE.Mesh
    Body6006: THREE.Mesh
    Body7006: THREE.Mesh
    Body8006: THREE.Mesh
    Body1322: THREE.Mesh
    Body1320: THREE.Mesh
    Body1326: THREE.Mesh
    Body1393: THREE.Mesh
    Body1315: THREE.Mesh
    Body1390: THREE.Mesh
    Body1328: THREE.Mesh
    Body1394: THREE.Mesh
    Body1361: THREE.Mesh
    Body1373: THREE.Mesh
    Body1374: THREE.Mesh
    Body1375: THREE.Mesh
    Body1372: THREE.Mesh
    Body1358: THREE.Mesh
    Body1360: THREE.Mesh
    Body1359: THREE.Mesh
    Body1353: THREE.Mesh
    Body1348: THREE.Mesh
    Body1349: THREE.Mesh
    Body1347: THREE.Mesh
    Body1357: THREE.Mesh
    Body1352: THREE.Mesh
    Body1351: THREE.Mesh
    Body1350: THREE.Mesh
    Body1366: THREE.Mesh
    Body1368: THREE.Mesh
    Body1365: THREE.Mesh
    Body1332: THREE.Mesh
    Body1334: THREE.Mesh
    Body1340: THREE.Mesh
    Body1341: THREE.Mesh
    Body1342: THREE.Mesh
    Body1343: THREE.Mesh
    Body1344: THREE.Mesh
    Body1345: THREE.Mesh
    Body1346: THREE.Mesh
    Body1333: THREE.Mesh
    Body1335: THREE.Mesh
    Body1336: THREE.Mesh
    Body1337: THREE.Mesh
    Body1338: THREE.Mesh
    Body1339: THREE.Mesh
    Body1331: THREE.Mesh
    Body1330: THREE.Mesh
    Body1367: THREE.Mesh
    Body1364: THREE.Mesh
    Body1355: THREE.Mesh
    Body1370: THREE.Mesh
    Body1371: THREE.Mesh
    Body1354: THREE.Mesh
    Body1369: THREE.Mesh
    Body1356: THREE.Mesh
    Body1376: THREE.Mesh
    Body1363: THREE.Mesh
    Body1362: THREE.Mesh
    Body1377: THREE.Mesh
    Body10003: THREE.Mesh
    Body11003: THREE.Mesh
    Body12003: THREE.Mesh
    Body13003: THREE.Mesh
    Body14003: THREE.Mesh
    Body15003: THREE.Mesh
    Body16003: THREE.Mesh
    Body17003: THREE.Mesh
    Body2022: THREE.Mesh
    Body3007: THREE.Mesh
    Body4007: THREE.Mesh
    Body5007: THREE.Mesh
    Body6007: THREE.Mesh
    Body7007: THREE.Mesh
    Body8007: THREE.Mesh
    Body9003: THREE.Mesh
    Body1318: THREE.Mesh
    Body1317: THREE.Mesh
    Body1316: THREE.Mesh
    Body2020: THREE.Mesh
    Body1309: THREE.Mesh
    Body1379: THREE.Mesh
    Body1380: THREE.Mesh
    Body1381: THREE.Mesh
    Body1382: THREE.Mesh
    Body1310: THREE.Mesh
    Body1395: THREE.Mesh
    Body1396: THREE.Mesh
    Body1397: THREE.Mesh
    Body1398: THREE.Mesh
    Body1399: THREE.Mesh
    Body1311: THREE.Mesh
    Body1383: THREE.Mesh
    Body1384: THREE.Mesh
    Body1385: THREE.Mesh
    Body1378: THREE.Mesh
    Body1400: THREE.Mesh
    Body1401: THREE.Mesh
    Body1402: THREE.Mesh
    Body1403: THREE.Mesh
    Body1404: THREE.Mesh
    Body1319: THREE.Mesh
    Body1391: THREE.Mesh
    Body1312: THREE.Mesh
    Body2019: THREE.Mesh
    Body1386: THREE.Mesh
    Body2023: THREE.Mesh
    Body1387: THREE.Mesh
    Body2024: THREE.Mesh
    Body1406: THREE.Mesh
    Body1405: THREE.Mesh
    Body1325: THREE.Mesh
    Body1327: THREE.Mesh
    Body1307: THREE.Mesh
    Body1308: THREE.Mesh
    Body1213: THREE.Mesh
    Body1288: THREE.Mesh
    Body1289: THREE.Mesh
    Body1214: THREE.Mesh
    Body1229: THREE.Mesh
    Body1223: THREE.Mesh
    Body1221: THREE.Mesh
    Body2015: THREE.Mesh
    Body3004: THREE.Mesh
    Body4004: THREE.Mesh
    Body5004: THREE.Mesh
    Body6004: THREE.Mesh
    Body7004: THREE.Mesh
    Body8004: THREE.Mesh
    Body1222: THREE.Mesh
    Body1220: THREE.Mesh
    Body1226: THREE.Mesh
    Body1293: THREE.Mesh
    Body1215: THREE.Mesh
    Body1290: THREE.Mesh
    Body1228: THREE.Mesh
    Body1294: THREE.Mesh
    Body1261: THREE.Mesh
    Body1273: THREE.Mesh
    Body1274: THREE.Mesh
    Body1275: THREE.Mesh
    Body1272: THREE.Mesh
    Body1258: THREE.Mesh
    Body1260: THREE.Mesh
    Body1259: THREE.Mesh
    Body1253: THREE.Mesh
    Body1248: THREE.Mesh
    Body1249: THREE.Mesh
    Body1247: THREE.Mesh
    Body1257: THREE.Mesh
    Body1252: THREE.Mesh
    Body1251: THREE.Mesh
    Body1250: THREE.Mesh
    Body1266: THREE.Mesh
    Body1268: THREE.Mesh
    Body1265: THREE.Mesh
    Body1232: THREE.Mesh
    Body1234: THREE.Mesh
    Body1240: THREE.Mesh
    Body1241: THREE.Mesh
    Body1242: THREE.Mesh
    Body1243: THREE.Mesh
    Body1244: THREE.Mesh
    Body1245: THREE.Mesh
    Body1246: THREE.Mesh
    Body1233: THREE.Mesh
    Body1235: THREE.Mesh
    Body1236: THREE.Mesh
    Body1237: THREE.Mesh
    Body1238: THREE.Mesh
    Body1239: THREE.Mesh
    Body1231: THREE.Mesh
    Body1230: THREE.Mesh
    Body1267: THREE.Mesh
    Body1264: THREE.Mesh
    Body1255: THREE.Mesh
    Body1270: THREE.Mesh
    Body1271: THREE.Mesh
    Body1254: THREE.Mesh
    Body1269: THREE.Mesh
    Body1256: THREE.Mesh
    Body1276: THREE.Mesh
    Body1263: THREE.Mesh
    Body1262: THREE.Mesh
    Body1277: THREE.Mesh
    Body10002: THREE.Mesh
    Body11002: THREE.Mesh
    Body12002: THREE.Mesh
    Body13002: THREE.Mesh
    Body14002: THREE.Mesh
    Body15002: THREE.Mesh
    Body16002: THREE.Mesh
    Body17002: THREE.Mesh
    Body2016: THREE.Mesh
    Body3005: THREE.Mesh
    Body4005: THREE.Mesh
    Body5005: THREE.Mesh
    Body6005: THREE.Mesh
    Body7005: THREE.Mesh
    Body8005: THREE.Mesh
    Body9002: THREE.Mesh
    Body1218: THREE.Mesh
    Body1217: THREE.Mesh
    Body1216: THREE.Mesh
    Body2014: THREE.Mesh
    Body1209: THREE.Mesh
    Body1279: THREE.Mesh
    Body1280: THREE.Mesh
    Body1281: THREE.Mesh
    Body1282: THREE.Mesh
    Body1210: THREE.Mesh
    Body1295: THREE.Mesh
    Body1296: THREE.Mesh
    Body1297: THREE.Mesh
    Body1298: THREE.Mesh
    Body1299: THREE.Mesh
    Body1211: THREE.Mesh
    Body1283: THREE.Mesh
    Body1284: THREE.Mesh
    Body1285: THREE.Mesh
    Body1278: THREE.Mesh
    Body1300: THREE.Mesh
    Body1301: THREE.Mesh
    Body1302: THREE.Mesh
    Body1303: THREE.Mesh
    Body1304: THREE.Mesh
    Body1219: THREE.Mesh
    Body1291: THREE.Mesh
    Body1212: THREE.Mesh
    Body2013: THREE.Mesh
    Body1286: THREE.Mesh
    Body2017: THREE.Mesh
    Body1287: THREE.Mesh
    Body2018: THREE.Mesh
    Body1306: THREE.Mesh
    Body1305: THREE.Mesh
    Body1225: THREE.Mesh
    Body1227: THREE.Mesh
    Body1207: THREE.Mesh
    Body1208: THREE.Mesh
    Body1113: THREE.Mesh
    Body1188: THREE.Mesh
    Body1189: THREE.Mesh
    Body1114: THREE.Mesh
    Body1129: THREE.Mesh
    Body1123: THREE.Mesh
    Body1121: THREE.Mesh
    Body2009: THREE.Mesh
    Body3002: THREE.Mesh
    Body4002: THREE.Mesh
    Body5002: THREE.Mesh
    Body6002: THREE.Mesh
    Body7002: THREE.Mesh
    Body8002: THREE.Mesh
    Body1122: THREE.Mesh
    Body1120: THREE.Mesh
    Body1126: THREE.Mesh
    Body1193: THREE.Mesh
    Body1115: THREE.Mesh
    Body1190: THREE.Mesh
    Body1128: THREE.Mesh
    Body1194: THREE.Mesh
    Body1161: THREE.Mesh
    Body1173: THREE.Mesh
    Body1174: THREE.Mesh
    Body1175: THREE.Mesh
    Body1172: THREE.Mesh
    Body1158: THREE.Mesh
    Body1160: THREE.Mesh
    Body1159: THREE.Mesh
    Body1153: THREE.Mesh
    Body1148: THREE.Mesh
    Body1149: THREE.Mesh
    Body1147: THREE.Mesh
    Body1157: THREE.Mesh
    Body1152: THREE.Mesh
    Body1151: THREE.Mesh
    Body1150: THREE.Mesh
    Body1166: THREE.Mesh
    Body1168: THREE.Mesh
    Body1165: THREE.Mesh
    Body1132: THREE.Mesh
    Body1134: THREE.Mesh
    Body1140: THREE.Mesh
    Body1141: THREE.Mesh
    Body1142: THREE.Mesh
    Body1143: THREE.Mesh
    Body1144: THREE.Mesh
    Body1145: THREE.Mesh
    Body1146: THREE.Mesh
    Body1133: THREE.Mesh
    Body1135: THREE.Mesh
    Body1136: THREE.Mesh
    Body1137: THREE.Mesh
    Body1138: THREE.Mesh
    Body1139: THREE.Mesh
    Body1131: THREE.Mesh
    Body1130: THREE.Mesh
    Body1167: THREE.Mesh
    Body1164: THREE.Mesh
    Body1155: THREE.Mesh
    Body1170: THREE.Mesh
    Body1171: THREE.Mesh
    Body1154: THREE.Mesh
    Body1169: THREE.Mesh
    Body1156: THREE.Mesh
    Body1176: THREE.Mesh
    Body1163: THREE.Mesh
    Body1162: THREE.Mesh
    Body1177: THREE.Mesh
    Body10001: THREE.Mesh
    Body11001: THREE.Mesh
    Body12001: THREE.Mesh
    Body13001: THREE.Mesh
    Body14001: THREE.Mesh
    Body15001: THREE.Mesh
    Body16001: THREE.Mesh
    Body17001: THREE.Mesh
    Body2010: THREE.Mesh
    Body3003: THREE.Mesh
    Body4003: THREE.Mesh
    Body5003: THREE.Mesh
    Body6003: THREE.Mesh
    Body7003: THREE.Mesh
    Body8003: THREE.Mesh
    Body9001: THREE.Mesh
    Body1118: THREE.Mesh
    Body1117: THREE.Mesh
    Body1116: THREE.Mesh
    Body2008: THREE.Mesh
    Body1109: THREE.Mesh
    Body1179: THREE.Mesh
    Body1180: THREE.Mesh
    Body1181: THREE.Mesh
    Body1182: THREE.Mesh
    Body1110: THREE.Mesh
    Body1195: THREE.Mesh
    Body1196: THREE.Mesh
    Body1197: THREE.Mesh
    Body1198: THREE.Mesh
    Body1199: THREE.Mesh
    Body1111: THREE.Mesh
    Body1183: THREE.Mesh
    Body1184: THREE.Mesh
    Body1185: THREE.Mesh
    Body1178: THREE.Mesh
    Body1200: THREE.Mesh
    Body1201: THREE.Mesh
    Body1202: THREE.Mesh
    Body1203: THREE.Mesh
    Body1204: THREE.Mesh
    Body1119: THREE.Mesh
    Body1191: THREE.Mesh
    Body1112: THREE.Mesh
    Body2007: THREE.Mesh
    Body1186: THREE.Mesh
    Body2011: THREE.Mesh
    Body1187: THREE.Mesh
    Body2012: THREE.Mesh
    Body1206: THREE.Mesh
    Body1205: THREE.Mesh
    Body1125: THREE.Mesh
    Body1127: THREE.Mesh
    Body1107: THREE.Mesh
    Body1108: THREE.Mesh
    Body1013: THREE.Mesh
    Body1088: THREE.Mesh
    Body1089: THREE.Mesh
    Body1014: THREE.Mesh
    Body1029: THREE.Mesh
    Body1023: THREE.Mesh
    Body1021: THREE.Mesh
    Body2003: THREE.Mesh
    Body3: THREE.Mesh
    Body4: THREE.Mesh
    Body5: THREE.Mesh
    Body6: THREE.Mesh
    Body7: THREE.Mesh
    Body8: THREE.Mesh
    Body1022: THREE.Mesh
    Body1020: THREE.Mesh
    Body1026: THREE.Mesh
    Body1093: THREE.Mesh
    Body1015: THREE.Mesh
    Body1090: THREE.Mesh
    Body1028: THREE.Mesh
    Body1094: THREE.Mesh
    Body1061: THREE.Mesh
    Body1073: THREE.Mesh
    Body1074: THREE.Mesh
    Body1075: THREE.Mesh
    Body1072: THREE.Mesh
    Body1058: THREE.Mesh
    Body1060: THREE.Mesh
    Body1059: THREE.Mesh
    Body1053: THREE.Mesh
    Body1048: THREE.Mesh
    Body1049: THREE.Mesh
    Body1047: THREE.Mesh
    Body1057: THREE.Mesh
    Body1052: THREE.Mesh
    Body1051: THREE.Mesh
    Body1050: THREE.Mesh
    Body1066: THREE.Mesh
    Body1068: THREE.Mesh
    Body1065: THREE.Mesh
    Body1032: THREE.Mesh
    Body1034: THREE.Mesh
    Body1040: THREE.Mesh
    Body1041: THREE.Mesh
    Body1042: THREE.Mesh
    Body1043: THREE.Mesh
    Body1044: THREE.Mesh
    Body1045: THREE.Mesh
    Body1046: THREE.Mesh
    Body1033: THREE.Mesh
    Body1035: THREE.Mesh
    Body1036: THREE.Mesh
    Body1037: THREE.Mesh
    Body1038: THREE.Mesh
    Body1039: THREE.Mesh
    Body1031: THREE.Mesh
    Body1030: THREE.Mesh
    Body1067: THREE.Mesh
    Body1064: THREE.Mesh
    Body1055: THREE.Mesh
    Body1070: THREE.Mesh
    Body1071: THREE.Mesh
    Body1054: THREE.Mesh
    Body1069: THREE.Mesh
    Body1056: THREE.Mesh
    Body1076: THREE.Mesh
    Body1063: THREE.Mesh
    Body1062: THREE.Mesh
    Body1077: THREE.Mesh
    Body10: THREE.Mesh
    Body11: THREE.Mesh
    Body12: THREE.Mesh
    Body13: THREE.Mesh
    Body14: THREE.Mesh
    Body15: THREE.Mesh
    Body16: THREE.Mesh
    Body17: THREE.Mesh
    Body2004: THREE.Mesh
    Body3001: THREE.Mesh
    Body4001: THREE.Mesh
    Body5001: THREE.Mesh
    Body6001: THREE.Mesh
    Body7001: THREE.Mesh
    Body8001: THREE.Mesh
    Body9: THREE.Mesh
    Body1018: THREE.Mesh
    Body1017: THREE.Mesh
    Body1016: THREE.Mesh
    Body2002: THREE.Mesh
    Body1021_1: THREE.Mesh
    Body1021_2: THREE.Mesh
    Body1009: THREE.Mesh
    Body1079: THREE.Mesh
    Body1080: THREE.Mesh
    Body1081: THREE.Mesh
    Body1082: THREE.Mesh
    Body1010: THREE.Mesh
    Body1095: THREE.Mesh
    Body1096: THREE.Mesh
    Body1097: THREE.Mesh
    Body1098: THREE.Mesh
    Body1099: THREE.Mesh
    Body1011: THREE.Mesh
    Body1083: THREE.Mesh
    Body1084: THREE.Mesh
    Body1085: THREE.Mesh
    Body1078: THREE.Mesh
    Body1100: THREE.Mesh
    Body1101: THREE.Mesh
    Body1102: THREE.Mesh
    Body1103: THREE.Mesh
    Body1104: THREE.Mesh
    Body1019: THREE.Mesh
    Body1091: THREE.Mesh
    Body1012: THREE.Mesh
    Body2001: THREE.Mesh
    Body1086: THREE.Mesh
    Body2005: THREE.Mesh
    Body1087: THREE.Mesh
    Body2006: THREE.Mesh
    Body1106: THREE.Mesh
    Body1105: THREE.Mesh
    Body1025: THREE.Mesh
    Body1027: THREE.Mesh
    Body1001: THREE.Mesh
    Body1002: THREE.Mesh
    Body1934: THREE.Mesh
    Body1938: THREE.Mesh
    Body1937: THREE.Mesh
    Body1952: THREE.Mesh
    Body1951: THREE.Mesh
    Body1935: THREE.Mesh
    Body1969: THREE.Mesh
    Body1980: THREE.Mesh
    Body1973: THREE.Mesh
    Body1977: THREE.Mesh
    Body1936: THREE.Mesh
    Body1978: THREE.Mesh
    Body1968: THREE.Mesh
    Body1971: THREE.Mesh
    Body1972: THREE.Mesh
    Body1941: THREE.Mesh
    Body1940: THREE.Mesh
    Body1943: THREE.Mesh
    Body1939: THREE.Mesh
    Body1942: THREE.Mesh
    Body1944: THREE.Mesh
    Body1946: THREE.Mesh
    Body1947: THREE.Mesh
    Body1945: THREE.Mesh
    Body1961: THREE.Mesh
    Body1962: THREE.Mesh
    Body1960: THREE.Mesh
    Body1954: THREE.Mesh
    Body1955: THREE.Mesh
    Body1956: THREE.Mesh
    Body1963: THREE.Mesh
    Body1958: THREE.Mesh
    Body1959: THREE.Mesh
    Body1957: THREE.Mesh
    Body1964: THREE.Mesh
    Body1970: THREE.Mesh
    Body1966: THREE.Mesh
    Body1967: THREE.Mesh
    Body1924: THREE.Mesh
    Body1932: THREE.Mesh
    Body1950: THREE.Mesh
    Body1953: THREE.Mesh
    Body1926: THREE.Mesh
    Body1925: THREE.Mesh
    Body1933: THREE.Mesh
    Body1928: THREE.Mesh
    Body1929: THREE.Mesh
    Body1931: THREE.Mesh
    Body1930: THREE.Mesh
    Body1927: THREE.Mesh
    Body1965: THREE.Mesh
    Body1976: THREE.Mesh
    Body1975: THREE.Mesh
    Body1981: THREE.Mesh
    Body1982: THREE.Mesh
    Body1948: THREE.Mesh
    Body1949: THREE.Mesh
    Body1979: THREE.Mesh
    Body2072: THREE.Mesh
    Body1974: THREE.Mesh
    Body2071: THREE.Mesh
    Body11044: THREE.Mesh
    Body1983: THREE.Mesh
    Body11099: THREE.Mesh
    Body11043: THREE.Mesh
    Body2075: THREE.Mesh
    Body11047: THREE.Mesh
    Body11046: THREE.Mesh
    Body11045: THREE.Mesh
    Body11073: THREE.Mesh
    Body11063: THREE.Mesh
    Body11093: THREE.Mesh
    Body11080: THREE.Mesh
    Body11081: THREE.Mesh
    Body11082: THREE.Mesh
    Body11083: THREE.Mesh
    Body11084: THREE.Mesh
    Body11085: THREE.Mesh
    Body11086: THREE.Mesh
    Body11092: THREE.Mesh
    Body1333_1: THREE.Mesh
    Body1333_2: THREE.Mesh
    Body1334_1: THREE.Mesh
    Body1334_2: THREE.Mesh
    Body11061: THREE.Mesh
    Body2077: THREE.Mesh
    Body3037: THREE.Mesh
    Body4028: THREE.Mesh
    Body5028: THREE.Mesh
    Body6028: THREE.Mesh
    Body7028: THREE.Mesh
    Body11067: THREE.Mesh
    Body10024: THREE.Mesh
    Body11024: THREE.Mesh
    Body2078: THREE.Mesh
    Body3038: THREE.Mesh
    Body4029: THREE.Mesh
    Body5029: THREE.Mesh
    Body6029: THREE.Mesh
    Body7029: THREE.Mesh
    Body8028: THREE.Mesh
    Body9024: THREE.Mesh
    Body1339_1: THREE.Mesh
    Body1339_2: THREE.Mesh
    Body1346_1: THREE.Mesh
    Body1346_2: THREE.Mesh
    Body2027_1: THREE.Mesh
    Body2027_2: THREE.Mesh
    Body3019_1: THREE.Mesh
    Body3019_2: THREE.Mesh
    Body3019_3: THREE.Mesh
    Body1341_1: THREE.Mesh
    Body1341_2: THREE.Mesh
    Body1343_1: THREE.Mesh
    Body1343_2: THREE.Mesh
    Body1338_1: THREE.Mesh
    Body1338_2: THREE.Mesh
    Body1345_1: THREE.Mesh
    Body1345_2: THREE.Mesh
    Body1337_1: THREE.Mesh
    Body1337_2: THREE.Mesh
    Body1337_3: THREE.Mesh
    Body11075: THREE.Mesh
    Body10025: THREE.Mesh
    Body11025: THREE.Mesh
    Body12024: THREE.Mesh
    Body13023: THREE.Mesh
    Body14023: THREE.Mesh
    Body15022: THREE.Mesh
    Body16022: THREE.Mesh
    Body17018: THREE.Mesh
    Body2079: THREE.Mesh
    Body3039: THREE.Mesh
    Body4030: THREE.Mesh
    Body5030: THREE.Mesh
    Body6030: THREE.Mesh
    Body7030: THREE.Mesh
    Body8029: THREE.Mesh
    Body9025: THREE.Mesh
    Body11071: THREE.Mesh
    Body11088: THREE.Mesh
    Body11062: THREE.Mesh
    Body11078: THREE.Mesh
    Body11079: THREE.Mesh
    Body11066: THREE.Mesh
    Body1998: THREE.Mesh
    Body1999: THREE.Mesh
    Body11000: THREE.Mesh
    Body11001_1: THREE.Mesh
    Body11009_1: THREE.Mesh
    Body11010_1: THREE.Mesh
    Body11011_1: THREE.Mesh
    Body11012_1: THREE.Mesh
    Body11008_1: THREE.Mesh
    Body11003_1: THREE.Mesh
    Body11004_1: THREE.Mesh
    Body11005_1: THREE.Mesh
    Body11006_1: THREE.Mesh
    Body11007_1: THREE.Mesh
    Body11002_1: THREE.Mesh
    Body11013_1: THREE.Mesh
    Body11034_1: THREE.Mesh
    Body11035: THREE.Mesh
    Body11036: THREE.Mesh
    Body11037: THREE.Mesh
    Body11038: THREE.Mesh
    Body11039: THREE.Mesh
    Body11040: THREE.Mesh
    Body11041: THREE.Mesh
    Body11042: THREE.Mesh
    Body11025_1: THREE.Mesh
    Body11018_1: THREE.Mesh
    Body11026_1: THREE.Mesh
    Body10022: THREE.Mesh
    Body11022: THREE.Mesh
    Body12022: THREE.Mesh
    Body13021: THREE.Mesh
    Body14021: THREE.Mesh
    Body15020: THREE.Mesh
    Body16020: THREE.Mesh
    Body2074: THREE.Mesh
    Body3035: THREE.Mesh
    Body4026: THREE.Mesh
    Body5026: THREE.Mesh
    Body6026: THREE.Mesh
    Body7026: THREE.Mesh
    Body8026: THREE.Mesh
    Body9022: THREE.Mesh
    Body11024_1: THREE.Mesh
    Body11033_1: THREE.Mesh
    Body11015_1: THREE.Mesh
    Body10021: THREE.Mesh
    Body100002: THREE.Mesh
    Body101002: THREE.Mesh
    Body102002: THREE.Mesh
    Body103002: THREE.Mesh
    Body104002: THREE.Mesh
    Body105002: THREE.Mesh
    Body106002: THREE.Mesh
    Body107002: THREE.Mesh
    Body108002: THREE.Mesh
    Body109002: THREE.Mesh
    Body11021: THREE.Mesh
    Body110001: THREE.Mesh
    Body111001: THREE.Mesh
    Body112001: THREE.Mesh
    Body113001: THREE.Mesh
    Body114001: THREE.Mesh
    Body115001: THREE.Mesh
    Body116001: THREE.Mesh
    Body117001: THREE.Mesh
    Body118001: THREE.Mesh
    Body119001: THREE.Mesh
    Body12021: THREE.Mesh
    Body120001: THREE.Mesh
    Body121001: THREE.Mesh
    Body122001: THREE.Mesh
    Body123001: THREE.Mesh
    Body124001: THREE.Mesh
    Body125001: THREE.Mesh
    Body126001: THREE.Mesh
    Body127001: THREE.Mesh
    Body128001: THREE.Mesh
    Body129001: THREE.Mesh
    Body13020: THREE.Mesh
    Body130001: THREE.Mesh
    Body131001: THREE.Mesh
    Body132001: THREE.Mesh
    Body133001: THREE.Mesh
    Body134001: THREE.Mesh
    Body135001: THREE.Mesh
    Body136001: THREE.Mesh
    Body137001: THREE.Mesh
    Body138001: THREE.Mesh
    Body139001: THREE.Mesh
    Body14020: THREE.Mesh
    Body140001: THREE.Mesh
    Body141001: THREE.Mesh
    Body142001: THREE.Mesh
    Body143001: THREE.Mesh
    Body144001: THREE.Mesh
    Body145001: THREE.Mesh
    Body146001: THREE.Mesh
    Body147001: THREE.Mesh
    Body148001: THREE.Mesh
    Body149001: THREE.Mesh
    Body15019: THREE.Mesh
    Body150001: THREE.Mesh
    Body151001: THREE.Mesh
    Body152001: THREE.Mesh
    Body153001: THREE.Mesh
    Body154001: THREE.Mesh
    Body155001: THREE.Mesh
    Body156001: THREE.Mesh
    Body157001: THREE.Mesh
    Body158001: THREE.Mesh
    Body159001: THREE.Mesh
    Body16019: THREE.Mesh
    Body160001: THREE.Mesh
    Body161001: THREE.Mesh
    Body162001: THREE.Mesh
    Body163001: THREE.Mesh
    Body164001: THREE.Mesh
    Body165001: THREE.Mesh
    Body166001: THREE.Mesh
    Body167001: THREE.Mesh
    Body168001: THREE.Mesh
    Body169001: THREE.Mesh
    Body17017: THREE.Mesh
    Body170001: THREE.Mesh
    Body171001: THREE.Mesh
    Body172001: THREE.Mesh
    Body173001: THREE.Mesh
    Body174001: THREE.Mesh
    Body175001: THREE.Mesh
    Body176001: THREE.Mesh
    Body177001: THREE.Mesh
    Body178001: THREE.Mesh
    Body179001: THREE.Mesh
    Body18013: THREE.Mesh
    Body180001: THREE.Mesh
    Body181001: THREE.Mesh
    Body182001: THREE.Mesh
    Body183001: THREE.Mesh
    Body184001: THREE.Mesh
    Body185001: THREE.Mesh
    Body186001: THREE.Mesh
    Body187001: THREE.Mesh
    Body188001: THREE.Mesh
    Body189001: THREE.Mesh
    Body19013: THREE.Mesh
    Body190001: THREE.Mesh
    Body191001: THREE.Mesh
    Body192001: THREE.Mesh
    Body193001: THREE.Mesh
    Body194001: THREE.Mesh
    Body195001: THREE.Mesh
    Body196001: THREE.Mesh
    Body197001: THREE.Mesh
    Body198001: THREE.Mesh
    Body199001: THREE.Mesh
    Body2021_1: THREE.Mesh
    Body2021_2: THREE.Mesh
    Body2021_3: THREE.Mesh
    Body2021_4: THREE.Mesh
    Body2021_5: THREE.Mesh
    Body2021_6: THREE.Mesh
    Body2021_7: THREE.Mesh
    Body2021_8: THREE.Mesh
    Body20002: THREE.Mesh
    Body200001: THREE.Mesh
    Body201001: THREE.Mesh
    Body202001: THREE.Mesh
    Body203001: THREE.Mesh
    Body204001: THREE.Mesh
    Body205001: THREE.Mesh
    Body206001: THREE.Mesh
    Body207001: THREE.Mesh
    Body208001: THREE.Mesh
    Body209001: THREE.Mesh
    Body21013: THREE.Mesh
    Body210001: THREE.Mesh
    Body211001: THREE.Mesh
    Body212001: THREE.Mesh
    Body213001: THREE.Mesh
    Body214001: THREE.Mesh
    Body215001: THREE.Mesh
    Body216001: THREE.Mesh
    Body217001: THREE.Mesh
    Body218001: THREE.Mesh
    Body219001: THREE.Mesh
    Body22013: THREE.Mesh
    Body220001: THREE.Mesh
    Body221001: THREE.Mesh
    Body222001: THREE.Mesh
    Body223001: THREE.Mesh
    Body224001: THREE.Mesh
    Body225001: THREE.Mesh
    Body226001: THREE.Mesh
    Body227001: THREE.Mesh
    Body228001: THREE.Mesh
    Body229001: THREE.Mesh
    Body23013: THREE.Mesh
    Body230001: THREE.Mesh
    Body231001: THREE.Mesh
    Body232001: THREE.Mesh
    Body233001: THREE.Mesh
    Body234001: THREE.Mesh
    Body235001: THREE.Mesh
    Body236001: THREE.Mesh
    Body237001: THREE.Mesh
    Body238001: THREE.Mesh
    Body239001: THREE.Mesh
    Body24013: THREE.Mesh
    Body240001: THREE.Mesh
    Body25013: THREE.Mesh
    Body26002: THREE.Mesh
    Body27002: THREE.Mesh
    Body28002: THREE.Mesh
    Body29002: THREE.Mesh
    Body3034: THREE.Mesh
    Body30002: THREE.Mesh
    Body31002: THREE.Mesh
    Body32002: THREE.Mesh
    Body33002: THREE.Mesh
    Body34002: THREE.Mesh
    Body35002: THREE.Mesh
    Body36002: THREE.Mesh
    Body37002: THREE.Mesh
    Body38002: THREE.Mesh
    Body39002: THREE.Mesh
    Body4025: THREE.Mesh
    Body40002: THREE.Mesh
    Body41002: THREE.Mesh
    Body42002: THREE.Mesh
    Body43002: THREE.Mesh
    Body44002: THREE.Mesh
    Body45002: THREE.Mesh
    Body46002: THREE.Mesh
    Body47002: THREE.Mesh
    Body48002: THREE.Mesh
    Body49002: THREE.Mesh
    Body5025: THREE.Mesh
    Body50002: THREE.Mesh
    Body51002: THREE.Mesh
    Body52002: THREE.Mesh
    Body53002: THREE.Mesh
    Body54002: THREE.Mesh
    Body55002: THREE.Mesh
    Body56002: THREE.Mesh
    Body57002: THREE.Mesh
    Body58002: THREE.Mesh
    Body59002: THREE.Mesh
    Body6025: THREE.Mesh
    Body60002: THREE.Mesh
    Body61002: THREE.Mesh
    Body62002: THREE.Mesh
    Body63002: THREE.Mesh
    Body64002: THREE.Mesh
    Body65002: THREE.Mesh
    Body66002: THREE.Mesh
    Body67002: THREE.Mesh
    Body68002: THREE.Mesh
    Body69002: THREE.Mesh
    Body7025: THREE.Mesh
    Body70002: THREE.Mesh
    Body71002: THREE.Mesh
    Body72002: THREE.Mesh
    Body73002: THREE.Mesh
    Body74002: THREE.Mesh
    Body75002: THREE.Mesh
    Body76002: THREE.Mesh
    Body77002: THREE.Mesh
    Body78002: THREE.Mesh
    Body79002: THREE.Mesh
    Body8025: THREE.Mesh
    Body80002: THREE.Mesh
    Body81002: THREE.Mesh
    Body82002: THREE.Mesh
    Body83002: THREE.Mesh
    Body84002: THREE.Mesh
    Body85003: THREE.Mesh
    Body86002: THREE.Mesh
    Body87002: THREE.Mesh
    Body88002: THREE.Mesh
    Body89002: THREE.Mesh
    Body9021: THREE.Mesh
    Body90002: THREE.Mesh
    Body91002: THREE.Mesh
    Body92002: THREE.Mesh
    Body93002: THREE.Mesh
    Body94002: THREE.Mesh
    Body95002: THREE.Mesh
    Body96002: THREE.Mesh
    Body97002: THREE.Mesh
    Body98002: THREE.Mesh
    Body99002: THREE.Mesh
    Body11016_1: THREE.Mesh
    Body11017_1: THREE.Mesh
    Body11019_1: THREE.Mesh
    Body11020_1: THREE.Mesh
    Body11021_1: THREE.Mesh
    Body11022_1: THREE.Mesh
    Body11023: THREE.Mesh
    Body11030_1: THREE.Mesh
    Body11031_1: THREE.Mesh
    Body11032_1: THREE.Mesh
    Body11027_1: THREE.Mesh
    Body11028_1: THREE.Mesh
    Body11029_1: THREE.Mesh
    Body1991: THREE.Mesh
    Body1992: THREE.Mesh
    Body1993: THREE.Mesh
    Body1994: THREE.Mesh
    Body1990: THREE.Mesh
    Body1995: THREE.Mesh
    Body1989: THREE.Mesh
    Body1996: THREE.Mesh
    Body1984: THREE.Mesh
    Body1985: THREE.Mesh
    Body1986: THREE.Mesh
    Body1988: THREE.Mesh
    Body1987: THREE.Mesh
    Body11014_1: THREE.Mesh
    Body1997: THREE.Mesh
    Body11059: THREE.Mesh
    Body11060: THREE.Mesh
    Body11055: THREE.Mesh
    Body11056: THREE.Mesh
    Body11057: THREE.Mesh
    Body10023: THREE.Mesh
    Body11023_1: THREE.Mesh
    Body12023: THREE.Mesh
    Body13022: THREE.Mesh
    Body14022: THREE.Mesh
    Body15021: THREE.Mesh
    Body16021: THREE.Mesh
    Body2076: THREE.Mesh
    Body3036: THREE.Mesh
    Body4027: THREE.Mesh
    Body5027: THREE.Mesh
    Body6027: THREE.Mesh
    Body7027: THREE.Mesh
    Body8027: THREE.Mesh
    Body9023: THREE.Mesh
    Body1327_1: THREE.Mesh
    Body1327_2: THREE.Mesh
    Body11048: THREE.Mesh
    Body11049: THREE.Mesh
    Body11050: THREE.Mesh
    Body11051: THREE.Mesh
    Body11052: THREE.Mesh
    Body11053: THREE.Mesh
    Body11054: THREE.Mesh
    Body11190: THREE.Mesh
    Body11191: THREE.Mesh
    Body11192: THREE.Mesh
    Body11193: THREE.Mesh
    Body11177: THREE.Mesh
    Body11183: THREE.Mesh
    Body11178: THREE.Mesh
    Body11179: THREE.Mesh
    Body11180: THREE.Mesh
    Body11181: THREE.Mesh
    Body11182: THREE.Mesh
    Body11184: THREE.Mesh
    Body11185: THREE.Mesh
    Body11186: THREE.Mesh
    Body11187: THREE.Mesh
    Body1384_1: THREE.Mesh
    Body1384_2: THREE.Mesh
    Body1384_3: THREE.Mesh
    Body1384_4: THREE.Mesh
    Body1384_5: THREE.Mesh
    Body11189: THREE.Mesh
    Body11188: THREE.Mesh
    Body11194: THREE.Mesh
    Body11195: THREE.Mesh
    Body11146: THREE.Mesh
    Body11145: THREE.Mesh
    Body11143: THREE.Mesh
    Body11142: THREE.Mesh
    Body11147: THREE.Mesh
    Body11144: THREE.Mesh
    Body11155: THREE.Mesh
    Body2089: THREE.Mesh
    Body11152: THREE.Mesh
    Body11153: THREE.Mesh
    Body11154: THREE.Mesh
    Body11148: THREE.Mesh
    Body11149: THREE.Mesh
    Body11150: THREE.Mesh
    Body11151: THREE.Mesh
    Body11156: THREE.Mesh
    Body11164: THREE.Mesh
    Body2090: THREE.Mesh
    Body11161: THREE.Mesh
    Body11162: THREE.Mesh
    Body11163: THREE.Mesh
    Body11157: THREE.Mesh
    Body11158: THREE.Mesh
    Body11159: THREE.Mesh
    Body11160: THREE.Mesh
    Body11165: THREE.Mesh
    Body11166: THREE.Mesh
    Body11167: THREE.Mesh
    Body11168: THREE.Mesh
    Body11175: THREE.Mesh
    Body11169: THREE.Mesh
    Body11171: THREE.Mesh
    Body11172: THREE.Mesh
    Body11173: THREE.Mesh
    Body11174: THREE.Mesh
    Body11170: THREE.Mesh
    Body11133: THREE.Mesh
    Body11135: THREE.Mesh
    Body11141: THREE.Mesh
    Body11138: THREE.Mesh
    Body11127: THREE.Mesh
    Body11125: THREE.Mesh
    Body11131: THREE.Mesh
    Body11134: THREE.Mesh
    Body11124: THREE.Mesh
    Body11126: THREE.Mesh
    Body11128: THREE.Mesh
    Body11129: THREE.Mesh
    Body11130: THREE.Mesh
    Body11132: THREE.Mesh
    Body11139: THREE.Mesh
    Body11140: THREE.Mesh
    Body11136: THREE.Mesh
    Body11137: THREE.Mesh
    Body11262: THREE.Mesh
    Body11263: THREE.Mesh
    Body11264: THREE.Mesh
    Body11265: THREE.Mesh
    Body11249: THREE.Mesh
    Body11255: THREE.Mesh
    Body11250: THREE.Mesh
    Body11251: THREE.Mesh
    Body11252: THREE.Mesh
    Body11253: THREE.Mesh
    Body11254: THREE.Mesh
    Body11256: THREE.Mesh
    Body11257: THREE.Mesh
    Body11258: THREE.Mesh
    Body11259: THREE.Mesh
    Body11261: THREE.Mesh
    Body11260: THREE.Mesh
    Body11266: THREE.Mesh
    Body11267: THREE.Mesh
    Body11218: THREE.Mesh
    Body11217: THREE.Mesh
    Body11215: THREE.Mesh
    Body11214: THREE.Mesh
    Body11219: THREE.Mesh
    Body11216: THREE.Mesh
    Body11227: THREE.Mesh
    Body2091: THREE.Mesh
    Body11224: THREE.Mesh
    Body11225: THREE.Mesh
    Body11226: THREE.Mesh
    Body11220: THREE.Mesh
    Body11221: THREE.Mesh
    Body11222: THREE.Mesh
    Body11223: THREE.Mesh
    Body11228: THREE.Mesh
    Body11236: THREE.Mesh
    Body2092: THREE.Mesh
    Body11233: THREE.Mesh
    Body11234: THREE.Mesh
    Body11235: THREE.Mesh
    Body11229: THREE.Mesh
    Body11230: THREE.Mesh
    Body11231: THREE.Mesh
    Body11232: THREE.Mesh
    Body11237: THREE.Mesh
    Body11238: THREE.Mesh
    Body11239: THREE.Mesh
    Body11240: THREE.Mesh
    Body11247: THREE.Mesh
    Body11241: THREE.Mesh
    Body11243: THREE.Mesh
    Body11244: THREE.Mesh
    Body11245: THREE.Mesh
    Body11246: THREE.Mesh
    Body11242: THREE.Mesh
    Body11205: THREE.Mesh
    Body11207: THREE.Mesh
    Body11213: THREE.Mesh
    Body11210: THREE.Mesh
    Body11199: THREE.Mesh
    Body11197: THREE.Mesh
    Body11203: THREE.Mesh
    Body11206: THREE.Mesh
    Body11196: THREE.Mesh
    Body11198: THREE.Mesh
    Body11200: THREE.Mesh
    Body11201: THREE.Mesh
    Body11202: THREE.Mesh
    Body11204: THREE.Mesh
    Body11211: THREE.Mesh
    Body11212: THREE.Mesh
    Body11208: THREE.Mesh
    Body11209: THREE.Mesh
    Body11114: THREE.Mesh
    Body11112: THREE.Mesh
    Body11115: THREE.Mesh
    Body11113: THREE.Mesh
    Body10030: THREE.Mesh
    Body11030: THREE.Mesh
    Body12029: THREE.Mesh
    Body13028: THREE.Mesh
    Body14028: THREE.Mesh
    Body15027: THREE.Mesh
    Body16027: THREE.Mesh
    Body2085: THREE.Mesh
    Body3045: THREE.Mesh
    Body4035: THREE.Mesh
    Body5035: THREE.Mesh
    Body6035: THREE.Mesh
    Body7035: THREE.Mesh
    Body8034: THREE.Mesh
    Body9030: THREE.Mesh
    Body11117: THREE.Mesh
    Body10031: THREE.Mesh
    Body11031: THREE.Mesh
    Body12030: THREE.Mesh
    Body13029: THREE.Mesh
    Body14029: THREE.Mesh
    Body15028: THREE.Mesh
    Body16028: THREE.Mesh
    Body2086: THREE.Mesh
    Body3046: THREE.Mesh
    Body4036: THREE.Mesh
    Body5036: THREE.Mesh
    Body6036: THREE.Mesh
    Body7036: THREE.Mesh
    Body8035: THREE.Mesh
    Body9031: THREE.Mesh
    Body11116: THREE.Mesh
    Body11120: THREE.Mesh
    Body11118: THREE.Mesh
    Body11121: THREE.Mesh
    Body11119: THREE.Mesh
    Body10032: THREE.Mesh
    Body11032: THREE.Mesh
    Body12031: THREE.Mesh
    Body13030: THREE.Mesh
    Body14030: THREE.Mesh
    Body15029: THREE.Mesh
    Body16029: THREE.Mesh
    Body2087: THREE.Mesh
    Body3047: THREE.Mesh
    Body4037: THREE.Mesh
    Body5037: THREE.Mesh
    Body6037: THREE.Mesh
    Body7037: THREE.Mesh
    Body8036: THREE.Mesh
    Body9032: THREE.Mesh
    Body11123: THREE.Mesh
    Body10033: THREE.Mesh
    Body11033: THREE.Mesh
    Body12032: THREE.Mesh
    Body13031: THREE.Mesh
    Body14031: THREE.Mesh
    Body15030: THREE.Mesh
    Body16030: THREE.Mesh
    Body2088: THREE.Mesh
    Body3048: THREE.Mesh
    Body4038: THREE.Mesh
    Body5038: THREE.Mesh
    Body6038: THREE.Mesh
    Body7038: THREE.Mesh
    Body8037: THREE.Mesh
    Body9033: THREE.Mesh
    Body11122: THREE.Mesh
    Body11108: THREE.Mesh
    Body11106: THREE.Mesh
    Body11109: THREE.Mesh
    Body11107: THREE.Mesh
    Body10028: THREE.Mesh
    Body11028: THREE.Mesh
    Body12027: THREE.Mesh
    Body13026: THREE.Mesh
    Body14026: THREE.Mesh
    Body15025: THREE.Mesh
    Body16025: THREE.Mesh
    Body2083: THREE.Mesh
    Body3043: THREE.Mesh
    Body4033: THREE.Mesh
    Body5033: THREE.Mesh
    Body6033: THREE.Mesh
    Body7033: THREE.Mesh
    Body8032: THREE.Mesh
    Body9028: THREE.Mesh
    Body11111: THREE.Mesh
    Body10029: THREE.Mesh
    Body11029: THREE.Mesh
    Body12028: THREE.Mesh
    Body13027: THREE.Mesh
    Body14027: THREE.Mesh
    Body15026: THREE.Mesh
    Body16026: THREE.Mesh
    Body2084: THREE.Mesh
    Body3044: THREE.Mesh
    Body4034: THREE.Mesh
    Body5034: THREE.Mesh
    Body6034: THREE.Mesh
    Body7034: THREE.Mesh
    Body8033: THREE.Mesh
    Body9029: THREE.Mesh
    Body11110: THREE.Mesh
    Body11102: THREE.Mesh
    Body11100: THREE.Mesh
    Body11103: THREE.Mesh
    Body11101: THREE.Mesh
    Body10026: THREE.Mesh
    Body11026: THREE.Mesh
    Body12025: THREE.Mesh
    Body13024: THREE.Mesh
    Body14024: THREE.Mesh
    Body15023: THREE.Mesh
    Body16023: THREE.Mesh
    Body2081: THREE.Mesh
    Body3041: THREE.Mesh
    Body4031: THREE.Mesh
    Body5031: THREE.Mesh
    Body6031: THREE.Mesh
    Body7031: THREE.Mesh
    Body8030: THREE.Mesh
    Body9026: THREE.Mesh
    Body11105: THREE.Mesh
    Body10027: THREE.Mesh
    Body11027: THREE.Mesh
    Body12026: THREE.Mesh
    Body13025: THREE.Mesh
    Body14025: THREE.Mesh
    Body15024: THREE.Mesh
    Body16024: THREE.Mesh
    Body2082: THREE.Mesh
    Body3042: THREE.Mesh
    Body4032: THREE.Mesh
    Body5032: THREE.Mesh
    Body6032: THREE.Mesh
    Body7032: THREE.Mesh
    Body8031: THREE.Mesh
    Body9027: THREE.Mesh
    Body11104: THREE.Mesh
    Body1919: THREE.Mesh
    Body1918: THREE.Mesh
    Body1921: THREE.Mesh
    Body1920: THREE.Mesh
    Body1900: THREE.Mesh
    Body1895: THREE.Mesh
    Body1896: THREE.Mesh
    Body1906: THREE.Mesh
    Body1905: THREE.Mesh
    Body1907: THREE.Mesh
    Body1892: THREE.Mesh
    Body1893: THREE.Mesh
    Body1894: THREE.Mesh
    Body1897: THREE.Mesh
    Body1898: THREE.Mesh
    Body1899: THREE.Mesh
    Body1903: THREE.Mesh
    Body1923: THREE.Mesh
    Body1922: THREE.Mesh
    Body1904: THREE.Mesh
    Body1901: THREE.Mesh
    Body1902: THREE.Mesh
    Body1910: THREE.Mesh
    Body1911: THREE.Mesh
    Body1912: THREE.Mesh
    Body1913: THREE.Mesh
    Body1914: THREE.Mesh
    Body1915: THREE.Mesh
    Body1916: THREE.Mesh
    Body1917: THREE.Mesh
    Body1886: THREE.Mesh
    Body1887: THREE.Mesh
    Body1908: THREE.Mesh
    Body1909: THREE.Mesh
    Body1888: THREE.Mesh
    Body1890: THREE.Mesh
    Body1889: THREE.Mesh
    Body1891: THREE.Mesh
    Body11400: THREE.Mesh
    Body11399: THREE.Mesh
    Body11398: THREE.Mesh
    Body11397: THREE.Mesh
    Body11395: THREE.Mesh
    Body11393: THREE.Mesh
    Body11392: THREE.Mesh
    Body11394: THREE.Mesh
    Body11390: THREE.Mesh
    Body11396: THREE.Mesh
    Body11391: THREE.Mesh
    Body11389: THREE.Mesh
    Body11385: THREE.Mesh
    Body11387: THREE.Mesh
    Body11386: THREE.Mesh
    Body11388: THREE.Mesh
    Body10034: THREE.Mesh
    Body6039: THREE.Mesh
    Body7039: THREE.Mesh
    Body9036: THREE.Mesh
    Body11280: THREE.Mesh
    Body11288: THREE.Mesh
    Body11286: THREE.Mesh
    Body11287: THREE.Mesh
    Body11285: THREE.Mesh
    Body11284: THREE.Mesh
    Body11283: THREE.Mesh
    Body11281: THREE.Mesh
    Body11282: THREE.Mesh
    Body11289: THREE.Mesh
    Body11314: THREE.Mesh
    Body11322: THREE.Mesh
    Body11316: THREE.Mesh
    Body11315: THREE.Mesh
    Body11313: THREE.Mesh
    Body11310: THREE.Mesh
    Body11311: THREE.Mesh
    Body11312: THREE.Mesh
    Body11320: THREE.Mesh
    Body11318: THREE.Mesh
    Body11319: THREE.Mesh
    Body11317: THREE.Mesh
    Body11321: THREE.Mesh
    Body9034: THREE.Mesh
    Body9035: THREE.Mesh
    Body12033: THREE.Mesh
    Body12034: THREE.Mesh
    Body12035: THREE.Mesh
    Body12036: THREE.Mesh
    Body12037: THREE.Mesh
    Body20003: THREE.Mesh
    Body11293: THREE.Mesh
    Body11292: THREE.Mesh
    Body11295: THREE.Mesh
    Body11294: THREE.Mesh
    Body11297: THREE.Mesh
    Body11296: THREE.Mesh
    Body11299: THREE.Mesh
    Body11298: THREE.Mesh
    Body11301: THREE.Mesh
    Body11300: THREE.Mesh
    Body11302: THREE.Mesh
    Body11303: THREE.Mesh
    Body11304: THREE.Mesh
    Body11305: THREE.Mesh
    Body11306: THREE.Mesh
    Body11290: THREE.Mesh
    Body11291: THREE.Mesh
    Body11307: THREE.Mesh
    Body11381: THREE.Mesh
    Body11382: THREE.Mesh
    Body11383: THREE.Mesh
    Body11366: THREE.Mesh
    Body11372: THREE.Mesh
    Body11371: THREE.Mesh
    Body11374: THREE.Mesh
    Body11373: THREE.Mesh
    Body11376: THREE.Mesh
    Body11375: THREE.Mesh
    Body11378: THREE.Mesh
    Body11377: THREE.Mesh
    Body11380: THREE.Mesh
    Body11379: THREE.Mesh
    Body11367: THREE.Mesh
    Body11368: THREE.Mesh
    Body11369: THREE.Mesh
    Body11370: THREE.Mesh
    Body11365: THREE.Mesh
    Body11363: THREE.Mesh
    Body11364: THREE.Mesh
    Body11384: THREE.Mesh
    Body1246_1: THREE.Mesh
    Body1246_2: THREE.Mesh
    Body1246_3: THREE.Mesh
    Body1246_4: THREE.Mesh
    Body1246_5: THREE.Mesh
    Body1246_6: THREE.Mesh
    Body1246_7: THREE.Mesh
    Body1246_8: THREE.Mesh
    Body1246_9: THREE.Mesh
    Body2018_1: THREE.Mesh
    Body2018_2: THREE.Mesh
    Body11325: THREE.Mesh
    Body2098: THREE.Mesh
    Body11326: THREE.Mesh
    Body2099: THREE.Mesh
    Body11331: THREE.Mesh
    Body11332: THREE.Mesh
    Body11333: THREE.Mesh
    Body11327: THREE.Mesh
    Body11328: THREE.Mesh
    Body11329: THREE.Mesh
    Body11330: THREE.Mesh
    Body11323: THREE.Mesh
    Body11324: THREE.Mesh
    Body11334: THREE.Mesh
    Body11356: THREE.Mesh
    Body2102: THREE.Mesh
    Body11357: THREE.Mesh
    Body2103: THREE.Mesh
    Body11354: THREE.Mesh
    Body11355: THREE.Mesh
    Body11360: THREE.Mesh
    Body11361: THREE.Mesh
    Body11352: THREE.Mesh
    Body11353: THREE.Mesh
    Body11345: THREE.Mesh
    Body11346: THREE.Mesh
    Body11347: THREE.Mesh
    Body11348: THREE.Mesh
    Body11349: THREE.Mesh
    Body11350: THREE.Mesh
    Body11351: THREE.Mesh
    Body11358: THREE.Mesh
    Body11359: THREE.Mesh
    Body11362: THREE.Mesh
    Body11340: THREE.Mesh
    Body2100: THREE.Mesh
    Body11341: THREE.Mesh
    Body2101: THREE.Mesh
    Body11338: THREE.Mesh
    Body11339: THREE.Mesh
    Body11335: THREE.Mesh
    Body11336: THREE.Mesh
    Body11337: THREE.Mesh
    Body11342: THREE.Mesh
    Body11343: THREE.Mesh
    Body11344: THREE.Mesh
    Body11274: THREE.Mesh
    Body11278: THREE.Mesh
    Body11277: THREE.Mesh
    Body11272: THREE.Mesh
    Body11273: THREE.Mesh
    Body11269: THREE.Mesh
    Body11270: THREE.Mesh
    Body11271: THREE.Mesh
    Body11268: THREE.Mesh
    Body11276: THREE.Mesh
    Body11279: THREE.Mesh
    Body3049: THREE.Mesh
    Body3050: THREE.Mesh
    ['Body2_(1)_(1)']: THREE.Mesh
    ['Body2_(1)_(2)']: THREE.Mesh
    Body2093: THREE.Mesh
    Body2094: THREE.Mesh
    Body4039: THREE.Mesh
    Body11275: THREE.Mesh
    ['Body4_(1)']: THREE.Mesh
    Body2095: THREE.Mesh
    Body1785: THREE.Mesh
    Body1801: THREE.Mesh
    Body1849: THREE.Mesh
    Body1850: THREE.Mesh
    Body1793: THREE.Mesh
    Body1784: THREE.Mesh
    Body1800: THREE.Mesh
    Body1799: THREE.Mesh
    Body1830: THREE.Mesh
    Body1868: THREE.Mesh
    Body1829: THREE.Mesh
    Body1882: THREE.Mesh
    Body1827: THREE.Mesh
    Body1778: THREE.Mesh
    Body1782: THREE.Mesh
    Body1881: THREE.Mesh
    Body1828: THREE.Mesh
    Body1802: THREE.Mesh
    Body1805: THREE.Mesh
    Body1833: THREE.Mesh
    Body1883: THREE.Mesh
    Body1884: THREE.Mesh
    Body1780: THREE.Mesh
    Body1783: THREE.Mesh
    Body1781: THREE.Mesh
    Body1867: THREE.Mesh
    Body1839: THREE.Mesh
    Body1840: THREE.Mesh
    Body1841: THREE.Mesh
    Body1842: THREE.Mesh
    Body1772: THREE.Mesh
    Body1843: THREE.Mesh
    Body1844: THREE.Mesh
    Body1869: THREE.Mesh
    Body1834: THREE.Mesh
    Body1832: THREE.Mesh
    Body1831: THREE.Mesh
    Body1826: THREE.Mesh
    Body1777: THREE.Mesh
    Body1797: THREE.Mesh
    Body1871: THREE.Mesh
    Body1870: THREE.Mesh
    Body1877: THREE.Mesh
    Body1879: THREE.Mesh
    Body1878: THREE.Mesh
    Body1776: THREE.Mesh
    Body1873: THREE.Mesh
    Body1876: THREE.Mesh
    Body1875: THREE.Mesh
    Body1874: THREE.Mesh
    Body1836: THREE.Mesh
    Body1865: THREE.Mesh
    Body1835: THREE.Mesh
    Body1863: THREE.Mesh
    Body1774: THREE.Mesh
    Body1854: THREE.Mesh
    Body1866: THREE.Mesh
    Body1852: THREE.Mesh
    Body1864: THREE.Mesh
    Body1775: THREE.Mesh
    Body1858: THREE.Mesh
    Body1853: THREE.Mesh
    Body1861: THREE.Mesh
    Body1862: THREE.Mesh
    Body1851: THREE.Mesh
    Body1856: THREE.Mesh
    Body1795: THREE.Mesh
    Body1855: THREE.Mesh
    Body1857: THREE.Mesh
    Body1860: THREE.Mesh
    Body1773: THREE.Mesh
    Body1819: THREE.Mesh
    Body1859: THREE.Mesh
    Body1821: THREE.Mesh
    Body1822: THREE.Mesh
    Body1823: THREE.Mesh
    Body1825: THREE.Mesh
    Body1824: THREE.Mesh
    Body1816: THREE.Mesh
    Body1794: THREE.Mesh
    Body1818: THREE.Mesh
    Body1817: THREE.Mesh
    Body1820: THREE.Mesh
    Body1804: THREE.Mesh
    Body1846: THREE.Mesh
    Body1847: THREE.Mesh
    Body1848: THREE.Mesh
    Body1838: THREE.Mesh
    Body1796: THREE.Mesh
    Body1880: THREE.Mesh
    Body1845: THREE.Mesh
    Body1779: THREE.Mesh
    Body1811: THREE.Mesh
    Body1808: THREE.Mesh
    Body1814: THREE.Mesh
    Body1813: THREE.Mesh
    Body1809: THREE.Mesh
    Body1807: THREE.Mesh
    Body1812: THREE.Mesh
    Body1810: THREE.Mesh
    Body1806: THREE.Mesh
    Body1815: THREE.Mesh
    Body1792: THREE.Mesh
    Body1789: THREE.Mesh
    Body1790: THREE.Mesh
    Body1791: THREE.Mesh
    Body1788: THREE.Mesh
    Body1787: THREE.Mesh
    Body1803: THREE.Mesh
    Body1837: THREE.Mesh
    Body1872: THREE.Mesh
    Body1798: THREE.Mesh
    Body1885: THREE.Mesh
    Body1786: THREE.Mesh
    Body1771: THREE.Mesh
    Body2070: THREE.Mesh
    Body1770: THREE.Mesh
    Body2069: THREE.Mesh
    Body1768: THREE.Mesh
    Body2067: THREE.Mesh
    Body1769: THREE.Mesh
    Body2068: THREE.Mesh
    Body1703: THREE.Mesh
    Body1695: THREE.Mesh
    Body1683: THREE.Mesh
    Body1763: THREE.Mesh
    Body1762: THREE.Mesh
    Body1759: THREE.Mesh
    Body1761: THREE.Mesh
    Body1760: THREE.Mesh
    Body1758: THREE.Mesh
    Body1682: THREE.Mesh
    Body1721: THREE.Mesh
    Body1722: THREE.Mesh
    Body1725: THREE.Mesh
    Body1727: THREE.Mesh
    Body1720: THREE.Mesh
    Body1723: THREE.Mesh
    Body1724: THREE.Mesh
    Body1726: THREE.Mesh
    Body1697: THREE.Mesh
    Body1701: THREE.Mesh
    Body1700: THREE.Mesh
    Body1699: THREE.Mesh
    Body1698: THREE.Mesh
    Body1732: THREE.Mesh
    Body2061: THREE.Mesh
    Body1734: THREE.Mesh
    Body2063: THREE.Mesh
    Body1735: THREE.Mesh
    Body2064: THREE.Mesh
    Body1736: THREE.Mesh
    Body2065: THREE.Mesh
    Body1737: THREE.Mesh
    Body2066: THREE.Mesh
    Body1733: THREE.Mesh
    Body2062: THREE.Mesh
    Body1678: THREE.Mesh
    Body1676: THREE.Mesh
    Body1677: THREE.Mesh
    Body1675: THREE.Mesh
    Body1663: THREE.Mesh
    Body1714: THREE.Mesh
    Body1712: THREE.Mesh
    Body1665: THREE.Mesh
    Body1668: THREE.Mesh
    Body1667: THREE.Mesh
    Body1666: THREE.Mesh
    Body1664: THREE.Mesh
    Body1674: THREE.Mesh
    Body1713: THREE.Mesh
    Body1715: THREE.Mesh
    Body1748: THREE.Mesh
    Body1749: THREE.Mesh
    Body1750: THREE.Mesh
    Body1751: THREE.Mesh
    Body1752: THREE.Mesh
    Body1753: THREE.Mesh
    Body1754: THREE.Mesh
    Body1755: THREE.Mesh
    Body1756: THREE.Mesh
    Body1757: THREE.Mesh
    Body1738: THREE.Mesh
    Body1739: THREE.Mesh
    Body1740: THREE.Mesh
    Body1741: THREE.Mesh
    Body1742: THREE.Mesh
    Body1743: THREE.Mesh
    Body1744: THREE.Mesh
    Body1745: THREE.Mesh
    Body1746: THREE.Mesh
    Body1747: THREE.Mesh
    Body1706: THREE.Mesh
    Body1684: THREE.Mesh
    Body2048: THREE.Mesh
    Body1688: THREE.Mesh
    Body2051: THREE.Mesh
    Body1685: THREE.Mesh
    Body2049: THREE.Mesh
    Body1686: THREE.Mesh
    Body2050: THREE.Mesh
    Body1687: THREE.Mesh
    Body1705: THREE.Mesh
    Body1696: THREE.Mesh
    Body1694: THREE.Mesh
    Body1658: THREE.Mesh
    Body1657: THREE.Mesh
    Body1660: THREE.Mesh
    Body1659: THREE.Mesh
    Body1670: THREE.Mesh
    Body1669: THREE.Mesh
    Body1672: THREE.Mesh
    Body1671: THREE.Mesh
    Body1767: THREE.Mesh
    Body1765: THREE.Mesh
    Body1702: THREE.Mesh
    Body1711: THREE.Mesh
    Body1710: THREE.Mesh
    Body1709: THREE.Mesh
    Body1708: THREE.Mesh
    Body1766: THREE.Mesh
    Body1764: THREE.Mesh
    Body1719: THREE.Mesh
    Body1717: THREE.Mesh
    Body1728: THREE.Mesh
    Body2057: THREE.Mesh
    Body3030: THREE.Mesh
    Body1729: THREE.Mesh
    Body2058: THREE.Mesh
    Body3031: THREE.Mesh
    Body1730: THREE.Mesh
    Body2059: THREE.Mesh
    Body3032: THREE.Mesh
    Body1731: THREE.Mesh
    Body2060: THREE.Mesh
    Body3033: THREE.Mesh
    Body1661: THREE.Mesh
    Body2046: THREE.Mesh
    Body3025: THREE.Mesh
    Body1662: THREE.Mesh
    Body2047: THREE.Mesh
    Body3026: THREE.Mesh
    Body1689: THREE.Mesh
    Body2052: THREE.Mesh
    Body3027: THREE.Mesh
    Body1690: THREE.Mesh
    Body2053: THREE.Mesh
    Body3028: THREE.Mesh
    Body1681: THREE.Mesh
    Body1679: THREE.Mesh
    Body1680: THREE.Mesh
    Body1693: THREE.Mesh
    Body1691: THREE.Mesh
    Body1692: THREE.Mesh
    Body1225_1: THREE.Mesh
    Body1225_2: THREE.Mesh
    Body1225_3: THREE.Mesh
    Body1225_4: THREE.Mesh
    Body1707: THREE.Mesh
    Body10020: THREE.Mesh
    Body11020: THREE.Mesh
    Body12020: THREE.Mesh
    Body13019: THREE.Mesh
    Body14019: THREE.Mesh
    Body16018: THREE.Mesh
    Body17016: THREE.Mesh
    Body18012: THREE.Mesh
    Body19002_1: THREE.Mesh
    Body19002_2: THREE.Mesh
    Body2054: THREE.Mesh
    Body21012: THREE.Mesh
    Body22012: THREE.Mesh
    Body23012: THREE.Mesh
    Body24012: THREE.Mesh
    Body25012: THREE.Mesh
    Body5024: THREE.Mesh
    Body6024: THREE.Mesh
    Body7024: THREE.Mesh
    Body8024: THREE.Mesh
    Body9020: THREE.Mesh
    Body1704: THREE.Mesh
    Body1622: THREE.Mesh
    Body1623: THREE.Mesh
    Body1624: THREE.Mesh
    Body1625: THREE.Mesh
    Body1635: THREE.Mesh
    Body1634: THREE.Mesh
    Body1628: THREE.Mesh
    Body1626: THREE.Mesh
    Body1627: THREE.Mesh
    Body1629: THREE.Mesh
    Body1630: THREE.Mesh
    Body1640: THREE.Mesh
    Body1639: THREE.Mesh
    Body1641: THREE.Mesh
    Body1636: THREE.Mesh
    Body1637: THREE.Mesh
    Body1638: THREE.Mesh
    Body1647: THREE.Mesh
    Body1649: THREE.Mesh
    Body1648: THREE.Mesh
    Body1650: THREE.Mesh
    Body1656: THREE.Mesh
    Body1655: THREE.Mesh
    Body1632: THREE.Mesh
    Body1633: THREE.Mesh
    Body1631: THREE.Mesh
    Body85002: THREE.Mesh
    Body1646: THREE.Mesh
    Body1642: THREE.Mesh
    Body1644: THREE.Mesh
    Body1645: THREE.Mesh
    Body1643: THREE.Mesh
    Body1652: THREE.Mesh
    Body1651: THREE.Mesh
    Body1621: THREE.Mesh
    Body1654: THREE.Mesh
    Body1653: THREE.Mesh
    Body3024: THREE.Mesh
    Body14018: THREE.Mesh
    Body2045: THREE.Mesh
  }
  materials: {
    ['Steel - Satin']: THREE.MeshStandardMaterial
    ['Opaque(76,76,76)']: THREE.MeshStandardMaterial
    ['Powder Coat (Black)']: THREE.MeshStandardMaterial
    ['Opaque(76,76,76).001']: THREE.MeshStandardMaterial
    ['Opaque(177,24,24)']: THREE.MeshStandardMaterial
    ['Opaque(170,178,196)']: THREE.MeshStandardMaterial
    ['Opaque(207,188,0)']: THREE.MeshStandardMaterial
    ['Opaque(255,221,135)']: THREE.MeshStandardMaterial
    ['Opaque(255,242,232)']: THREE.MeshStandardMaterial
    ['Powder Coat (Red)']: THREE.MeshStandardMaterial
    ['Opaque(202,209,238)']: THREE.MeshStandardMaterial
    ['Opaque(210,210,255)']: THREE.MeshStandardMaterial
    ['Opaque(70,60,69)']: THREE.MeshStandardMaterial
    ['Opaque(255,255,255)']: THREE.MeshStandardMaterial
    ['Opaque(192,192,192)']: THREE.MeshStandardMaterial
    ['Opaque(63,63,63)']: THREE.MeshStandardMaterial
    ['Opaque(33,138,33)']: THREE.MeshStandardMaterial
    ['Opaque(221,221,13)']: THREE.MeshStandardMaterial
    ['Opaque(5,5,5)']: THREE.MeshStandardMaterial
    ['Opaque(190,188,186)']: THREE.MeshStandardMaterial
    ['Opaque(178,0,0)']: THREE.MeshStandardMaterial
    ['Opaque(245,245,245)']: THREE.MeshStandardMaterial
    ['Opaque(254,213,0)']: THREE.MeshStandardMaterial
    ['Opaque(244,244,244)']: THREE.MeshStandardMaterial
    ['Opaque(177,177,177)']: THREE.MeshStandardMaterial
    ['Opaque(10,10,10)']: THREE.MeshStandardMaterial
    ['Opaque(72,71,61)']: THREE.MeshStandardMaterial
    ['Opaque(0,100,0)']: THREE.MeshStandardMaterial
    ['Opaque(231,214,172)']: THREE.MeshStandardMaterial
    ['Opaque(255,0,0)']: THREE.MeshStandardMaterial
    ['Opaque(204,204,204)']: THREE.MeshStandardMaterial
    ['Opaque(32,32,32)']: THREE.MeshStandardMaterial
    ['Opaque(50,112,20)']: THREE.MeshStandardMaterial
    ['Opaque(248,135,1)']: THREE.MeshStandardMaterial
    Default: THREE.MeshStandardMaterial
    ['Dark Gray']: THREE.MeshStandardMaterial
    ['Opaque(61,158,110)']: THREE.MeshStandardMaterial
    ['255,255,255']: THREE.MeshStandardMaterial
    ['229,235,237']: THREE.MeshStandardMaterial
    ['177,25,25']: THREE.MeshStandardMaterial
    ['229,45,45']: THREE.MeshStandardMaterial
    ['40,40,40']: THREE.MeshStandardMaterial
    ['232,156,1']: THREE.MeshStandardMaterial
    ['76,76,76']: THREE.MeshStandardMaterial
    ['2,61,210']: THREE.MeshStandardMaterial
    ['127,127,127']: THREE.MeshStandardMaterial
    ['113,185,237']: THREE.MeshStandardMaterial
    ['255,239,35']: THREE.MeshStandardMaterial
    ['73,169,84']: THREE.MeshStandardMaterial
    ['166,158,150']: THREE.MeshStandardMaterial
    ['73,217,84']: THREE.MeshStandardMaterial
    ['0,0,0']: THREE.MeshStandardMaterial
    ['26,26,26']: THREE.MeshStandardMaterial
    ['198,193,188']: THREE.MeshStandardMaterial
    ['64,64,64']: THREE.MeshStandardMaterial
    ['229,229,229']: THREE.MeshStandardMaterial
    ['Coating - Black Oxide.001']: THREE.MeshStandardMaterial
    ['Millennium Falcon Green']: THREE.MeshStandardMaterial
    ['Polycarbonate (Smoked)']: THREE.MeshStandardMaterial
    ['165,158,150']: THREE.MeshStandardMaterial
    ['239,239,239']: THREE.MeshStandardMaterial
    ['255,223,0']: THREE.MeshStandardMaterial
    ['128,128,128']: THREE.MeshStandardMaterial
    ['53,51,48']: THREE.MeshStandardMaterial
    ['170,178,196']: THREE.MeshStandardMaterial
    ['Opaque(221,232,255)']: THREE.MeshStandardMaterial
    ['Opaque(166,158,150)']: THREE.MeshStandardMaterial
    ['Rubber - Soft']: THREE.MeshStandardMaterial
    ['Opaque(64,64,64)']: THREE.MeshStandardMaterial
    ['Opaque(252,188,132)']: THREE.MeshStandardMaterial
    ['Opaque(0,128,0)']: THREE.MeshStandardMaterial
    ['Opaque(255,223,0)']: THREE.MeshStandardMaterial
    ['Opaque(49,49,49)']: THREE.MeshStandardMaterial
    ['Opaque(184,184,184)']: THREE.MeshStandardMaterial
    ['Opaque(149,149,149)']: THREE.MeshStandardMaterial
    ['Opaque(228,233,237)']: THREE.MeshStandardMaterial
    ['Opaque(215,208,192)']: THREE.MeshStandardMaterial
    ['Opaque(203,210,239)']: THREE.MeshStandardMaterial
    ['Opaque(58,43,5)']: THREE.MeshStandardMaterial
    ['Opaque(28,28,28)']: THREE.MeshStandardMaterial
    ['Opaque(196,196,196)']: THREE.MeshStandardMaterial
    ['Opaque(40,40,40)']: THREE.MeshStandardMaterial
    ['Opaque(255,215,0)']: THREE.MeshStandardMaterial
    ['Opaque(220,150,0)']: THREE.MeshStandardMaterial
    ['Opaque(198,193,188)']: THREE.MeshStandardMaterial
    ['Opaque(165,158,150)']: THREE.MeshStandardMaterial
    ['Opaque(192,192,255)']: THREE.MeshStandardMaterial
    ['Opaque(60,60,60)']: THREE.MeshStandardMaterial
    ['Opaque(255,255,0)']: THREE.MeshStandardMaterial
    ['Opaque(0,255,0)']: THREE.MeshStandardMaterial
    ['Opaque(151,215,0)']: THREE.MeshStandardMaterial
    ['Opaque(199,194,189)']: THREE.MeshStandardMaterial
    ['Opaque(51,102,178)']: THREE.MeshStandardMaterial
    ['Opaque(165,132,0)']: THREE.MeshStandardMaterial
    ['Opaque(229,234,237)']: THREE.MeshStandardMaterial
    ['Opaque(37,40,42)']: THREE.MeshStandardMaterial
    ['Opaque(128,128,128)']: THREE.MeshStandardMaterial
    ['Opaque(255,192,192)']: THREE.MeshStandardMaterial
    ['Opaque(217,225,221)']: THREE.MeshStandardMaterial
    ['Opaque(128,128,0)']: THREE.MeshStandardMaterial
    ['Opaque(192,0,0)']: THREE.MeshStandardMaterial
    ['Opaque(135,140,140)']: THREE.MeshStandardMaterial
    ['Opaque(64,64,64).001']: THREE.MeshStandardMaterial
    ['Opaque(25,25,25)']: THREE.MeshStandardMaterial
    ['Rubber - Soft.001']: THREE.MeshStandardMaterial
    ['Plastic - Glossy (Black)']: THREE.MeshStandardMaterial
    ['Plastic - Matte (Blue)']: THREE.MeshStandardMaterial
    ['Powder Coat (Black).001']: THREE.MeshStandardMaterial
    ['255,255,0']: THREE.MeshStandardMaterial
    ['0,255,0']: THREE.MeshStandardMaterial
    ['255,0,0']: THREE.MeshStandardMaterial
    ['60,60,60']: THREE.MeshStandardMaterial
    ['151,215,0']: THREE.MeshStandardMaterial
    ['199,194,189']: THREE.MeshStandardMaterial
    ['Plastic - Matte (Green)']: THREE.MeshStandardMaterial
    ['ABS (White)']: THREE.MeshStandardMaterial
    ['Opaque(158,104,51)']: THREE.MeshStandardMaterial
    ['Aluminum - Satin']: THREE.MeshStandardMaterial
    ['Opaque(255,255,192)']: THREE.MeshStandardMaterial
    ['Opaque(126,126,126)']: THREE.MeshStandardMaterial
    ['Opaque(179,179,179)']: THREE.MeshStandardMaterial
    ['Anodized - Black']: THREE.MeshStandardMaterial
    ['Aluminum - Polished']: THREE.MeshStandardMaterial
    Red: THREE.MeshStandardMaterial
    Generic: THREE.MeshStandardMaterial
  }
}

export function MShredder(props: JSX.IntrinsicElements['group']) {
  const { nodes, materials } = useGLTF('/MShredder/MShredder.gltf') as GLTFResult
  return (
    <group {...props} dispose={null}>
      <group rotation={[Math.PI / 2, 0, 0]} scale={0.025}>
        <group position={[17, 0, 0]} rotation={[0, 0, Math.PI / 2]}>
          <group position={[40.552, -11.462, -5.329]} rotation={[0, -1.222, -Math.PI / 2]}>
            <group scale={0.394}>
              <mesh geometry={nodes.Body1185_1.geometry} material={materials['Opaque(33,138,33)']} />
              <mesh geometry={nodes.Body1185_2.geometry} material={materials['Opaque(221,221,13)']} />
              <mesh geometry={nodes.Body1185_3.geometry} material={materials['Opaque(5,5,5)']} />
              <mesh geometry={nodes.Body1185_4.geometry} material={materials['Opaque(190,188,186)']} />
              <mesh geometry={nodes.Body1185_5.geometry} material={materials['Opaque(63,63,63)']} />
            </group>
            <mesh geometry={nodes.Body10034.geometry} material={materials['Opaque(33,138,33)']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body11006_2.geometry} material={materials['Opaque(5,5,5)']} />
              <mesh geometry={nodes.Body11006_3.geometry} material={materials['Opaque(178,0,0)']} />
            </group>
            <group scale={0.394}>
              <mesh geometry={nodes.Body12006_1.geometry} material={materials['Opaque(5,5,5)']} />
              <mesh geometry={nodes.Body12006_2.geometry} material={materials['Opaque(63,63,63)']} />
            </group>
            <group scale={0.394}>
              <mesh geometry={nodes.Body2012_1.geometry} material={materials['Opaque(190,188,186)']} />
              <mesh geometry={nodes.Body2012_2.geometry} material={materials['Opaque(245,245,245)']} />
              <mesh geometry={nodes.Body2012_3.geometry} material={materials['Opaque(254,213,0)']} />
            </group>
            <group scale={0.394}>
              <mesh geometry={nodes.Body3007_1.geometry} material={materials['Opaque(244,244,244)']} />
              <mesh geometry={nodes.Body3007_2.geometry} material={materials['Opaque(177,177,177)']} />
            </group>
            <group scale={0.394}>
              <mesh geometry={nodes.Body4007_1.geometry} material={materials['Opaque(245,245,245)']} />
              <mesh geometry={nodes.Body4007_2.geometry} material={materials['Opaque(63,63,63)']} />
              <mesh geometry={nodes.Body4007_3.geometry} material={materials['Opaque(244,244,244)']} />
            </group>
            <group scale={0.394}>
              <mesh geometry={nodes.Body5007_1.geometry} material={materials['Opaque(63,63,63)']} />
              <mesh geometry={nodes.Body5007_2.geometry} material={materials['Opaque(10,10,10)']} />
              <mesh geometry={nodes.Body5007_3.geometry} material={materials['Opaque(72,71,61)']} />
            </group>
            <mesh geometry={nodes.Body6039.geometry} material={materials['Opaque(63,63,63)']} scale={0.394} />
            <mesh geometry={nodes.Body7039.geometry} material={materials['Opaque(63,63,63)']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body8007_1.geometry} material={materials['Opaque(0,100,0)']} />
              <mesh geometry={nodes.Body8007_2.geometry} material={materials['Opaque(231,214,172)']} />
              <mesh geometry={nodes.Body8007_3.geometry} material={materials['Opaque(63,63,63)']} />
            </group>
            <mesh geometry={nodes.Body9036.geometry} material={materials['Opaque(221,221,13)']} scale={0.394} />
          </group>
          <group position={[41.311, 0.377, -3.168]}>
            <mesh geometry={nodes.Body11400.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[41.311, -10.623, -3.168]}>
            <mesh geometry={nodes.Body11399.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[41.311, -11.623, -3.168]}>
            <mesh geometry={nodes.Body11398.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[41.311, -22.623, -3.168]}>
            <mesh geometry={nodes.Body11397.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[40.033, 1.609, -3.463]} rotation={[-Math.PI / 2, 0, 2.482]}>
            <mesh geometry={nodes.Body11395.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[40.251, 1.252, -3.745]} rotation={[-Math.PI, 0.66, -Math.PI]}>
            <mesh geometry={nodes.Body11393.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[34.108, 1.609, -8.062]} rotation={[-Math.PI / 2, 0, 2.482]}>
            <mesh geometry={nodes.Body11392.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[34.326, 1.252, -8.343]} rotation={[-Math.PI, 0.66, -Math.PI]}>
            <mesh geometry={nodes.Body11394.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[40.033, -23.854, -3.463]} rotation={[Math.PI / 2, 0, 0.66]}>
            <mesh geometry={nodes.Body11390.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[34.326, -23.498, -8.343]} rotation={[Math.PI, 0.66, 0]}>
            <mesh geometry={nodes.Body11396.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[40.251, -23.498, -3.745]} rotation={[Math.PI, 0.66, 0]}>
            <mesh geometry={nodes.Body11391.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[34.108, -23.854, -8.062]} rotation={[Math.PI / 2, 0, 0.66]}>
            <mesh geometry={nodes.Body11389.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[40.658, 0.877, -3.453]} rotation={[Math.PI / 2, 0, -2.482]}>
            <mesh geometry={nodes.Body11385.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[34.733, 0.877, -8.051]} rotation={[Math.PI / 2, 0, -2.482]}>
            <mesh geometry={nodes.Body11387.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[39.867, -23.123, -4.066]} rotation={[-Math.PI / 2, 0, -0.66]}>
            <mesh geometry={nodes.Body11386.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[33.943, -23.123, -8.664]} rotation={[-Math.PI / 2, 0, -0.66]}>
            <mesh geometry={nodes.Body11388.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[40.759, -24.216, -0.15]} rotation={[-Math.PI, 0, -Math.PI]}>
            <mesh geometry={nodes.Body11280.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
          </group>
          <group position={[40.759, 1.971, -0.15]} rotation={[0, 0, Math.PI]}>
            <mesh geometry={nodes.Body11288.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
          </group>
          <group position={[37.186, -24.216, 1.35]} rotation={[-Math.PI, 0, -Math.PI]}>
            <mesh geometry={nodes.Body11286.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
          </group>
          <group position={[36.235, -24.216, -5.983]} rotation={[-Math.PI, 0, -Math.PI]}>
            <mesh geometry={nodes.Body11287.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
          </group>
          <group position={[32.83, -24.216, -8.816]} rotation={[-Math.PI, 0, -Math.PI]}>
            <mesh geometry={nodes.Body11285.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
          </group>
          <group position={[33.987, -24.216, -4.191]} rotation={[-Math.PI, 0, -Math.PI]}>
            <mesh geometry={nodes.Body11284.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
          </group>
          <group position={[37.186, 1.971, 1.35]} rotation={[0, 0, Math.PI]}>
            <mesh geometry={nodes.Body11283.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
          </group>
          <group position={[32.83, 1.971, -8.816]} rotation={[0, 0, Math.PI]}>
            <mesh geometry={nodes.Body11281.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
          </group>
          <group position={[33.987, 1.971, -4.191]} rotation={[0, 0, Math.PI]}>
            <mesh geometry={nodes.Body11282.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
          </group>
          <group position={[36.235, 1.971, -5.983]} rotation={[0, 0, Math.PI]}>
            <mesh geometry={nodes.Body11289.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
          </group>
          <group position={[40.759, -23.133, -0.15]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body11314.geometry} material={materials['Rubber - Soft.001']} scale={0.394} />
          </group>
          <group position={[36.233, -16.749, -5.981]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body11322.geometry} material={materials['Rubber - Soft.001']} scale={0.394} />
          </group>
          <group position={[36.233, -10.762, -5.981]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body11316.geometry} material={materials['Rubber - Soft.001']} scale={0.394} />
          </group>
          <group position={[36.233, -4.754, -5.981]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body11315.geometry} material={materials['Rubber - Soft.001']} scale={0.394} />
          </group>
          <group position={[37.186, 0.882, 1.35]} rotation={[0, -0.611, Math.PI / 2]}>
            <mesh geometry={nodes.Body11313.geometry} material={materials['Rubber - Soft.001']} scale={0.394} />
          </group>
          <group position={[37.186, -19.98, 1.35]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body11310.geometry} material={materials['Rubber - Soft']} scale={0.394} />
          </group>
          <group position={[37.186, -14.212, 1.35]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body11311.geometry} material={materials['Rubber - Soft']} scale={0.394} />
          </group>
          <group position={[37.186, -7.349, 1.35]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body11312.geometry} material={materials['Rubber - Soft']} scale={0.394} />
          </group>
          <group position={[37.186, -1.568, 1.35]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body11320.geometry} material={materials['Rubber - Soft']} scale={0.394} />
          </group>
          <group position={[40.759, -18.024, -0.151]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body11318.geometry} material={materials['Rubber - Soft.001']} scale={0.394} />
          </group>
          <group position={[40.759, -12.199, -0.151]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body11319.geometry} material={materials['Rubber - Soft.001']} scale={0.394} />
          </group>
          <group position={[40.759, -9.337, -0.151]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body11317.geometry} material={materials['Rubber - Soft.001']} scale={0.394} />
          </group>
          <group position={[40.759, -3.492, -0.151]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body11321.geometry} material={materials['Rubber - Soft.001']} scale={0.394} />
          </group>
          <group position={[37.186, 2.118, 1.35]} rotation={[Math.PI, 0, -Math.PI / 2]}>
            <group position={[-4.498, 3.142, -0.362]} rotation={[-0.436, Math.PI / 2, 0]}>
              <mesh geometry={nodes.Body9034.geometry} material={materials['Plastic - Glossy (Black)']} scale={0.394} />
            </group>
            <group position={[-20.114, 2.901, 1.258]} rotation={[0.087, Math.PI / 2, 0]}>
              <mesh geometry={nodes.Body9035.geometry} material={materials['Plastic - Glossy (Black)']} scale={0.394} />
            </group>
            <group position={[-22.895, 1.78, 3.942]} rotation={[0.087, Math.PI / 2, 0]}>
              <mesh geometry={nodes.Body12033.geometry} material={materials['Plastic - Glossy (Black)']} scale={0.394} />
            </group>
            <group position={[-12.279, -2.437, 3.573]} rotation={[-3.054, -1.571, 0]}>
              <mesh geometry={nodes.Body12034.geometry} material={materials['Plastic - Glossy (Black)']} scale={0.394} />
            </group>
            <group position={[-16.259, -2.437, 3.573]} rotation={[-3.054, -1.571, 0]}>
              <mesh geometry={nodes.Body12035.geometry} material={materials['Plastic - Glossy (Black)']} scale={0.394} />
            </group>
            <group position={[-17.134, 1.78, 3.942]} rotation={[0.087, Math.PI / 2, 0]}>
              <mesh geometry={nodes.Body12036.geometry} material={materials['Plastic - Glossy (Black)']} scale={0.394} />
            </group>
            <group position={[-9.404, 2.437, -3.573]} rotation={[0.087, -Math.PI / 2, 0]}>
              <mesh geometry={nodes.Body12037.geometry} material={materials['Plastic - Glossy (Black)']} scale={0.394} />
            </group>
            <group position={[-1.498, 5.777, 3.32]} rotation={[-0.436, Math.PI / 2, 0]}>
              <mesh geometry={nodes.Body20003.geometry} material={materials['Plastic - Glossy (Black)']} scale={0.394} />
            </group>
            <group position={[102.927, -36.205, 25.109]} rotation={[-0.001, -0.348, -1.558]}>
              <group position={[-37.704, -117.78, 16.224]} rotation={[-0.34, 0.616, -1.585]}>
                <mesh geometry={nodes.Body11293.geometry} material={materials['Plastic - Matte (Blue)']} scale={0.394} />
              </group>
              <group position={[-37.704, -117.78, 16.224]} rotation={[-0.347, 0.093, 3.13]}>
                <mesh geometry={nodes.Body11292.geometry} material={materials['Powder Coat (Black).001']} scale={0.394} />
              </group>
            </group>
            <group position={[99.167, -36.256, 23.745]} rotation={[-0.001, -0.348, -1.558]}>
              <group position={[-37.773, -119.302, 18.228]} rotation={[-0.353, -0.431, 1.558]}>
                <mesh geometry={nodes.Body11295.geometry} material={materials['Plastic - Matte (Blue)']} scale={0.394} />
              </group>
              <group position={[-37.773, -119.302, 18.228]} rotation={[-0.347, 0.093, -0.012]}>
                <mesh geometry={nodes.Body11294.geometry} material={materials['Powder Coat (Black).001']} scale={0.394} />
              </group>
            </group>
            <group position={[105.277, -36.173, 25.961]} rotation={[-0.001, -0.348, -1.558]}>
              <group position={[-37.693, -119.358, 15.89]} rotation={[-0.34, 0.616, -1.585]}>
                <mesh geometry={nodes.Body11297.geometry} material={materials['Plastic - Matte (Blue)']} scale={0.394} />
              </group>
              <group position={[-37.693, -119.358, 15.89]} rotation={[-0.347, 0.093, 3.13]}>
                <mesh geometry={nodes.Body11296.geometry} material={materials['Powder Coat (Black).001']} scale={0.394} />
              </group>
            </group>
            <group position={[108.097, -36.134, 26.984]} rotation={[-0.001, -0.348, -1.558]}>
              <group position={[-37.624, -116.836, 13.887]} rotation={[-0.353, -0.431, 1.558]}>
                <mesh geometry={nodes.Body11299.geometry} material={materials['Plastic - Matte (Blue)']} scale={0.394} />
              </group>
              <group position={[-37.624, -116.836, 13.887]} rotation={[-0.347, 0.093, -0.012]}>
                <mesh geometry={nodes.Body11298.geometry} material={materials['Powder Coat (Black).001']} scale={0.394} />
              </group>
            </group>
            <group position={[95.407, -36.308, 22.381]} rotation={[-0.001, -0.348, -3.129]}>
              <group position={[120.698, -37.84, 20.185]} rotation={[-0.644, -0.275, -0.216]}>
                <mesh geometry={nodes.Body11301.geometry} material={materials['Plastic - Matte (Blue)']} scale={0.394} />
              </group>
              <group position={[120.698, -37.84, 20.185]} rotation={[-0.099, -0.345, -1.616]}>
                <mesh geometry={nodes.Body11300.geometry} material={materials['Powder Coat (Black).001']} scale={0.394} />
              </group>
            </group>
            <group position={[-16.697, 0, 0]} rotation={[0.087, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11302.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            </group>
            <group position={[-4.061, 0, 0]} rotation={[-0.436, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11303.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            </group>
            <group position={[-9.842, 0, 0]} rotation={[0.087, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11304.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            </group>
            <group position={[-22.457, 0, 0]} rotation={[0.087, 0, -Math.PI / 2]}>
              <mesh geometry={nodes.Body11305.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            </group>
            <group position={[-1.498, 0, 0]} rotation={[-0.436, -Math.PI / 2, 0]}>
              <mesh geometry={nodes.Body11306.geometry} material={materials['Steel - Satin']} scale={0.394} />
            </group>
            <group position={[-0.625, 0, 0]} rotation={[-Math.PI / 6, -1.571, 0]}>
              <mesh geometry={nodes.Body11290.geometry} material={materials['Steel - Satin']} scale={0.394} />
            </group>
            <group position={[-26.187, 0, 0]} rotation={[0.611, -Math.PI / 2, 0]}>
              <mesh geometry={nodes.Body11291.geometry} material={materials['Steel - Satin']} scale={0.394} />
            </group>
            <mesh geometry={nodes.Body11307.geometry} material={materials['Powder Coat (Black).001']} scale={0.394} />
          </group>
          <group position={[40.759, 2.137, -0.15]} rotation={[0, 0, Math.PI / 2]}>
            <group position={[-1.625, 0, 0]} rotation={[-Math.PI / 6, 1.571, 0]}>
              <mesh geometry={nodes.Body11381.geometry} material={materials['Plastic - Glossy (Black)']} scale={0.394} />
            </group>
            <group position={[-7.437, 0, 0]} rotation={[-Math.PI / 6, 1.571, 0]}>
              <mesh geometry={nodes.Body11382.geometry} material={materials['Plastic - Glossy (Black)']} scale={0.394} />
            </group>
            <group position={[-19.063, 0, 0]} rotation={[-Math.PI / 6, -1.571, 0]}>
              <mesh geometry={nodes.Body11383.geometry} material={materials['Plastic - Glossy (Black)']} scale={0.394} />
            </group>
            <group position={[-20.937, 0, 0]} rotation={[-Math.PI / 9, Math.PI / 2, 0]}>
              <mesh geometry={nodes.Body11366.geometry} material={materials['Plastic - Glossy (Black)']} scale={0.394} />
            </group>
            <group position={[72.827, 40.189, -12.699]} rotation={[-0.001, 0.348, 1.558]}>
              <group position={[-41.336, 90.659, -19.611]} rotation={[-0.355, 0.518, -1.557]}>
                <mesh geometry={nodes.Body11372.geometry} material={materials['Plastic - Matte (Blue)']} scale={0.394} />
              </group>
              <group position={[-41.336, 90.659, -19.611]} rotation={[-0.348, -0.006, -3.13]}>
                <mesh geometry={nodes.Body11371.geometry} material={materials['Powder Coat (Black).001']} scale={0.394} />
              </group>
            </group>
            <group position={[74.707, 40.164, -13.381]} rotation={[-0.001, 0.348, 1.558]}>
              <group position={[-41.268, 87.195, -17.629]} rotation={[-0.355, 0.518, -1.557]}>
                <mesh geometry={nodes.Body11374.geometry} material={materials['Plastic - Matte (Blue)']} scale={0.394} />
              </group>
              <group position={[-41.268, 87.195, -17.629]} rotation={[-0.348, -0.006, -3.13]}>
                <mesh geometry={nodes.Body11373.geometry} material={materials['Powder Coat (Black).001']} scale={0.394} />
              </group>
            </group>
            <group position={[77.527, 40.125, -14.404]} rotation={[-0.001, 0.348, 1.558]}>
              <group position={[-41.256, 89.255, -17.288]} rotation={[-0.355, 0.518, -1.557]}>
                <mesh geometry={nodes.Body11376.geometry} material={materials['Plastic - Matte (Blue)']} scale={0.394} />
              </group>
              <group position={[-41.256, 89.255, -17.288]} rotation={[-0.348, -0.006, -3.13]}>
                <mesh geometry={nodes.Body11375.geometry} material={materials['Powder Coat (Black).001']} scale={0.394} />
              </group>
            </group>
            <group position={[79.407, 40.099, -15.086]} rotation={[-0.001, 0.348, 1.558]}>
              <group position={[-41.189, 85.791, -15.306]} rotation={[-0.355, 0.518, -1.557]}>
                <mesh geometry={nodes.Body11378.geometry} material={materials['Plastic - Matte (Blue)']} scale={0.394} />
              </group>
              <group position={[-41.189, 85.791, -15.306]} rotation={[-0.348, -0.006, -3.13]}>
                <mesh geometry={nodes.Body11377.geometry} material={materials['Powder Coat (Black).001']} scale={0.394} />
              </group>
            </group>
            <group position={[81.287, 40.074, -15.768]} rotation={[-0.001, 0.348, 1.558]}>
              <group position={[-41.121, 82.328, -13.324]} rotation={[-0.355, 0.518, -1.557]}>
                <mesh geometry={nodes.Body11380.geometry} material={materials['Plastic - Matte (Blue)']} scale={0.394} />
              </group>
              <group position={[-41.121, 82.328, -13.324]} rotation={[-0.348, -0.006, -3.13]}>
                <mesh geometry={nodes.Body11379.geometry} material={materials['Powder Coat (Black).001']} scale={0.394} />
              </group>
            </group>
            <group position={[-20.5, 0, 0]} rotation={[-Math.PI / 6, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11367.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            </group>
            <group position={[-14.688, 0, 0]} rotation={[-Math.PI / 6, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11368.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            </group>
            <group position={[-11.813, 0, 0]} rotation={[-Math.PI / 6, 0, -Math.PI / 2]}>
              <mesh geometry={nodes.Body11369.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            </group>
            <group position={[-6, 0, 0]} rotation={[-Math.PI / 6, 0, -Math.PI / 2]}>
              <mesh geometry={nodes.Body11370.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            </group>
            <group position={[-25.875, 0, 0]} rotation={[0.175, -Math.PI / 2, 0]}>
              <mesh geometry={nodes.Body11365.geometry} material={materials['Steel - Satin']} scale={0.394} />
            </group>
            <group position={[-25.875, 0, 0]} rotation={[Math.PI / 6, 1.571, 0]}>
              <mesh geometry={nodes.Body11363.geometry} material={materials['Steel - Satin']} scale={0.394} />
            </group>
            <group position={[-0.625, 0, 0]} rotation={[-Math.PI / 6, -1.571, 0]}>
              <mesh geometry={nodes.Body11364.geometry} material={materials['Steel - Satin']} scale={0.394} />
            </group>
            <mesh geometry={nodes.Body11384.geometry} material={materials['Powder Coat (Black).001']} scale={0.394} />
          </group>
          <group position={[38.575, -21.386, -3.089]} rotation={[0, -Math.PI / 3, -Math.PI]}>
            <group scale={0.394}>
              <mesh geometry={nodes.Body1246_1.geometry} material={materials['255,255,0']} />
              <mesh geometry={nodes.Body1246_2.geometry} material={materials['0,255,0']} />
              <mesh geometry={nodes.Body1246_3.geometry} material={materials['255,0,0']} />
              <mesh geometry={nodes.Body1246_4.geometry} material={materials['0,0,0']} />
              <mesh geometry={nodes.Body1246_5.geometry} material={materials['60,60,60']} />
              <mesh geometry={nodes.Body1246_6.geometry} material={materials['64,64,64']} />
              <mesh geometry={nodes.Body1246_7.geometry} material={materials['76,76,76']} />
              <mesh geometry={nodes.Body1246_8.geometry} material={materials['255,255,255']} />
              <mesh geometry={nodes.Body1246_9.geometry} material={materials['151,215,0']} />
            </group>
            <group scale={0.394}>
              <mesh geometry={nodes.Body2018_1.geometry} material={materials['198,193,188']} />
              <mesh geometry={nodes.Body2018_2.geometry} material={materials['199,194,189']} />
            </group>
          </group>
          <group position={[38.575, -0.859, -3.089]} rotation={[Math.PI, -Math.PI / 3, Math.PI]}>
            <group scale={0.394}>
              <mesh geometry={nodes.Body1246_1.geometry} material={materials['255,255,0']} />
              <mesh geometry={nodes.Body1246_2.geometry} material={materials['0,255,0']} />
              <mesh geometry={nodes.Body1246_3.geometry} material={materials['255,0,0']} />
              <mesh geometry={nodes.Body1246_4.geometry} material={materials['0,0,0']} />
              <mesh geometry={nodes.Body1246_5.geometry} material={materials['60,60,60']} />
              <mesh geometry={nodes.Body1246_6.geometry} material={materials['64,64,64']} />
              <mesh geometry={nodes.Body1246_7.geometry} material={materials['76,76,76']} />
              <mesh geometry={nodes.Body1246_8.geometry} material={materials['255,255,255']} />
              <mesh geometry={nodes.Body1246_9.geometry} material={materials['151,215,0']} />
            </group>
            <group scale={0.394}>
              <mesh geometry={nodes.Body2018_1.geometry} material={materials['198,193,188']} />
              <mesh geometry={nodes.Body2018_2.geometry} material={materials['199,194,189']} />
            </group>
          </group>
          <group position={[33.987, -24.363, -4.191]} rotation={[0, 0, -Math.PI / 2]}>
            <group position={[-23.031, 0, 0]} rotation={[Math.PI / 2, 0, -Math.PI / 2]}>
              <mesh geometry={nodes.Body11325.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
              <mesh geometry={nodes.Body2098.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
            </group>
            <group position={[-3.469, 0, 0]} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11326.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
              <mesh geometry={nodes.Body2099.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
            </group>
            <group position={[-22.031, 0, 0]} rotation={[-Math.PI, -1.571, 0]}>
              <mesh geometry={nodes.Body11331.geometry} material={materials['Plastic - Glossy (Black)']} scale={0.394} />
            </group>
            <group position={[-4.469, 0, 0]} rotation={[0, 1.571, 0]}>
              <mesh geometry={nodes.Body11332.geometry} material={materials['Plastic - Glossy (Black)']} scale={0.394} />
            </group>
            <group position={[-10.25, 0, 0]} rotation={[0, 1.571, 0]}>
              <mesh geometry={nodes.Body11333.geometry} material={materials['Plastic - Glossy (Black)']} scale={0.394} />
            </group>
            <group position={[-4.031, 0, 0]} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11327.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            </group>
            <group position={[-22.469, 0, 0]} rotation={[Math.PI / 2, 0, -Math.PI / 2]}>
              <mesh geometry={nodes.Body11328.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            </group>
            <group position={[-16.688, 0, 0]} rotation={[Math.PI / 2, 0, -Math.PI / 2]}>
              <mesh geometry={nodes.Body11329.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            </group>
            <group position={[-9.813, 0, 0]} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11330.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            </group>
            <group position={[-26.219, 0, 0]} rotation={[0, -1.571, 0]}>
              <mesh geometry={nodes.Body11323.geometry} material={materials['Steel - Satin']} scale={0.394} />
            </group>
            <group position={[-0.594, 0, 0]} rotation={[0, -1.571, 0]}>
              <mesh geometry={nodes.Body11324.geometry} material={materials['Steel - Satin']} scale={0.394} />
            </group>
            <mesh geometry={nodes.Body11334.geometry} material={materials['Powder Coat (Black).001']} scale={0.394} />
          </group>
          <group position={[36.235, 2.137, -5.983]} rotation={[0, 0, Math.PI / 2]}>
            <group position={[-5.437, 0, 0]} rotation={[-1.222, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11356.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
              <mesh geometry={nodes.Body2102.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
            </group>
            <group position={[-21.063, 0, 0]} rotation={[-2.182, 0, -Math.PI / 2]}>
              <mesh geometry={nodes.Body11357.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
              <mesh geometry={nodes.Body2103.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
            </group>
            <group position={[-7.687, 0, 0]} rotation={[0, 1.571, 0]}>
              <mesh geometry={nodes.Body11354.geometry} material={materials['Plastic - Glossy (Black)']} scale={0.394} />
            </group>
            <group position={[-18.813, 0, 0]} rotation={[-Math.PI / 6, -1.571, 0]}>
              <mesh geometry={nodes.Body11355.geometry} material={materials['Plastic - Glossy (Black)']} scale={0.394} />
            </group>
            <group position={[-20.063, 0, 0]} rotation={[-2.094, -Math.PI / 2, 0]}>
              <mesh geometry={nodes.Body11360.geometry} material={materials['Plastic - Glossy (Black)']} scale={0.394} />
            </group>
            <group position={[-6.438, 0, 0]} rotation={[-2.007, Math.PI / 2, 0]}>
              <mesh geometry={nodes.Body11361.geometry} material={materials['Plastic - Glossy (Black)']} scale={0.394} />
            </group>
            <group position={[-14.25, 0, 0]} rotation={[Math.PI / 4, -Math.PI / 2, 0]}>
              <mesh geometry={nodes.Body11352.geometry} material={materials['Plastic - Glossy (Black)']} scale={0.394} />
            </group>
            <group position={[-12.25, 0, 0]} rotation={[-0.611, Math.PI / 2, 0]}>
              <mesh geometry={nodes.Body11353.geometry} material={materials['Plastic - Glossy (Black)']} scale={0.394} />
            </group>
            <group position={[-13.25, 0, 0]} rotation={[0, 0, -Math.PI / 2]}>
              <mesh geometry={nodes.Body11345.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            </group>
            <group position={[-14.687, 0, 0]} rotation={[-Math.PI / 6, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11346.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            </group>
            <group position={[-11.813, 0, 0]} rotation={[-0.611, 0, -Math.PI / 2]}>
              <mesh geometry={nodes.Body11347.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            </group>
            <group position={[-19.25, 0, 0]} rotation={[-Math.PI / 6, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11348.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            </group>
            <group position={[-7.25, 0, 0]} rotation={[-0.436, 0, -Math.PI / 2]}>
              <mesh geometry={nodes.Body11349.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            </group>
            <group position={[-6, 0, 0]} rotation={[-0.436, 0, -Math.PI / 2]}>
              <mesh geometry={nodes.Body11350.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            </group>
            <group position={[-20.5, 0, 0]} rotation={[-Math.PI / 6, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11351.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            </group>
            <group position={[-0.594, 0, 0]} rotation={[-Math.PI / 2, -Math.PI / 2, 0]}>
              <mesh geometry={nodes.Body11358.geometry} material={materials['Steel - Satin']} scale={0.394} />
            </group>
            <group position={[-25.906, 0, 0]} rotation={[Math.PI / 2, Math.PI / 2, 0]}>
              <mesh geometry={nodes.Body11359.geometry} material={materials['Steel - Satin']} scale={0.394} />
            </group>
            <mesh geometry={nodes.Body11362.geometry} material={materials['Powder Coat (Black).001']} scale={0.394} />
          </group>
          <group position={[32.83, -24.363, -8.816]} rotation={[0, 0, -Math.PI / 2]}>
            <group position={[-6.708, 0, 0]} rotation={[-Math.PI, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11340.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
              <mesh geometry={nodes.Body2100.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
            </group>
            <group position={[-19.813, 0, 0]} rotation={[0, 0, -Math.PI / 2]}>
              <mesh geometry={nodes.Body11341.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
              <mesh geometry={nodes.Body2101.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
            </group>
            <group position={[-18.813, 0, 0]} rotation={[Math.PI / 2, -Math.PI / 2, 0]}>
              <mesh geometry={nodes.Body11338.geometry} material={materials['Plastic - Glossy (Black)']} scale={0.394} />
            </group>
            <group position={[-7.688, 0, 0]} rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
              <mesh geometry={nodes.Body11339.geometry} material={materials['Plastic - Glossy (Black)']} scale={0.394} />
            </group>
            <group position={[-7.25, 0, 0]} rotation={[-Math.PI, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11335.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            </group>
            <group position={[-13.25, 0, 0]} rotation={[0, 0, -Math.PI / 2]}>
              <mesh geometry={nodes.Body11336.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            </group>
            <group position={[-19.25, 0, 0]} rotation={[-Math.PI, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11337.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            </group>
            <group position={[-25.906, 0, 0]} rotation={[Math.PI / 2, Math.PI / 2, 0]}>
              <mesh geometry={nodes.Body11342.geometry} material={materials['Steel - Satin']} scale={0.394} />
            </group>
            <group position={[-0.594, 0, 0]} rotation={[-Math.PI / 2, -Math.PI / 2, 0]}>
              <mesh geometry={nodes.Body11343.geometry} material={materials['Steel - Satin']} scale={0.394} />
            </group>
            <mesh geometry={nodes.Body11344.geometry} material={materials['Powder Coat (Black).001']} scale={0.394} />
          </group>
          <group position={[32.215, -6.123, -5.584]} rotation={[Math.PI, 0.611, -Math.PI / 2]}>
            <mesh geometry={nodes.Body11274.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
          </group>
          <group position={[35.186, 1.502, 0.475]} rotation={[-Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body11278.geometry} material={materials['Opaque(221,232,255)']} scale={0.394} />
          </group>
          <group position={[35.186, -23.748, 0.475]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body11277.geometry} material={materials['Opaque(221,232,255)']} scale={0.394} />
          </group>
          <group position={[35.186, -23.748, 0.475]} rotation={[Math.PI / 2, 0, -Math.PI]}>
            <mesh geometry={nodes.Body11272.geometry} material={materials['Opaque(221,232,255)']} scale={0.394} />
          </group>
          <group position={[35.186, 1.502, 0.475]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body11273.geometry} material={materials['Opaque(221,232,255)']} scale={0.394} />
          </group>
          <group position={[41.311, 1.502, -2.65]} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body11269.geometry} material={materials['Opaque(221,232,255)']} scale={0.394} />
          </group>
          <group position={[41.311, -23.748, -2.65]} rotation={[Math.PI / 2, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body11270.geometry} material={materials['Opaque(221,232,255)']} scale={0.394} />
          </group>
          <group position={[34.686, 1.877, 0.975]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body11271.geometry} material={materials['Millennium Falcon Green']} scale={0.394} />
          </group>
          <group position={[40.811, -24.123, -1.65]}>
            <mesh geometry={nodes.Body11268.geometry} material={materials['Millennium Falcon Green']} scale={0.394} />
          </group>
          <group position={[40.811, 2.127, -3.65]} rotation={[0, -0.66, -Math.PI / 2]}>
            <mesh geometry={nodes.Body11276.geometry} material={materials['Polycarbonate (Smoked)']} scale={0.394} />
          </group>
          <group position={[40.811, -12.06, -1.65]}>
            <mesh geometry={nodes.Body11279.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[34.686, 1.877, 0.975]} rotation={[Math.PI / 2, 0, -Math.PI]}>
            <mesh geometry={nodes.Body3049.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[34.686, -24.373, 0.975]} rotation={[Math.PI / 2, 0, -Math.PI]}>
            <mesh geometry={nodes.Body3050.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[39.979, 1.877, 0.855]} rotation={[-Math.PI / 2, 0, -2.347]}>
            <mesh geometry={nodes['Body2_(1)_(1)'].geometry} material={materials['Plastic - Matte (Green)']} scale={0.394} />
            <mesh geometry={nodes['Body2_(1)_(2)'].geometry} material={materials['Plastic - Matte (Green)']} scale={0.394} />
            <mesh geometry={nodes.Body2093.geometry} material={materials['Plastic - Matte (Green)']} scale={0.394} />
          </group>
          <group position={[38.482, -24.123, 1.483]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body2094.geometry} material={materials['ABS (White)']} scale={0.394} />
          </group>
          <group position={[34.686, 1.877, 0.975]} rotation={[Math.PI / 2, 0, -Math.PI]}>
            <mesh geometry={nodes.Body4039.geometry} material={materials['Plastic - Glossy (Black)']} scale={0.394} />
          </group>
          <group position={[38.075, -24.123, -3.223]} rotation={[Math.PI / 2, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body11275.geometry} material={materials['Plastic - Glossy (Black)']} scale={0.394} />
          </group>
          <group position={[34.686, 1.877, 0.974]} rotation={[Math.PI / 2, 0, Math.PI]}>
            <mesh geometry={nodes['Body4_(1)'].geometry} material={materials['Plastic - Glossy (Black)']} scale={0.394} />
          </group>
          <group position={[38.075, -24.123, -3.223]} rotation={[Math.PI / 2, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body2095.geometry} material={materials['Plastic - Glossy (Black)']} scale={0.394} />
          </group>
        </group>
        <group position={[21.748, 11.734, -17.481]} rotation={[Math.PI / 6, 0, 0]}>
          <group position={[13.25, 2.688, 0.093]} rotation={[-Math.PI, 0, -Math.PI]}>
            <mesh geometry={nodes.Body1785.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[13.781, 8.25, 0.937]} rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
            <mesh geometry={nodes.Body1801.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[13.781, 8.25, 1.938]} rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
            <mesh geometry={nodes.Body1849.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[13.781, 9.938, 2.625]} rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
            <mesh geometry={nodes.Body1850.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[15.469, 16.26, -1.045]} rotation={[Math.PI / 2, -1.571, 0]}>
            <mesh geometry={nodes.Body1793.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-0.5, 2.688, 0.093]} rotation={[-Math.PI, 0, -Math.PI]}>
            <mesh geometry={nodes.Body1784.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[13.781, 10.25, 0.937]} rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
            <mesh geometry={nodes.Body1800.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[13.781, 10.25, 1.937]} rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
            <mesh geometry={nodes.Body1799.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[14.101, 10.563, 0.188]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1830.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[14.101, 9.563, 0.25]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1868.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[14.101, 9.063, 0.25]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1829.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[14.101, 8.063, 0.25]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1882.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[14.101, 7.563, 0.25]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1827.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[1.438, 1.744, -1.453]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1778.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[2.304, 3.244, -1.453]} rotation={[-Math.PI / 2, Math.PI / 3, Math.PI]}>
            <mesh geometry={nodes.Body1782.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[0.853, 11.938, 0.25]} rotation={[-Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1881.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[0.853, 12.438, 0.25]} rotation={[-Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1828.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[0.853, 9.563, 0.25]} rotation={[-Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1802.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[0.853, 9.063, 0.25]} rotation={[-Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1805.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[0.853, 8.563, 0.25]} rotation={[-Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1833.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[15.228, 15.724, 0.25]} rotation={[Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1883.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[15.228, 16.431, 1.957]} rotation={[Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1884.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[1.438, 3.744, -1.453]} rotation={[-Math.PI / 2, 0, Math.PI]}>
            <mesh geometry={nodes.Body1780.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[0.571, 3.244, -1.453]} rotation={[-Math.PI / 2, -Math.PI / 3, Math.PI]}>
            <mesh geometry={nodes.Body1783.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[0.571, 2.244, -1.453]} rotation={[Math.PI / 2, -Math.PI / 3, 0]}>
            <mesh geometry={nodes.Body1781.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[13.625, 7.313, -1.453]} rotation={[Math.PI / 2, -1.571, 0]}>
            <mesh geometry={nodes.Body1867.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[13.625, 7.813, -1.453]} rotation={[Math.PI / 2, -1.571, 0]}>
            <mesh geometry={nodes.Body1839.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[13.625, 8.813, -1.453]} rotation={[Math.PI / 2, -1.571, 0]}>
            <mesh geometry={nodes.Body1840.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[13.625, 8.313, -1.453]} rotation={[Math.PI / 2, -1.571, 0]}>
            <mesh geometry={nodes.Body1841.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[13.625, 9.313, -1.453]} rotation={[Math.PI / 2, -1.571, 0]}>
            <mesh geometry={nodes.Body1842.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[11.312, 1.744, -1.453]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1772.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[13.625, 12.188, -1.453]} rotation={[Math.PI / 2, -1.571, 0]}>
            <mesh geometry={nodes.Body1843.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[13.625, 9.813, -1.453]} rotation={[Math.PI / 2, -1.571, 0]}>
            <mesh geometry={nodes.Body1844.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[15.228, 14.724, 1.25]} rotation={[-Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1869.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[15.228, 15.724, 2.25]} rotation={[-Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1834.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[15.228, 14.938, -2.813]} rotation={[-Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1832.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[15.228, 7.563, -3.25]} rotation={[-Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1831.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[15.228, 10.313, -2.814]} rotation={[-Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1826.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[12.179, 2.244, -1.453]} rotation={[Math.PI / 2, Math.PI / 3, 0]}>
            <mesh geometry={nodes.Body1777.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-2.478, 7.563, -3.25]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1797.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[13.625, 7.313, -1.272]} rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
            <mesh geometry={nodes.Body1871.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-0.875, 7.313, -1.272]} rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
            <mesh geometry={nodes.Body1870.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[15.228, 15.017, 0.543]} rotation={[-Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1877.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-2.478, 10.313, -2.814]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1879.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-2.478, 10.563, 0.188]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1878.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[12.179, 3.244, -1.453]} rotation={[-Math.PI / 2, Math.PI / 3, Math.PI]}>
            <mesh geometry={nodes.Body1776.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-2.478, 7.563, 0.25]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1873.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-0.875, 7.313, -1.453]} rotation={[Math.PI / 2, -1.571, 0]}>
            <mesh geometry={nodes.Body1876.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-0.875, 12.188, -1.453]} rotation={[Math.PI / 2, -1.571, 0]}>
            <mesh geometry={nodes.Body1875.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-2.478, 14.938, -2.813]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1874.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-2.478, 11.938, 0.25]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1836.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[13.625, 12.687, -1.478]} rotation={[Math.PI / 2, -1.571, 0]}>
            <mesh geometry={nodes.Body1865.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[13.625, 13.187, -1.478]} rotation={[Math.PI / 2, -1.571, 0]}>
            <mesh geometry={nodes.Body1835.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[13.625, 13.688, -1.478]} rotation={[Math.PI / 2, -1.571, 0]}>
            <mesh geometry={nodes.Body1863.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[11.312, 3.744, -1.453]} rotation={[-Math.PI / 2, 0, Math.PI]}>
            <mesh geometry={nodes.Body1774.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[13.625, 14.188, -1.478]} rotation={[Math.PI / 2, -1.571, 0]}>
            <mesh geometry={nodes.Body1854.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[15.228, 12.438, 0.25]} rotation={[Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1866.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[15.228, 12.938, 0.25]} rotation={[Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1852.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[15.228, 13.438, 0.25]} rotation={[Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1864.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[10.446, 3.244, -1.453]} rotation={[-Math.PI / 2, -Math.PI / 3, Math.PI]}>
            <mesh geometry={nodes.Body1775.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[15.228, 13.938, 0.25]} rotation={[Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1858.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[15.228, 14.438, 0.25]} rotation={[Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1853.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[15.228, 8.063, -3.25]} rotation={[-Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1861.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[15.228, 8.563, -3.25]} rotation={[-Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1862.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[15.228, 9.063, -3.25]} rotation={[-Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1851.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[15.228, 9.562, -3.25]} rotation={[-Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1856.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[13.625, 7.812, -1.522]} rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
            <mesh geometry={nodes.Body1795.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[13.625, 8.312, -1.522]} rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
            <mesh geometry={nodes.Body1855.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[13.625, 8.813, -1.522]} rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
            <mesh geometry={nodes.Body1857.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[13.625, 9.312, -1.522]} rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
            <mesh geometry={nodes.Body1860.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[10.446, 2.244, -1.453]} rotation={[Math.PI / 2, -Math.PI / 3, 0]}>
            <mesh geometry={nodes.Body1773.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[13.625, 9.813, -1.522]} rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
            <mesh geometry={nodes.Body1819.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-0.875, 7.812, -1.522]} rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
            <mesh geometry={nodes.Body1859.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-0.875, 8.312, -1.522]} rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
            <mesh geometry={nodes.Body1821.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-0.875, 8.813, -1.522]} rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
            <mesh geometry={nodes.Body1822.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-0.875, 9.312, -1.522]} rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
            <mesh geometry={nodes.Body1823.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-0.875, 9.813, -1.522]} rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
            <mesh geometry={nodes.Body1825.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-0.875, 7.812, -1.478]} rotation={[Math.PI / 2, -1.571, 0]}>
            <mesh geometry={nodes.Body1824.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-0.875, 8.313, -1.478]} rotation={[Math.PI / 2, -1.571, 0]}>
            <mesh geometry={nodes.Body1816.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-0.875, 8.813, -1.478]} rotation={[Math.PI / 2, -1.571, 0]}>
            <mesh geometry={nodes.Body1794.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-0.875, 9.313, -1.478]} rotation={[Math.PI / 2, -1.571, 0]}>
            <mesh geometry={nodes.Body1818.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-0.875, 9.813, -1.478]} rotation={[Math.PI / 2, -1.571, 0]}>
            <mesh geometry={nodes.Body1817.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-0.875, 12.687, -1.478]} rotation={[Math.PI / 2, -1.571, 0]}>
            <mesh geometry={nodes.Body1820.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-0.875, 13.187, -1.478]} rotation={[Math.PI / 2, -1.571, 0]}>
            <mesh geometry={nodes.Body1804.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-0.875, 13.688, -1.478]} rotation={[Math.PI / 2, -1.571, 0]}>
            <mesh geometry={nodes.Body1846.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-0.875, 14.188, -1.478]} rotation={[Math.PI / 2, -1.571, 0]}>
            <mesh geometry={nodes.Body1847.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-2.478, 20.426, -3.063]} rotation={[Math.PI, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1848.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-2.478, 20.426, 0.313]} rotation={[Math.PI, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1838.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-2.478, 8.063, 0.25]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1796.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-2.478, 8.563, 0.25]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1880.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-2.478, 9.063, 0.25]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1845.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[2.304, 2.244, -1.453]} rotation={[Math.PI / 2, Math.PI / 3, 0]}>
            <mesh geometry={nodes.Body1779.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-2.478, 9.563, 0.25]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1811.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-2.478, 8.063, -3.25]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1808.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-2.478, 8.563, -3.25]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1814.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-2.478, 9.063, -3.25]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1813.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-2.478, 9.562, -3.25]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1809.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-2.478, 12.438, 0.25]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1807.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-2.478, 12.938, 0.25]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1812.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-2.478, 13.438, 0.25]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1810.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-2.478, 13.938, 0.25]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1806.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-2.478, 14.438, 0.25]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1815.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[15.221, 10.25, 1.937]} rotation={[-Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1792.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[15.221, 10.25, 0.937]} rotation={[-Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1789.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[15.221, 8.25, 0.937]} rotation={[-Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1790.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[15.221, 8.25, 1.938]} rotation={[-Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1791.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[15.221, 8.563, 0.25]} rotation={[-Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1788.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[15.221, 9.938, 2.625]} rotation={[-Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1787.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[13.838, 16.26, -1.045]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1803.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[15.412, 20.426, -3.063]} rotation={[Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1837.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[15.412, 20.426, 0.313]} rotation={[Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1872.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[15.412, 15.016, 1.957]} rotation={[Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1798.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[13.25, 2.687, -1.787]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1885.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[-0.5, 2.687, -1.787]} rotation={[Math.PI / 2, 1.571, 0]}>
            <mesh geometry={nodes.Body1786.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[12.75, 0.5, 0.15]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1771.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            <mesh geometry={nodes.Body2070.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[0, 4.929, 0.15]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1770.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            <mesh geometry={nodes.Body2069.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[0, 0.5, 0.15]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1768.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            <mesh geometry={nodes.Body2067.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[12.75, 4.929, 0.15]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1769.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            <mesh geometry={nodes.Body2068.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[14.184, 9.25, 1.437]} rotation={[Math.PI, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1703.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
          </group>
          <group position={[16.375, 18.313, -2.814]} rotation={[-Math.PI / 6, 0, 0]}>
            <mesh geometry={nodes.Body1695.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
          </group>
          <group position={[16.06, 18.313, 0.062]}>
            <mesh geometry={nodes.Body1683.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
          </group>
          <group position={[11.312, 2.744, 14.005]} rotation={[-Math.PI / 2, -Math.PI / 3, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1763.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
          </group>
          <group position={[1.438, 2.744, 14.005]} rotation={[Math.PI / 2, -Math.PI / 3, Math.PI / 2]}>
            <mesh geometry={nodes.Body1762.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
          </group>
          <group position={[0, 4.929, -17.625]} rotation={[0, 1.571, 0]}>
            <mesh geometry={nodes.Body1759.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
          </group>
          <group position={[0, 0.5, -17.625]} rotation={[0, 1.571, 0]}>
            <mesh geometry={nodes.Body1761.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
          </group>
          <group position={[12.75, 4.929, -17.625]} rotation={[0, 1.571, 0]}>
            <mesh geometry={nodes.Body1760.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
          </group>
          <group position={[12.75, 0.5, -17.625]} rotation={[0, 1.571, 0]}>
            <mesh geometry={nodes.Body1758.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
          </group>
          <group position={[14.563, 18.313, -2.814]} rotation={[-2.88, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1682.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[0, 4.929, -0.725]} rotation={[-Math.PI, 0, 0]}>
            <mesh geometry={nodes.Body1721.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[0, 0.5, -0.725]} rotation={[-Math.PI, 0, 0]}>
            <mesh geometry={nodes.Body1722.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[12.75, 0.5, -0.725]} rotation={[-Math.PI, 0, 0]}>
            <mesh geometry={nodes.Body1725.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[12.75, 4.929, -0.725]} rotation={[-Math.PI, 0, 0]}>
            <mesh geometry={nodes.Body1727.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[0, 4.929, -2.413]} rotation={[-Math.PI, 0, 0]}>
            <mesh geometry={nodes.Body1720.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[12.75, 4.929, -2.413]} rotation={[-Math.PI, 0, 0]}>
            <mesh geometry={nodes.Body1723.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[12.75, 0.5, -2.413]} rotation={[-Math.PI, 0, 0]}>
            <mesh geometry={nodes.Body1724.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[0, 0.5, -2.413]} rotation={[-Math.PI, 0, 0]}>
            <mesh geometry={nodes.Body1726.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[15.437, 16.79, 0.818]} rotation={[0, 1.571, 0]}>
            <mesh geometry={nodes.Body1697.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
          </group>
          <group position={[-1.156, 9.25, 1.437]} rotation={[0, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1701.geometry} material={materials['Opaque(158,104,51)']} scale={0.394} />
          </group>
          <group position={[13.906, 9.25, 1.437]} rotation={[Math.PI, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1700.geometry} material={materials['Opaque(158,104,51)']} scale={0.394} />
          </group>
          <group position={[13.344, 16.79, 0.818]}>
            <mesh geometry={nodes.Body1699.geometry} material={materials['Aluminum - Satin']} scale={0.394} />
          </group>
          <group position={[15.455, 16.79, 0.818]}>
            <mesh geometry={nodes.Body1698.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
          </group>
          <group position={[0, 4.929, -3.75]} rotation={[-Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1732.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            <mesh geometry={nodes.Body2061.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[0, 0.5, -3.75]} rotation={[-Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1734.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            <mesh geometry={nodes.Body2063.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[11.312, 2.744, -3.75]} rotation={[-Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1735.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            <mesh geometry={nodes.Body2064.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[1.438, 2.744, -3.75]} rotation={[-Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1736.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            <mesh geometry={nodes.Body2065.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[12.75, 4.929, -3.75]} rotation={[-Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1737.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            <mesh geometry={nodes.Body2066.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[12.75, 0.5, -3.75]} rotation={[-Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1733.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            <mesh geometry={nodes.Body2062.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[14.063, 18.313, 0.062]} rotation={[-Math.PI, 0, -Math.PI]}>
            <mesh geometry={nodes.Body1678.geometry} material={materials['Opaque(165,158,150)']} scale={0.394} />
          </group>
          <group position={[-1.313, 18.313, -2.814]}>
            <mesh geometry={nodes.Body1676.geometry} material={materials['Opaque(165,158,150)']} scale={0.394} />
          </group>
          <group position={[14.063, 18.313, -2.814]} rotation={[-Math.PI, 0, -Math.PI]}>
            <mesh geometry={nodes.Body1677.geometry} material={materials['Opaque(165,158,150)']} scale={0.394} />
          </group>
          <group position={[-1.313, 18.313, 0.062]}>
            <mesh geometry={nodes.Body1675.geometry} material={materials['Opaque(165,158,150)']} scale={0.394} />
          </group>
          <group position={[0, 0.5, -0.131]} rotation={[Math.PI / 2, 0, -Math.PI]}>
            <mesh geometry={nodes.Body1663.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
          </group>
          <group position={[0, 4.929, -0.131]} rotation={[Math.PI / 2, 0, -Math.PI]}>
            <mesh geometry={nodes.Body1714.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
          </group>
          <group position={[12.75, 4.929, -0.131]} rotation={[Math.PI / 2, 0, -Math.PI]}>
            <mesh geometry={nodes.Body1712.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
          </group>
          <group position={[0, 0.5, -2.594]} rotation={[-Math.PI / 2, 0, Math.PI]}>
            <mesh geometry={nodes.Body1665.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
          </group>
          <group position={[12.75, 0.5, -2.594]} rotation={[-Math.PI / 2, 0, Math.PI]}>
            <mesh geometry={nodes.Body1668.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
          </group>
          <group position={[12.75, 0.5, -0.131]} rotation={[Math.PI / 2, 0, -Math.PI]}>
            <mesh geometry={nodes.Body1667.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
          </group>
          <group position={[0, 4.929, -2.594]} rotation={[-Math.PI / 2, 0, Math.PI]}>
            <mesh geometry={nodes.Body1666.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
          </group>
          <group position={[12.75, 4.929, -2.594]} rotation={[-Math.PI / 2, 0, Math.PI]}>
            <mesh geometry={nodes.Body1664.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
          </group>
          <group position={[13.906, 16.789, 0.817]} rotation={[Math.PI, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1674.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
          </group>
          <group position={[11.312, 2.744, -2.594]} rotation={[-Math.PI / 2, 0, Math.PI]}>
            <mesh geometry={nodes.Body1713.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
          </group>
          <group position={[1.438, 2.744, -2.594]} rotation={[-Math.PI / 2, 0, Math.PI]}>
            <mesh geometry={nodes.Body1715.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
          </group>
          <group position={[1.438, 2.744, -1.225]} rotation={[Math.PI / 2, 0, 0]}>
            <group rotation={[Math.PI, 0, 0]}>
              <mesh geometry={nodes.Body1749.geometry} material={materials['Opaque(76,76,76).001']} scale={0.394} />
            </group>
            <group position={[0.386, 0.187, -0.277]} rotation={[-Math.PI / 2, 0.109, 0]}>
              <mesh geometry={nodes.Body1750.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
            </group>
            <group position={[0.386, 0.562, -0.277]} rotation={[Math.PI / 2, 0.699, Math.PI]}>
              <mesh geometry={nodes.Body1751.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
            </group>
            <group position={[-0.386, 0.562, -0.277]} rotation={[1.571, -1.456, 3.142]}>
              <mesh geometry={nodes.Body1752.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
            </group>
            <group position={[-0.386, 0.187, -0.277]} rotation={[-Math.PI / 2, -1.366, 0]}>
              <mesh geometry={nodes.Body1753.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
            </group>
            <group position={[-0.386, -0.188, 0.277]} rotation={[Math.PI / 2, -1.366, 0]}>
              <mesh geometry={nodes.Body1754.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
            </group>
            <group position={[-0.386, -0.563, 0.277]} rotation={[-1.571, -1.456, -3.142]}>
              <mesh geometry={nodes.Body1755.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
            </group>
            <group position={[0.386, -0.563, 0.277]} rotation={[-Math.PI / 2, 0.699, -Math.PI]}>
              <mesh geometry={nodes.Body1756.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
            </group>
            <group position={[0.386, -0.188, 0.277]} rotation={[Math.PI / 2, 0.109, 0]}>
              <mesh geometry={nodes.Body1757.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
            </group>
            <mesh geometry={nodes.Body1748.geometry} material={materials['Opaque(76,76,76).001']} scale={0.394} />
          </group>
          <group position={[11.312, 2.744, -1.225]} rotation={[Math.PI / 2, 0, 0]}>
            <group rotation={[Math.PI, 0, 0]}>
              <mesh geometry={nodes.Body1739.geometry} material={materials['Opaque(76,76,76).001']} scale={0.394} />
            </group>
            <group position={[0.386, 0.187, -0.277]} rotation={[-Math.PI / 2, 0.109, 0]}>
              <mesh geometry={nodes.Body1740.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
            </group>
            <group position={[0.386, 0.562, -0.277]} rotation={[Math.PI / 2, 0.699, Math.PI]}>
              <mesh geometry={nodes.Body1741.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
            </group>
            <group position={[-0.386, 0.562, -0.277]} rotation={[1.571, -1.456, 3.142]}>
              <mesh geometry={nodes.Body1742.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
            </group>
            <group position={[-0.386, 0.187, -0.277]} rotation={[-Math.PI / 2, -1.366, 0]}>
              <mesh geometry={nodes.Body1743.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
            </group>
            <group position={[-0.386, -0.188, 0.277]} rotation={[Math.PI / 2, -1.366, 0]}>
              <mesh geometry={nodes.Body1744.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
            </group>
            <group position={[-0.386, -0.563, 0.277]} rotation={[-1.571, -1.456, -3.142]}>
              <mesh geometry={nodes.Body1745.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
            </group>
            <group position={[0.386, -0.563, 0.277]} rotation={[-Math.PI / 2, 0.699, -Math.PI]}>
              <mesh geometry={nodes.Body1746.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
            </group>
            <group position={[0.386, -0.188, 0.277]} rotation={[Math.PI / 2, 0.109, 0]}>
              <mesh geometry={nodes.Body1747.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
            </group>
            <mesh geometry={nodes.Body1738.geometry} material={materials['Opaque(76,76,76).001']} scale={0.394} />
          </group>
          <group position={[14.253, 15.724, 1.25]} rotation={[Math.PI / 4, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1706.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[-1.438, 18.313, 0.062]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1684.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
            <mesh geometry={nodes.Body2048.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-1.438, 18.313, -2.814]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1688.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
            <mesh geometry={nodes.Body2051.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[15.25, 18.313, -2.814]} rotation={[-2.618, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1685.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
            <mesh geometry={nodes.Body2049.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[14.685, 18.313, 0.062]} rotation={[-Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1686.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
            <mesh geometry={nodes.Body2050.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[14.875, 18.313, -2.814]} rotation={[-Math.PI / 6, 0, 0]}>
            <mesh geometry={nodes.Body1687.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[-0.625, 9.25, 1.437]} rotation={[0, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1705.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[14.311, 16.789, 0.817]} rotation={[-Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1696.geometry} material={materials['Opaque(221,232,255)']} scale={0.394} />
          </group>
          <group position={[14.311, 18.313, 0.062]} rotation={[-Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1694.geometry} material={materials['Opaque(255,255,192)']} scale={0.394} />
          </group>
          <group position={[12.75, 4.929, -1.273]} rotation={[Math.PI / 2, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1658.geometry} material={materials['Opaque(126,126,126)']} scale={0.394} />
            <mesh geometry={nodes.Body1657.geometry} material={materials['Opaque(25,25,25)']} scale={0.394} />
          </group>
          <group position={[0, 4.929, -1.273]} rotation={[Math.PI / 2, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1660.geometry} material={materials['Opaque(126,126,126)']} scale={0.394} />
            <mesh geometry={nodes.Body1659.geometry} material={materials['Opaque(25,25,25)']} scale={0.394} />
          </group>
          <group position={[0, 0.5, -1.273]} rotation={[Math.PI / 2, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1670.geometry} material={materials['Opaque(126,126,126)']} scale={0.394} />
            <mesh geometry={nodes.Body1669.geometry} material={materials['Opaque(25,25,25)']} scale={0.394} />
          </group>
          <group position={[12.75, 0.5, -1.273]} rotation={[Math.PI / 2, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1672.geometry} material={materials['Opaque(126,126,126)']} scale={0.394} />
            <mesh geometry={nodes.Body1671.geometry} material={materials['Opaque(25,25,25)']} scale={0.394} />
          </group>
          <group position={[1.438, 2.744, -3.183]}>
            <mesh geometry={nodes.Body1767.geometry} material={materials['Rubber - Soft']} scale={0.394} />
          </group>
          <group position={[11.312, 2.744, -3.537]} rotation={[-Math.PI, 0, -Math.PI]}>
            <mesh geometry={nodes.Body1765.geometry} material={materials['Rubber - Soft']} scale={0.394} />
          </group>
          <group position={[14.639, 16.789, 0.817]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1702.geometry} material={materials['Rubber - Soft']} scale={0.394} />
          </group>
          <group position={[-1.569, 19.668, -1.375]} rotation={[Math.PI, -1.571, 0]}>
            <mesh geometry={nodes.Body1711.geometry} material={materials['Opaque(179,179,179)']} scale={0.394} />
          </group>
          <group position={[-1.569, 10.598, -1.375]} rotation={[0, -1.571, 0]}>
            <mesh geometry={nodes.Body1710.geometry} material={materials['Opaque(179,179,179)']} scale={0.394} />
          </group>
          <group position={[14.319, 19.668, -1.375]} rotation={[Math.PI, 1.571, 0]}>
            <mesh geometry={nodes.Body1709.geometry} material={materials['Opaque(179,179,179)']} scale={0.394} />
          </group>
          <group position={[14.319, 10.596, -1.375]} rotation={[0, 1.571, 0]}>
            <mesh geometry={nodes.Body1708.geometry} material={materials['Opaque(179,179,179)']} scale={0.394} />
          </group>
          <group position={[0, 4.929, -2.829]}>
            <mesh geometry={nodes.Body1766.geometry} material={materials['Rubber - Soft']} scale={0.394} />
          </group>
          <group position={[12.75, 4.929, -2.829]}>
            <mesh geometry={nodes.Body1764.geometry} material={materials['Rubber - Soft']} scale={0.394} />
          </group>
          <group position={[1.438, 2.744, 1.454]} rotation={[-Math.PI / 2, 0, 0]}>
            <group scale={0.394}>
              <mesh geometry={nodes.Body1246_1.geometry} material={materials['255,255,0']} />
              <mesh geometry={nodes.Body1246_2.geometry} material={materials['0,255,0']} />
              <mesh geometry={nodes.Body1246_3.geometry} material={materials['255,0,0']} />
              <mesh geometry={nodes.Body1246_4.geometry} material={materials['0,0,0']} />
              <mesh geometry={nodes.Body1246_5.geometry} material={materials['60,60,60']} />
              <mesh geometry={nodes.Body1246_6.geometry} material={materials['64,64,64']} />
              <mesh geometry={nodes.Body1246_7.geometry} material={materials['76,76,76']} />
              <mesh geometry={nodes.Body1246_8.geometry} material={materials['255,255,255']} />
              <mesh geometry={nodes.Body1246_9.geometry} material={materials['151,215,0']} />
            </group>
            <group scale={0.394}>
              <mesh geometry={nodes.Body2018_1.geometry} material={materials['198,193,188']} />
              <mesh geometry={nodes.Body2018_2.geometry} material={materials['199,194,189']} />
            </group>
          </group>
          <group position={[11.312, 2.744, 1.454]} rotation={[-Math.PI / 2, 0, 0]}>
            <group scale={0.394}>
              <mesh geometry={nodes.Body1246_1.geometry} material={materials['255,255,0']} />
              <mesh geometry={nodes.Body1246_2.geometry} material={materials['0,255,0']} />
              <mesh geometry={nodes.Body1246_3.geometry} material={materials['255,0,0']} />
              <mesh geometry={nodes.Body1246_4.geometry} material={materials['0,0,0']} />
              <mesh geometry={nodes.Body1246_5.geometry} material={materials['60,60,60']} />
              <mesh geometry={nodes.Body1246_6.geometry} material={materials['64,64,64']} />
              <mesh geometry={nodes.Body1246_7.geometry} material={materials['76,76,76']} />
              <mesh geometry={nodes.Body1246_8.geometry} material={materials['255,255,255']} />
              <mesh geometry={nodes.Body1246_9.geometry} material={materials['151,215,0']} />
            </group>
            <group scale={0.394}>
              <mesh geometry={nodes.Body2018_1.geometry} material={materials['198,193,188']} />
              <mesh geometry={nodes.Body2018_2.geometry} material={materials['199,194,189']} />
            </group>
          </group>
          <group position={[11.312, 2.744, -0.745]} rotation={[0, 1.571, 0]}>
            <mesh geometry={nodes.Body1719.geometry} material={materials['Opaque(76,76,76).001']} scale={0.394} />
          </group>
          <group position={[1.438, 2.744, -0.745]} rotation={[0, 1.571, 0]}>
            <mesh geometry={nodes.Body1717.geometry} material={materials['Opaque(76,76,76).001']} scale={0.394} />
          </group>
          <group position={[0, 4.929, -3.188]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1728.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            <mesh geometry={nodes.Body2057.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            <mesh geometry={nodes.Body3030.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
          </group>
          <group position={[0, 0.5, -3.187]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1729.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            <mesh geometry={nodes.Body2058.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            <mesh geometry={nodes.Body3031.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
          </group>
          <group position={[12.75, 0.5, -3.187]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1730.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            <mesh geometry={nodes.Body2059.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            <mesh geometry={nodes.Body3032.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
          </group>
          <group position={[12.75, 4.929, -3.188]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1731.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            <mesh geometry={nodes.Body2060.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            <mesh geometry={nodes.Body3033.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
          </group>
          <group position={[1.438, 2.744, -3.188]} rotation={[-Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1661.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            <mesh geometry={nodes.Body2046.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            <mesh geometry={nodes.Body3025.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
          </group>
          <group position={[11.312, 2.744, -3.188]} rotation={[-Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1662.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            <mesh geometry={nodes.Body2047.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            <mesh geometry={nodes.Body3026.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
          </group>
          <group position={[14.844, 18.313, -2.814]} rotation={[-Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1689.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            <mesh geometry={nodes.Body2052.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            <mesh geometry={nodes.Body3027.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
          </group>
          <group position={[14.842, 16.789, 0.817]} rotation={[-Math.PI, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1690.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            <mesh geometry={nodes.Body2053.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
            <mesh geometry={nodes.Body3028.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
          </group>
          <group position={[0, 13, 0]}>
            <group position={[-0.935, 5.313, -2.814]} rotation={[0, 0, -Math.PI / 2]}>
              <mesh geometry={nodes.Body1681.geometry} material={materials['Polycarbonate (Smoked)']} scale={0.394} />
            </group>
            <group position={[0.065, 5.313, -2.814]} rotation={[-Math.PI, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body1679.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            </group>
            <group position={[12.7, 5.313, -2.814]} rotation={[0, 0, -Math.PI / 2]}>
              <mesh geometry={nodes.Body1680.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            </group>
          </group>
          <group position={[1, 13, 0]}>
            <group position={[-1.935, 5.313, 0.062]} rotation={[0, 0, -Math.PI / 2]}>
              <mesh geometry={nodes.Body1693.geometry} material={materials['Polycarbonate (Smoked)']} scale={0.394} />
            </group>
            <group position={[-0.935, 5.313, 0.062]} rotation={[-Math.PI, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body1691.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            </group>
            <group position={[11.7, 5.313, 0.062]} rotation={[0, 0, -Math.PI / 2]}>
              <mesh geometry={nodes.Body1692.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            </group>
          </group>
          <group position={[13.75, 15.724, 1.25]} rotation={[Math.PI / 4, 0, -Math.PI / 2]}>
            <group scale={0.394}>
              <mesh geometry={nodes.Body1225_1.geometry} material={materials['Anodized - Black']} />
              <mesh geometry={nodes.Body1225_2.geometry} material={materials['Aluminum - Polished']} />
              <mesh geometry={nodes.Body1225_3.geometry} material={materials.Red} />
              <mesh geometry={nodes.Body1225_4.geometry} material={materials.Generic} />
            </group>
          </group>
          <group position={[6.375, 12.875, 0.779]} rotation={[0, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1707.geometry} material={materials['0,0,0']} scale={0.394} />
            <mesh geometry={nodes.Body10020.geometry} material={materials['26,26,26']} scale={0.394} />
            <mesh geometry={nodes.Body11020.geometry} material={materials['198,193,188']} scale={0.394} />
            <mesh geometry={nodes.Body12020.geometry} material={materials['198,193,188']} scale={0.394} />
            <mesh geometry={nodes.Body13019.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body14019.geometry} material={materials['64,64,64']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body15005_1.geometry} material={materials['73,169,84']} />
              <mesh geometry={nodes.Body15005_2.geometry} material={materials['255,255,255']} />
            </group>
            <mesh geometry={nodes.Body16018.geometry} material={materials['198,193,188']} scale={0.394} />
            <mesh geometry={nodes.Body17016.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body18012.geometry} material={materials['26,26,26']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body19002_1.geometry} material={materials['229,229,229']} />
              <mesh geometry={nodes.Body19002_2.geometry} material={materials['127,127,127']} />
            </group>
            <mesh geometry={nodes.Body2054.geometry} material={materials['198,193,188']} scale={0.394} />
            <mesh geometry={nodes.Body21012.geometry} material={materials['177,25,25']} scale={0.394} />
            <mesh geometry={nodes.Body22012.geometry} material={materials['177,25,25']} scale={0.394} />
            <mesh geometry={nodes.Body23012.geometry} material={materials['64,64,64']} scale={0.394} />
            <mesh geometry={nodes.Body24012.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body25012.geometry} material={materials['255,255,255']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body3008_1.geometry} material={materials['255,255,255']} />
              <mesh geometry={nodes.Body3008_2.geometry} material={materials['229,235,237']} />
            </group>
            <group scale={0.394}>
              <mesh geometry={nodes.Body4008_1.geometry} material={materials['177,25,25']} />
              <mesh geometry={nodes.Body4008_2.geometry} material={materials['76,76,76']} />
            </group>
            <mesh geometry={nodes.Body5024.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body6024.geometry} material={materials['166,158,150']} scale={0.394} />
            <mesh geometry={nodes.Body7024.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body8024.geometry} material={materials['0,0,0']} scale={0.394} />
            <mesh geometry={nodes.Body9020.geometry} material={materials['255,255,255']} scale={0.394} />
          </group>
          <group position={[14, 9.25, 1.437]} rotation={[Math.PI, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1704.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-0.875, 10.063, -3.25]} rotation={[Math.PI, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1622.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[13.625, 10.063, -3.25]} rotation={[Math.PI, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1623.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-0.875, 10.063, 0.25]} rotation={[0, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1624.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[13.625, 10.063, 0.25]} rotation={[Math.PI, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1625.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[13.625, 14.688, 0.25]} rotation={[-Math.PI / 2, -Math.PI / 2, 0]}>
            <mesh geometry={nodes.Body1635.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-0.875, 14.688, 0.25]} rotation={[-Math.PI / 2, -Math.PI / 2, 0]}>
            <mesh geometry={nodes.Body1634.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[16.875, 10.563, 0.188]}>
            <mesh geometry={nodes.Body1628.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
          </group>
          <group position={[-4.125, 20.426, 0.313]} rotation={[-2.967, 0, Math.PI]}>
            <mesh geometry={nodes.Body1626.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
          </group>
          <group position={[-4.125, 10.313, -2.814]} rotation={[-Math.PI, 0, -Math.PI]}>
            <mesh geometry={nodes.Body1627.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
          </group>
          <group position={[-4.125, 20.426, -3.063]} rotation={[2.967, 0, -Math.PI]}>
            <mesh geometry={nodes.Body1629.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
          </group>
          <group position={[-4.125, 14.938, -2.813]} rotation={[-Math.PI, 0, -Math.PI]}>
            <mesh geometry={nodes.Body1630.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
          </group>
          <group position={[14.125, 8.25, 1.938]} rotation={[-Math.PI, 0, -Math.PI]}>
            <mesh geometry={nodes.Body1640.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[14.125, 10.25, 1.937]} rotation={[-Math.PI, 0, -Math.PI]}>
            <mesh geometry={nodes.Body1639.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[14.125, 10.25, 0.937]} rotation={[-Math.PI, 0, -Math.PI]}>
            <mesh geometry={nodes.Body1641.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[14.125, 8.25, 0.937]} rotation={[-Math.PI, 0, -Math.PI]}>
            <mesh geometry={nodes.Body1636.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[14.125, 9.938, 2.625]} rotation={[-Math.PI, 0, -Math.PI]}>
            <mesh geometry={nodes.Body1637.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[14.125, 8.563, 0.25]} rotation={[-Math.PI, 0, -Math.PI]}>
            <mesh geometry={nodes.Body1638.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[15.188, 16.26, -1.045]} rotation={[-Math.PI, 0, -Math.PI]}>
            <mesh geometry={nodes.Body1647.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[15.188, 15.017, 1.957]} rotation={[-Math.PI, 0, -Math.PI]}>
            <mesh geometry={nodes.Body1649.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[15.188, 20.423, 0.313]} rotation={[-Math.PI, 0, -Math.PI]}>
            <mesh geometry={nodes.Body1648.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[15.188, 20.426, -3.063]} rotation={[-Math.PI, 0, -Math.PI]}>
            <mesh geometry={nodes.Body1650.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-0.5, 2.688, -2.5]} rotation={[-Math.PI, 0, 0]}>
            <mesh geometry={nodes.Body1656.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[13.25, 2.688, -2.5]} rotation={[-Math.PI, 0, 0]}>
            <mesh geometry={nodes.Body1655.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-1.125, 19.003, -2.495]} rotation={[-0.175, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1632.geometry} material={materials['Polycarbonate (Smoked)']} scale={0.394} />
          </group>
          <group position={[-1.125, 18.982, -0.133]} rotation={[0.175, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Body1633.geometry} material={materials['Polycarbonate (Smoked)']} scale={0.394} />
          </group>
          <group position={[13.875, 10.063, -2.626]} rotation={[Math.PI, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1631.geometry} material={materials['Polycarbonate (Smoked)']} scale={0.394} />
          </group>
          <group position={[15.188, 0, 0]}>
            <mesh geometry={nodes.Body85002.geometry} material={materials['Polycarbonate (Smoked)']} scale={0.394} />
          </group>
          <group position={[15.575, 16.621, -1.397]} rotation={[-Math.PI, 1.571, 0]}>
            <mesh geometry={nodes.Body1646.geometry} material={materials['Plastic - Glossy (Black)']} scale={0.394} />
          </group>
          <group position={[13.75, 15.723, 1.249]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1642.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-1.25, 20.423, 0.312]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1644.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[14.623, 9.25, 1.437]} rotation={[Math.PI, 1.571, 0]}>
            <mesh geometry={nodes.Body1645.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-1.875, 9.25, 1.437]} rotation={[Math.PI, -1.571, 0]}>
            <mesh geometry={nodes.Body1643.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[0, 0.5, 0]}>
            <mesh geometry={nodes.Body1652.geometry} material={materials['Aluminum - Satin']} scale={0.394} />
          </group>
          <group position={[12.75, 0.5, -0.125]} rotation={[-Math.PI, 0, -Math.PI]}>
            <mesh geometry={nodes.Body1651.geometry} material={materials['Aluminum - Satin']} scale={0.394} />
          </group>
          <group position={[0, 0.5, -0.125]}>
            <mesh geometry={nodes.Body1621.geometry} material={materials['Aluminum - Satin']} scale={0.394} />
          </group>
          <group position={[13.625, 9.813, -3]} rotation={[-Math.PI, 0, -Math.PI]}>
            <mesh geometry={nodes.Body1654.geometry} material={materials['Polycarbonate (Smoked)']} scale={0.394} />
          </group>
          <group position={[-0.875, 9.813, -3]} rotation={[-Math.PI, 0, -Math.PI]}>
            <mesh geometry={nodes.Body1653.geometry} material={materials['Polycarbonate (Smoked)']} scale={0.394} />
          </group>
          <group position={[-1.25, 10.063, 0.5]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body3024.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[13.875, 10.063, 0.5]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh geometry={nodes.Body14018.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[0, 0.5, -2.5]}>
            <mesh geometry={nodes.Body2045.geometry} material={materials['Polycarbonate (Smoked)']} scale={0.394} />
          </group>
        </group>
        <group position={[28.123, 12.976, 0.975]} rotation={[Math.PI / 2, 0, -Math.PI]}>
          <mesh geometry={nodes.Body1620.geometry} material={materials['Steel - Satin']} scale={0.394} />
        </group>
        <group position={[28.623, 9.186, -19.025]} rotation={[0, 1.571, 0]}>
          <mesh geometry={nodes.Body1573.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
        </group>
        <group position={[27.623, 9.186, -19.025]} rotation={[0, 1.571, 0]}>
          <mesh geometry={nodes.Body1574.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
        </group>
        <group position={[40.623, 25.186, 16.225]} rotation={[0, -1.571, 0]}>
          <mesh geometry={nodes.Body1578.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
        </group>
        <group position={[15.623, 25.186, 16.225]} rotation={[0, -1.571, 0]}>
          <mesh geometry={nodes.Body1579.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
        </group>
        <group position={[29.623, 9.186, 16.225]} rotation={[0, -1.571, 0]}>
          <mesh geometry={nodes.Body1576.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
        </group>
        <group position={[26.623, 9.186, 16.225]} rotation={[0, -1.571, 0]}>
          <mesh geometry={nodes.Body1577.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
        </group>
        <group position={[33.123, 14.686, -1.025]} rotation={[0, 0, Math.PI]}>
          <mesh geometry={nodes.Body1570.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[27.623, 9.186, -1.525]}>
          <mesh geometry={nodes.Body1575.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[22.123, 8.686, -1.15]} rotation={[-Math.PI, 0, 0]}>
          <mesh geometry={nodes.Body1571.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[34.123, 8.686, -1.025]} rotation={[0, 0, -Math.PI]}>
          <mesh geometry={nodes.Body1572.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[22.123, 34.686, -1.025]}>
          <mesh geometry={nodes.Body1569.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[34.123, 34.686, -1.15]} rotation={[-Math.PI, 0, -Math.PI]}>
          <mesh geometry={nodes.Body1568.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[15.623, 11.686, -1.463]} rotation={[-Math.PI / 2, -Math.PI / 2, 0]}>
          <mesh geometry={nodes.Body1563.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[40.623, 31.686, -1.463]} rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
          <mesh geometry={nodes.Body1566.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[15.623, 31.686, -1.463]} rotation={[Math.PI / 2, -Math.PI / 2, 0]}>
          <mesh geometry={nodes.Body1562.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[40.623, 11.686, -1.463]} rotation={[Math.PI / 2, Math.PI / 2, 0]}>
          <mesh geometry={nodes.Body1567.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[15.373, 28.686, -1.213]} rotation={[0, 0, Math.PI / 2]}>
          <mesh geometry={nodes.Body1565.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[40.873, 14.686, -1.213]} rotation={[0, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.Body1561.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[41.123, 28.686, -1.213]} rotation={[0, 0, Math.PI / 2]}>
          <mesh geometry={nodes.Body1564.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[15.123, 14.686, -1.213]} rotation={[0, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.Body1560.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group rotation={[Math.PI, -0.087, Math.PI]}>
          <group position={[-15.291, 14.686, 1.26]} rotation={[0, -0.087, 0]}>
            <mesh geometry={nodes.Body1533.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
          </group>
          <group position={[-15.291, 10.936, 1.26]} rotation={[0, -0.087, 0]}>
            <mesh geometry={nodes.Body1534.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
          </group>
          <group position={[-28.241, 13.436, 0.127]} rotation={[0, 1.484, 0]}>
            <mesh geometry={nodes.Body1537.geometry} material={materials['Opaque(76,76,76).001']} scale={0.394} />
          </group>
          <group position={[-28.241, 14.186, 0.127]} rotation={[0, 1.484, 0]}>
            <mesh geometry={nodes.Body1538.geometry} material={materials['Opaque(76,76,76).001']} scale={0.394} />
          </group>
          <group position={[-24.182, 12.686, 0.484]} rotation={[-Math.PI, 0.087, -Math.PI / 2]}>
            <mesh geometry={nodes.Body1535.geometry} material={materials['Opaque(177,24,24)']} scale={0.394} />
            <mesh geometry={nodes.Body10008.geometry} material={materials['Opaque(170,178,196)']} scale={0.394} />
            <mesh geometry={nodes.Body11008.geometry} material={materials['Opaque(170,178,196)']} scale={0.394} />
            <mesh geometry={nodes.Body12008.geometry} material={materials['Opaque(170,178,196)']} scale={0.394} />
            <mesh geometry={nodes.Body13008.geometry} material={materials['Opaque(170,178,196)']} scale={0.394} />
            <mesh geometry={nodes.Body14007.geometry} material={materials['Opaque(207,188,0)']} scale={0.394} />
            <mesh geometry={nodes.Body15007.geometry} material={materials['Steel - Satin']} scale={0.394} />
            <mesh geometry={nodes.Body16007.geometry} material={materials['Steel - Satin']} scale={0.394} />
            <mesh geometry={nodes.Body2033.geometry} material={materials['Opaque(255,221,135)']} scale={0.394} />
            <mesh geometry={nodes.Body3012.geometry} material={materials['Opaque(255,221,135)']} scale={0.394} />
            <mesh geometry={nodes.Body4012.geometry} material={materials['Opaque(255,221,135)']} scale={0.394} />
            <mesh geometry={nodes.Body5012.geometry} material={materials['Opaque(255,221,135)']} scale={0.394} />
            <mesh geometry={nodes.Body6012.geometry} material={materials['Opaque(255,242,232)']} scale={0.394} />
            <mesh geometry={nodes.Body7012.geometry} material={materials['Steel - Satin']} scale={0.394} />
            <mesh geometry={nodes.Body8012.geometry} material={materials['Opaque(255,242,232)']} scale={0.394} />
            <mesh geometry={nodes.Body9008.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[-28.359, 14.113, 1.468]} rotation={[-Math.PI / 2, 0, 3.054]}>
            <mesh geometry={nodes.Body1536.geometry} material={materials['Powder Coat (Red)']} scale={0.394} />
          </group>
          <group position={[-33.042, 15.686, -2.364]} rotation={[-Math.PI, 0.087, -Math.PI]}>
            <mesh geometry={nodes.Body1530.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-22.956, 15.686, -1.481]} rotation={[-Math.PI, 0.087, -Math.PI]}>
            <mesh geometry={nodes.Body1531.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-23.108, 15.686, 0.262]} rotation={[-Math.PI, 0.087, -Math.PI]}>
            <mesh geometry={nodes.Body1532.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-25.204, 14.936, -0.172]} rotation={[-Math.PI, 0.087, -Math.PI]}>
            <mesh geometry={nodes.Body1539.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
        </group>
        <group rotation={[0, 0, -0.698]}>
          <group position={[2.1, 41.248, -1.15]} rotation={[-Math.PI / 2, 0.873, 0]}>
            <mesh geometry={nodes.Body1521.geometry} material={materials['Opaque(202,209,238)']} scale={0.394} />
          </group>
          <group position={[2.1, 41.248, -1.455]} rotation={[-Math.PI / 2, 0.873, Math.PI]}>
            <group position={[-0.002, 0, -0.811]} rotation={[Math.PI, 0, 0]}>
              <mesh geometry={nodes.Body1524.geometry} material={materials['Opaque(210,210,255)']} scale={0.394} />
              <mesh geometry={nodes.Body10005.geometry} material={materials['Opaque(70,60,69)']} scale={0.394} />
              <mesh geometry={nodes.Body11005.geometry} material={materials['Opaque(210,210,255)']} scale={0.394} />
              <mesh geometry={nodes.Body12005.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
              <mesh geometry={nodes.Body13005.geometry} material={materials['Opaque(210,210,255)']} scale={0.394} />
              <mesh geometry={nodes.Body2030.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
              <mesh geometry={nodes.Body3009.geometry} material={materials['Opaque(210,210,255)']} scale={0.394} />
              <mesh geometry={nodes.Body4009.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
              <mesh geometry={nodes.Body5009.geometry} material={materials['Opaque(210,210,255)']} scale={0.394} />
              <mesh geometry={nodes.Body6009.geometry} material={materials['Opaque(210,210,255)']} scale={0.394} />
              <mesh geometry={nodes.Body7009.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
              <mesh geometry={nodes.Body8009.geometry} material={materials['Opaque(210,210,255)']} scale={0.394} />
              <mesh geometry={nodes.Body9005.geometry} material={materials['Opaque(210,210,255)']} scale={0.394} />
            </group>
            <group position={[0, 0, 0.915]} rotation={[0, 0, -Math.PI]}>
              <mesh geometry={nodes.Body1525.geometry} material={materials['Opaque(202,209,238)']} scale={0.394} />
            </group>
            <mesh geometry={nodes.Body1523.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
          </group>
          <group position={[2.1, 41.248, -1.155]} rotation={[0, 0, 2.269]}>
            <mesh geometry={nodes.Body1526.geometry} material={materials['Opaque(63,63,63)']} scale={0.394} />
          </group>
          <group position={[2.1, 41.248, -1.87]} rotation={[0, 0, 2.269]}>
            <mesh geometry={nodes.Body1527.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          </group>
          <group position={[2.1, 41.248, -1.91]} rotation={[-Math.PI / 2, 0.873, Math.PI]}>
            <mesh geometry={nodes.Body1522.geometry} material={materials['Opaque(202,209,238)']} scale={0.394} />
          </group>
        </group>
        <group position={[28.123, 26.092, -1.63]} rotation={[-Math.PI / 2, 0, 0]}>
          <mesh geometry={nodes.Body1542.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[28.123, 26.092, -1.63]} rotation={[-Math.PI / 2, 0, 0]}>
          <mesh geometry={nodes.Body1544.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[18.273, 13.855, -5.952]} rotation={[-1.222, 0, Math.PI]}>
          <group scale={0.394}>
            <mesh geometry={nodes.Body1185_1.geometry} material={materials['Opaque(33,138,33)']} />
            <mesh geometry={nodes.Body1185_2.geometry} material={materials['Opaque(221,221,13)']} />
            <mesh geometry={nodes.Body1185_3.geometry} material={materials['Opaque(5,5,5)']} />
            <mesh geometry={nodes.Body1185_4.geometry} material={materials['Opaque(190,188,186)']} />
            <mesh geometry={nodes.Body1185_5.geometry} material={materials['Opaque(63,63,63)']} />
          </group>
          <mesh geometry={nodes.Body10009.geometry} material={materials['Opaque(33,138,33)']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body11006_2.geometry} material={materials['Opaque(5,5,5)']} />
            <mesh geometry={nodes.Body11006_3.geometry} material={materials['Opaque(178,0,0)']} />
          </group>
          <group scale={0.394}>
            <mesh geometry={nodes.Body12006_1.geometry} material={materials['Opaque(5,5,5)']} />
            <mesh geometry={nodes.Body12006_2.geometry} material={materials['Opaque(63,63,63)']} />
          </group>
          <group scale={0.394}>
            <mesh geometry={nodes.Body2012_1.geometry} material={materials['Opaque(190,188,186)']} />
            <mesh geometry={nodes.Body2012_2.geometry} material={materials['Opaque(245,245,245)']} />
            <mesh geometry={nodes.Body2012_3.geometry} material={materials['Opaque(254,213,0)']} />
          </group>
          <group scale={0.394}>
            <mesh geometry={nodes.Body3007_1.geometry} material={materials['Opaque(244,244,244)']} />
            <mesh geometry={nodes.Body3007_2.geometry} material={materials['Opaque(177,177,177)']} />
          </group>
          <group scale={0.394}>
            <mesh geometry={nodes.Body4007_1.geometry} material={materials['Opaque(245,245,245)']} />
            <mesh geometry={nodes.Body4007_2.geometry} material={materials['Opaque(63,63,63)']} />
            <mesh geometry={nodes.Body4007_3.geometry} material={materials['Opaque(244,244,244)']} />
          </group>
          <group scale={0.394}>
            <mesh geometry={nodes.Body5007_1.geometry} material={materials['Opaque(63,63,63)']} />
            <mesh geometry={nodes.Body5007_2.geometry} material={materials['Opaque(10,10,10)']} />
            <mesh geometry={nodes.Body5007_3.geometry} material={materials['Opaque(72,71,61)']} />
          </group>
          <mesh geometry={nodes.Body6013.geometry} material={materials['Opaque(63,63,63)']} scale={0.394} />
          <mesh geometry={nodes.Body7013.geometry} material={materials['Opaque(63,63,63)']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body8007_1.geometry} material={materials['Opaque(0,100,0)']} />
            <mesh geometry={nodes.Body8007_2.geometry} material={materials['Opaque(231,214,172)']} />
            <mesh geometry={nodes.Body8007_3.geometry} material={materials['Opaque(63,63,63)']} />
          </group>
          <mesh geometry={nodes.Body9009.geometry} material={materials['Opaque(221,221,13)']} scale={0.394} />
        </group>
        <group position={[25.123, 21.936, 0.975]} rotation={[Math.PI / 2, 0, -Math.PI]}>
          <mesh geometry={nodes.Body1422.geometry} material={materials['Opaque(63,63,63)']} scale={0.394} />
          <mesh geometry={nodes.Body1433.geometry} material={materials['Opaque(63,63,63)']} scale={0.394} />
          <mesh geometry={nodes.Body1434.geometry} material={materials['Opaque(255,0,0)']} scale={0.394} />
          <mesh geometry={nodes.Body1437.geometry} material={materials['Opaque(204,204,204)']} scale={0.394} />
          <mesh geometry={nodes.Body1439.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1440.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1428.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1443.geometry} material={materials['Opaque(63,63,63)']} scale={0.394} />
          <mesh geometry={nodes.Body1431.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1442.geometry} material={materials['Opaque(255,0,0)']} scale={0.394} />
          <mesh geometry={nodes.Body1441.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1423.geometry} material={materials['Opaque(32,32,32)']} scale={0.394} />
          <mesh geometry={nodes.Body1444.geometry} material={materials['Opaque(63,63,63)']} scale={0.394} />
          <mesh geometry={nodes.Body1446.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1447.geometry} material={materials['Opaque(63,63,63)']} scale={0.394} />
          <mesh geometry={nodes.Body1438.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1445.geometry} material={materials['Opaque(204,204,204)']} scale={0.394} />
          <mesh geometry={nodes.Body1452.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1453.geometry} material={materials['Opaque(63,63,63)']} scale={0.394} />
          <mesh geometry={nodes.Body1448.geometry} material={materials['Opaque(204,204,204)']} scale={0.394} />
          <mesh geometry={nodes.Body1454.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1455.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1424.geometry} material={materials['Opaque(63,63,63)']} scale={0.394} />
          <mesh geometry={nodes.Body1449.geometry} material={materials['Opaque(255,0,0)']} scale={0.394} />
          <mesh geometry={nodes.Body1450.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1458.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1451.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1456.geometry} material={materials['Opaque(204,204,204)']} scale={0.394} />
          <mesh geometry={nodes.Body1459.geometry} material={materials['Opaque(204,204,204)']} scale={0.394} />
          <mesh geometry={nodes.Body1462.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1460.geometry} material={materials['Opaque(204,204,204)']} scale={0.394} />
          <mesh geometry={nodes.Body1457.geometry} material={materials['Opaque(204,204,204)']} scale={0.394} />
          <mesh geometry={nodes.Body1464.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1425.geometry} material={materials['Opaque(204,204,204)']} scale={0.394} />
          <mesh geometry={nodes.Body1467.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1461.geometry} material={materials['Opaque(63,63,63)']} scale={0.394} />
          <mesh geometry={nodes.Body1465.geometry} material={materials['Opaque(63,63,63)']} scale={0.394} />
          <mesh geometry={nodes.Body1466.geometry} material={materials['Opaque(63,63,63)']} scale={0.394} />
          <mesh geometry={nodes.Body1470.geometry} material={materials['Opaque(63,63,63)']} scale={0.394} />
          <mesh geometry={nodes.Body1471.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1469.geometry} material={materials['Opaque(204,204,204)']} scale={0.394} />
          <mesh geometry={nodes.Body1473.geometry} material={materials['Opaque(50,112,20)']} scale={0.394} />
          <mesh geometry={nodes.Body1468.geometry} material={materials['Opaque(204,204,204)']} scale={0.394} />
          <mesh geometry={nodes.Body1474.geometry} material={materials['Opaque(63,63,63)']} scale={0.394} />
          <mesh geometry={nodes.Body1426.geometry} material={materials['Opaque(50,112,20)']} scale={0.394} />
          <mesh geometry={nodes.Body1476.geometry} material={materials['Opaque(63,63,63)']} scale={0.394} />
          <mesh geometry={nodes.Body1477.geometry} material={materials['Opaque(63,63,63)']} scale={0.394} />
          <mesh geometry={nodes.Body1435.geometry} material={materials['Opaque(32,32,32)']} scale={0.394} />
          <mesh geometry={nodes.Body1480.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1481.geometry} material={materials['Opaque(204,204,204)']} scale={0.394} />
          <mesh geometry={nodes.Body1482.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1479.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1483.geometry} material={materials['Opaque(255,0,0)']} scale={0.394} />
          <mesh geometry={nodes.Body1484.geometry} material={materials['Opaque(50,112,20)']} scale={0.394} />
          <mesh geometry={nodes.Body1486.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1427.geometry} material={materials['Opaque(63,63,63)']} scale={0.394} />
          <mesh geometry={nodes.Body1487.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1488.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1485.geometry} material={materials['Opaque(248,135,1)']} scale={0.394} />
          <mesh geometry={nodes.Body1489.geometry} material={materials['Opaque(204,204,204)']} scale={0.394} />
          <mesh geometry={nodes.Body1490.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1491.geometry} material={materials['Opaque(255,0,0)']} scale={0.394} />
          <mesh geometry={nodes.Body1492.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1494.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1495.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1493.geometry} material={materials['Opaque(204,204,204)']} scale={0.394} />
          <mesh geometry={nodes.Body1429.geometry} material={materials['Opaque(204,204,204)']} scale={0.394} />
          <mesh geometry={nodes.Body1496.geometry} material={materials['Opaque(50,112,20)']} scale={0.394} />
          <mesh geometry={nodes.Body1498.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1499.geometry} material={materials['Opaque(63,63,63)']} scale={0.394} />
          <mesh geometry={nodes.Body1500.geometry} material={materials['Opaque(50,112,20)']} scale={0.394} />
          <mesh geometry={nodes.Body1497.geometry} material={materials['Opaque(204,204,204)']} scale={0.394} />
          <mesh geometry={nodes.Body1501.geometry} material={materials['Opaque(63,63,63)']} scale={0.394} />
          <mesh geometry={nodes.Body1503.geometry} material={materials['Opaque(50,112,20)']} scale={0.394} />
          <mesh geometry={nodes.Body1504.geometry} material={materials['Opaque(255,0,0)']} scale={0.394} />
          <mesh geometry={nodes.Body1505.geometry} material={materials['Opaque(63,63,63)']} scale={0.394} />
          <mesh geometry={nodes.Body1472.geometry} material={materials['Opaque(204,204,204)']} scale={0.394} />
          <mesh geometry={nodes.Body1430.geometry} material={materials['Opaque(32,32,32)']} scale={0.394} />
          <mesh geometry={nodes.Body1506.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1508.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1509.geometry} material={materials['Opaque(63,63,63)']} scale={0.394} />
          <mesh geometry={nodes.Body1475.geometry} material={materials['Opaque(204,204,204)']} scale={0.394} />
          <mesh geometry={nodes.Body1507.geometry} material={materials['Opaque(204,204,204)']} scale={0.394} />
          <mesh geometry={nodes.Body1502.geometry} material={materials['Opaque(248,135,1)']} scale={0.394} />
          <mesh geometry={nodes.Body1513.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1514.geometry} material={materials['Opaque(50,112,20)']} scale={0.394} />
          <mesh geometry={nodes.Body1512.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1510.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1432.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1516.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1515.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1519.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1478.geometry} material={materials['Opaque(204,204,204)']} scale={0.394} />
          <mesh geometry={nodes.Body1463.geometry} material={materials['Opaque(204,204,204)']} scale={0.394} />
          <mesh geometry={nodes.Body1511.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1520.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1518.geometry} material={materials['Opaque(63,63,63)']} scale={0.394} />
          <mesh geometry={nodes.Body1517.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
          <mesh geometry={nodes.Body1436.geometry} material={materials['Opaque(32,32,32)']} scale={0.394} />
          <mesh geometry={nodes.Body1421.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
        </group>
        <group position={[28.123, 26.092, -1.62]} rotation={[-Math.PI / 2, 0, 0]}>
          <mesh geometry={nodes.Body1529.geometry} material={materials.Default} scale={0.394} />
          <mesh geometry={nodes.Body10007.geometry} material={materials.Default} scale={0.394} />
          <mesh geometry={nodes.Body11007.geometry} material={materials.Default} scale={0.394} />
          <mesh geometry={nodes.Body12007.geometry} material={materials.Default} scale={0.394} />
          <mesh geometry={nodes.Body13007.geometry} material={materials.Default} scale={0.394} />
          <mesh geometry={nodes.Body14006.geometry} material={materials.Default} scale={0.394} />
          <mesh geometry={nodes.Body15006.geometry} material={materials.Default} scale={0.394} />
          <mesh geometry={nodes.Body16006.geometry} material={materials.Default} scale={0.394} />
          <mesh geometry={nodes.Body2032.geometry} material={materials.Default} scale={0.394} />
          <mesh geometry={nodes.Body3011.geometry} material={materials.Default} scale={0.394} />
          <mesh geometry={nodes.Body4011.geometry} material={materials.Default} scale={0.394} />
          <mesh geometry={nodes.Body5011.geometry} material={materials.Default} scale={0.394} />
          <mesh geometry={nodes.Body6011.geometry} material={materials['Dark Gray']} scale={0.394} />
          <mesh geometry={nodes.Body7011.geometry} material={materials.Default} scale={0.394} />
          <mesh geometry={nodes.Body8011.geometry} material={materials.Default} scale={0.394} />
          <mesh geometry={nodes.Body9007.geometry} material={materials.Default} scale={0.394} />
        </group>
        <group position={[19.61, 19.302, 0.975]} rotation={[0, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.Body1553.geometry} material={materials['Steel - Satin']} scale={0.394} />
        </group>
        <group rotation={[0, 0, 1.745]}>
          <group position={[16.473, -31.461, 0.975]} rotation={[-Math.PI / 2, 0.175, 0]}>
            <mesh geometry={nodes.Body1417.geometry} material={materials['Opaque(202,209,238)']} scale={0.394} />
          </group>
          <group position={[16.473, -31.461, 0.505]} rotation={[0, 0, 2.967]}>
            <mesh geometry={nodes.Body1418.geometry} material={materials['Opaque(63,63,63)']} scale={0.394} />
          </group>
          <group position={[16.473, -31.461, 0.935]} rotation={[Math.PI, 0, 1.745]}>
            <mesh geometry={nodes.Body1419.geometry} material={materials['Opaque(63,63,63)']} scale={0.394} />
          </group>
          <group position={[16.473, -31.461, 0.739]} rotation={[1.571, 1.396, -Math.PI]}>
            <group position={[0.67, 0.063, 0]} rotation={[Math.PI, 0, Math.PI]}>
              <mesh geometry={nodes.Body1414.geometry} material={materials['Opaque(61,158,110)']} scale={0.394} />
            </group>
            <group position={[-0.67, 0.063, 0]} rotation={[Math.PI, 0, Math.PI]}>
              <mesh geometry={nodes.Body1415.geometry} material={materials['Opaque(61,158,110)']} scale={0.394} />
            </group>
            <mesh geometry={nodes.Body1416.geometry} material={materials['Opaque(202,209,238)']} scale={0.394} />
          </group>
          <group position={[16.473, -31.461, 0.465]} rotation={[Math.PI / 2, -0.175, 0]}>
            <mesh geometry={nodes.Body1420.geometry} material={materials['Opaque(202,209,238)']} scale={0.394} />
          </group>
        </group>
        <group rotation={[0, 1.484, 0]}>
          <group position={[1.48, 29.186, 28.101]} rotation={[Math.PI / 2, 0, -1.658]}>
            <mesh geometry={nodes.Body1528.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body10006.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body100001.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body101001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body102001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body103001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body104001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body105001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body106001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body107001.geometry} material={materials['177,25,25']} scale={0.394} />
            <mesh geometry={nodes.Body108001.geometry} material={materials['229,45,45']} scale={0.394} />
            <mesh geometry={nodes.Body109001.geometry} material={materials['255,255,255']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body11003_2.geometry} material={materials['40,40,40']} />
              <mesh geometry={nodes.Body11003_3.geometry} material={materials['232,156,1']} />
            </group>
            <group scale={0.394}>
              <mesh geometry={nodes.Body110_1.geometry} material={materials['177,25,25']} />
              <mesh geometry={nodes.Body110_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body111.geometry} material={materials['76,76,76']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body112_1.geometry} material={materials['76,76,76']} />
              <mesh geometry={nodes.Body112_2.geometry} material={materials['2,61,210']} />
              <mesh geometry={nodes.Body112_3.geometry} material={materials['127,127,127']} />
            </group>
            <mesh geometry={nodes.Body113.geometry} material={materials['255,255,255']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body114_1.geometry} material={materials['177,25,25']} />
              <mesh geometry={nodes.Body114_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body115.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body116.geometry} material={materials['229,235,237']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body117_1.geometry} material={materials['177,25,25']} />
              <mesh geometry={nodes.Body117_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body118.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body119.geometry} material={materials['255,255,255']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body12003_1.geometry} material={materials['177,25,25']} />
              <mesh geometry={nodes.Body12003_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body120.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body121.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body122.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body123.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body124.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body125.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body126.geometry} material={materials['113,185,237']} scale={0.394} />
            <mesh geometry={nodes.Body127.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body128.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body129.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body13006.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body130.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body131.geometry} material={materials['113,185,237']} scale={0.394} />
            <mesh geometry={nodes.Body132.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body133.geometry} material={materials['76,76,76']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body134_1.geometry} material={materials['232,156,1']} />
              <mesh geometry={nodes.Body134_2.geometry} material={materials['177,25,25']} />
            </group>
            <mesh geometry={nodes.Body135.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body136.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body137.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body138.geometry} material={materials['255,255,255']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body139_1.geometry} material={materials['40,40,40']} />
              <mesh geometry={nodes.Body139_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body14005.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body140.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body141.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body142.geometry} material={materials['255,255,255']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body143_1.geometry} material={materials['40,40,40']} />
              <mesh geometry={nodes.Body143_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body144.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body145.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body146.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body147.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body148.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body149.geometry} material={materials['255,255,255']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body15002_1.geometry} material={materials['40,40,40']} />
              <mesh geometry={nodes.Body15002_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body150.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body151.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body152.geometry} material={materials['255,255,255']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body153_1.geometry} material={materials['40,40,40']} />
              <mesh geometry={nodes.Body153_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body154.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body155.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body156.geometry} material={materials['229,45,45']} scale={0.394} />
            <mesh geometry={nodes.Body157.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body158.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body159.geometry} material={materials['255,239,35']} scale={0.394} />
            <mesh geometry={nodes.Body16005.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body160.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body161.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body162.geometry} material={materials['177,25,25']} scale={0.394} />
            <mesh geometry={nodes.Body163.geometry} material={materials['229,45,45']} scale={0.394} />
            <mesh geometry={nodes.Body164.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body165.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body166.geometry} material={materials['229,235,237']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body167_1.geometry} material={materials['40,40,40']} />
              <mesh geometry={nodes.Body167_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body168.geometry} material={materials['229,235,237']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body169_1.geometry} material={materials['177,25,25']} />
              <mesh geometry={nodes.Body169_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body17005.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body170.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body171.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body172.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body173.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body174.geometry} material={materials['229,45,45']} scale={0.394} />
            <mesh geometry={nodes.Body175.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body176.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body177.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body178.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body179.geometry} material={materials['229,45,45']} scale={0.394} />
            <mesh geometry={nodes.Body18001.geometry} material={materials['255,255,255']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body180_1.geometry} material={materials['40,40,40']} />
              <mesh geometry={nodes.Body180_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body181.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body182.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body183.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body184.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body185.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body186.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body187.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body188.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body189.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body19001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body190.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body191.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body192.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body193.geometry} material={materials['229,45,45']} scale={0.394} />
            <mesh geometry={nodes.Body194.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body195.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body196.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body197.geometry} material={materials['73,169,84']} scale={0.394} />
            <mesh geometry={nodes.Body198.geometry} material={materials['229,45,45']} scale={0.394} />
            <mesh geometry={nodes.Body199.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body2031.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body20001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body200.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body201.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body202.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body203.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body204.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body205.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body206.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body207.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body208.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body209.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body21001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body210.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body211.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body212.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body213.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body214.geometry} material={materials['40,40,40']} scale={0.394} />
            <mesh geometry={nodes.Body215.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body216.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body217.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body218.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body219.geometry} material={materials['76,76,76']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body22001_1.geometry} material={materials['177,25,25']} />
              <mesh geometry={nodes.Body22001_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body220.geometry} material={materials['177,25,25']} scale={0.394} />
            <mesh geometry={nodes.Body221.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body222.geometry} material={materials['255,255,255']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body223_1.geometry} material={materials['40,40,40']} />
              <mesh geometry={nodes.Body223_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body224.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body225.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body226.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body227.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body228.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body229.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body23001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body230.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body231.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body232.geometry} material={materials['229,45,45']} scale={0.394} />
            <mesh geometry={nodes.Body233.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body234.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body235.geometry} material={materials['255,255,255']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body236_1.geometry} material={materials['177,25,25']} />
              <mesh geometry={nodes.Body236_2.geometry} material={materials['232,156,1']} />
            </group>
            <group scale={0.394}>
              <mesh geometry={nodes.Body237_1.geometry} material={materials['40,40,40']} />
              <mesh geometry={nodes.Body237_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body238.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body239.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body24001.geometry} material={materials['229,235,237']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body240_1.geometry} material={materials['177,25,25']} />
              <mesh geometry={nodes.Body240_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body241.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body242.geometry} material={materials['229,45,45']} scale={0.394} />
            <mesh geometry={nodes.Body243.geometry} material={materials['255,255,255']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body244_1.geometry} material={materials['40,40,40']} />
              <mesh geometry={nodes.Body244_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body245.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body246.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body247.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body248.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body249.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body25001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body250.geometry} material={materials['255,239,35']} scale={0.394} />
            <mesh geometry={nodes.Body251.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body252.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body253.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body254.geometry} material={materials['229,45,45']} scale={0.394} />
            <mesh geometry={nodes.Body255.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body256.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body257.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body258.geometry} material={materials['73,169,84']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body259_1.geometry} material={materials['40,40,40']} />
              <mesh geometry={nodes.Body259_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body26001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body260.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body261.geometry} material={materials['255,239,35']} scale={0.394} />
            <mesh geometry={nodes.Body262.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body263.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body264.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body265.geometry} material={materials['255,255,255']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body266_1.geometry} material={materials['40,40,40']} />
              <mesh geometry={nodes.Body266_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body267.geometry} material={materials['255,255,255']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body268_1.geometry} material={materials['177,25,25']} />
              <mesh geometry={nodes.Body268_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body269.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body27001.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body270.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body271.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body272.geometry} material={materials['255,255,255']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body273_1.geometry} material={materials['177,25,25']} />
              <mesh geometry={nodes.Body273_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body274.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body275.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body276.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body277.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body278.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body279.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body28001.geometry} material={materials['255,255,255']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body280_1.geometry} material={materials['40,40,40']} />
              <mesh geometry={nodes.Body280_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body281.geometry} material={materials['73,169,84']} scale={0.394} />
            <mesh geometry={nodes.Body282.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body283.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body284.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body285.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body286.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body287.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body288.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body289.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body29001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body290.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body291.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body292.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body293.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body294.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body295.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body296.geometry} material={materials['166,158,150']} scale={0.394} />
            <mesh geometry={nodes.Body297.geometry} material={materials['255,255,255']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body298_1.geometry} material={materials['40,40,40']} />
              <mesh geometry={nodes.Body298_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body299.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body3010.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body30001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body300.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body301.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body302.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body303.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body304.geometry} material={materials['255,255,255']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body305_1.geometry} material={materials['40,40,40']} />
              <mesh geometry={nodes.Body305_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body306.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body307.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body308.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body309.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body31001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body310.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body311.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body312.geometry} material={materials['229,45,45']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body313_1.geometry} material={materials['40,40,40']} />
              <mesh geometry={nodes.Body313_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body314.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body315.geometry} material={materials['113,185,237']} scale={0.394} />
            <mesh geometry={nodes.Body316.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body317.geometry} material={materials['229,235,237']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body318_1.geometry} material={materials['232,156,1']} />
              <mesh geometry={nodes.Body318_2.geometry} material={materials['177,25,25']} />
            </group>
            <mesh geometry={nodes.Body319.geometry} material={materials['255,255,255']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body32001_1.geometry} material={materials['232,156,1']} />
              <mesh geometry={nodes.Body32001_2.geometry} material={materials['177,25,25']} />
            </group>
            <mesh geometry={nodes.Body320.geometry} material={materials['255,255,255']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body321_1.geometry} material={materials['40,40,40']} />
              <mesh geometry={nodes.Body321_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body322.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body323.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body324.geometry} material={materials['229,45,45']} scale={0.394} />
            <mesh geometry={nodes.Body325.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body326.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body327.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body328.geometry} material={materials['229,235,237']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body329_1.geometry} material={materials['177,25,25']} />
              <mesh geometry={nodes.Body329_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body33001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body330.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body331.geometry} material={materials['229,45,45']} scale={0.394} />
            <mesh geometry={nodes.Body332.geometry} material={materials['229,45,45']} scale={0.394} />
            <mesh geometry={nodes.Body333.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body334.geometry} material={materials['177,25,25']} scale={0.394} />
            <mesh geometry={nodes.Body335.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body336.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body337.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body338.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body339.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body34001.geometry} material={materials['40,40,40']} scale={0.394} />
            <mesh geometry={nodes.Body340.geometry} material={materials['229,45,45']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body341_1.geometry} material={materials['177,25,25']} />
              <mesh geometry={nodes.Body341_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body342.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body343.geometry} material={materials['229,45,45']} scale={0.394} />
            <mesh geometry={nodes.Body344.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body345.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body346.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body347.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body348.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body349.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body35001.geometry} material={materials['229,45,45']} scale={0.394} />
            <mesh geometry={nodes.Body350.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body351.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body352.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body353.geometry} material={materials['166,158,150']} scale={0.394} />
            <mesh geometry={nodes.Body354.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body355.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body356.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body357.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body358.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body359.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body36001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body360.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body361.geometry} material={materials['229,45,45']} scale={0.394} />
            <mesh geometry={nodes.Body362.geometry} material={materials['229,45,45']} scale={0.394} />
            <mesh geometry={nodes.Body363.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body364.geometry} material={materials['166,158,150']} scale={0.394} />
            <mesh geometry={nodes.Body365.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body366.geometry} material={materials['76,76,76']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body367_1.geometry} material={materials['232,156,1']} />
              <mesh geometry={nodes.Body367_2.geometry} material={materials['40,40,40']} />
            </group>
            <mesh geometry={nodes.Body368.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body369.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body37001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body370.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body371.geometry} material={materials['113,185,237']} scale={0.394} />
            <mesh geometry={nodes.Body372.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body373.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body374.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body375.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body376.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body377.geometry} material={materials['73,169,84']} scale={0.394} />
            <mesh geometry={nodes.Body378.geometry} material={materials['166,158,150']} scale={0.394} />
            <mesh geometry={nodes.Body379.geometry} material={materials['177,25,25']} scale={0.394} />
            <mesh geometry={nodes.Body38001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body380.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body381.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body382.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body383.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body384.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body385.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body386.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body387.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body388.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body389.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body39001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body390.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body391.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body392.geometry} material={materials['229,45,45']} scale={0.394} />
            <mesh geometry={nodes.Body393.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body394.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body395.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body396.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body397.geometry} material={materials['229,45,45']} scale={0.394} />
            <mesh geometry={nodes.Body398.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body399.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body4010.geometry} material={materials['229,235,237']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body40001_1.geometry} material={materials['232,156,1']} />
              <mesh geometry={nodes.Body40001_2.geometry} material={materials['177,25,25']} />
            </group>
            <mesh geometry={nodes.Body400.geometry} material={materials['2,61,210']} scale={0.394} />
            <mesh geometry={nodes.Body401.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body402.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body403.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body41001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body42001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body43001.geometry} material={materials['40,40,40']} scale={0.394} />
            <mesh geometry={nodes.Body44001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body45001.geometry} material={materials['229,235,237']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body46001_1.geometry} material={materials['40,40,40']} />
              <mesh geometry={nodes.Body46001_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body47001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body48001.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body49001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body5010.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body50001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body51001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body52001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body53001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body54001.geometry} material={materials['255,255,255']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body55001_1.geometry} material={materials['177,25,25']} />
              <mesh geometry={nodes.Body55001_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body56001.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body57001.geometry} material={materials['255,255,255']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body58001_1.geometry} material={materials['177,25,25']} />
              <mesh geometry={nodes.Body58001_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body59001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body6010.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body60001.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body61001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body62001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body63001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body64001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body65001.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body66001.geometry} material={materials['229,45,45']} scale={0.394} />
            <mesh geometry={nodes.Body67001.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body68001.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body69001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body7010.geometry} material={materials['255,255,255']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body70001_1.geometry} material={materials['177,25,25']} />
              <mesh geometry={nodes.Body70001_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body71001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body72001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body73001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body74001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body75001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body76001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body77001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body78001.geometry} material={materials['229,45,45']} scale={0.394} />
            <mesh geometry={nodes.Body79001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body8010.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body80001.geometry} material={materials['40,40,40']} scale={0.394} />
            <mesh geometry={nodes.Body81001.geometry} material={materials['229,45,45']} scale={0.394} />
            <mesh geometry={nodes.Body82001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body83001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body84001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body85001.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body86001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body87001.geometry} material={materials['40,40,40']} scale={0.394} />
            <mesh geometry={nodes.Body88001.geometry} material={materials['229,235,237']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body89001_1.geometry} material={materials['40,40,40']} />
              <mesh geometry={nodes.Body89001_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body9006.geometry} material={materials['40,40,40']} scale={0.394} />
            <mesh geometry={nodes.Body90001.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body91001.geometry} material={materials['255,255,255']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body92001_1.geometry} material={materials['232,156,1']} />
              <mesh geometry={nodes.Body92001_2.geometry} material={materials['177,25,25']} />
            </group>
            <mesh geometry={nodes.Body93001.geometry} material={materials['255,255,255']} scale={0.394} />
            <mesh geometry={nodes.Body94001.geometry} material={materials['255,255,255']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body95001_1.geometry} material={materials['40,40,40']} />
              <mesh geometry={nodes.Body95001_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body96001.geometry} material={materials['229,235,237']} scale={0.394} />
            <mesh geometry={nodes.Body97001.geometry} material={materials['76,76,76']} scale={0.394} />
            <group scale={0.394}>
              <mesh geometry={nodes.Body98001_1.geometry} material={materials['177,25,25']} />
              <mesh geometry={nodes.Body98001_2.geometry} material={materials['232,156,1']} />
            </group>
            <mesh geometry={nodes.Body99001.geometry} material={materials['229,235,237']} scale={0.394} />
          </group>
        </group>
        <group position={[36.123, 18.873, 0.975]} rotation={[-Math.PI / 2, 0, 0]}>
          <mesh geometry={nodes.Body1413.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body10004.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body100.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body101.geometry} material={materials['2,61,210']} scale={0.394} />
          <mesh geometry={nodes.Body102.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body103.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body104.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body105.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body106.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body107.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body108.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body109.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body11004.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body12004.geometry} material={materials['76,76,76']} scale={0.394} />
          <mesh geometry={nodes.Body13004.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body14004.geometry} material={materials['73,169,84']} scale={0.394} />
          <mesh geometry={nodes.Body15004.geometry} material={materials['76,76,76']} scale={0.394} />
          <mesh geometry={nodes.Body16004.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body17004.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body18.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body19.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body2029.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body20.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body21.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body22.geometry} material={materials['73,217,84']} scale={0.394} />
          <mesh geometry={nodes.Body23.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body24.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body25.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body26.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body27.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body28.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body29.geometry} material={materials['76,76,76']} scale={0.394} />
          <mesh geometry={nodes.Body3008.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body30.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body31.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body32.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body33.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body34.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body35.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body36.geometry} material={materials['2,61,210']} scale={0.394} />
          <mesh geometry={nodes.Body37.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body38.geometry} material={materials['177,25,25']} scale={0.394} />
          <mesh geometry={nodes.Body39.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body4008.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body40.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body41.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body42.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body43.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body44.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body45.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body46.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body47.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body48.geometry} material={materials['40,40,40']} scale={0.394} />
          <mesh geometry={nodes.Body49.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body5008.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body50.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body51.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body52.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body53.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body54.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body55.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body56.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body57.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body58.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body59.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body6008.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body60.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body61.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body62.geometry} material={materials['166,158,150']} scale={0.394} />
          <mesh geometry={nodes.Body63.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body64.geometry} material={materials['76,76,76']} scale={0.394} />
          <mesh geometry={nodes.Body65.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body66.geometry} material={materials['255,239,35']} scale={0.394} />
          <mesh geometry={nodes.Body67.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body68.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body69.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body7008.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body70.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body71.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body72.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body73.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body74.geometry} material={materials['166,158,150']} scale={0.394} />
          <mesh geometry={nodes.Body75.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body76.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body77.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body78.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body79.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body8008.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body80.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body81.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body82.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body83.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body84.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body85.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body86.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body87.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body88.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body89.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body9004.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body90.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body91.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body92.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body93.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body94.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body95.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body96.geometry} material={materials['177,25,25']} scale={0.394} />
          <mesh geometry={nodes.Body97.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body98.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body99.geometry} material={materials['255,255,255']} scale={0.394} />
        </group>
        <group position={[22.31, 17.623, -0.01]} rotation={[0, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.Body1558.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[33.935, 14.605, -0.01]} rotation={[0, 0, Math.PI / 2]}>
          <mesh geometry={nodes.Body1557.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[22.31, 28.475, -0.01]} rotation={[0, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.Body1552.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[33.935, 23.998, -0.01]} rotation={[0, 0, Math.PI / 2]}>
          <mesh geometry={nodes.Body1551.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[21.311, 26.237, 0.069]} rotation={[Math.PI, 0, Math.PI]}>
          <mesh geometry={nodes.Body1545.geometry} material={materials['0,0,0']} scale={0.394} />
          <mesh geometry={nodes.Body10010.geometry} material={materials['26,26,26']} scale={0.394} />
          <mesh geometry={nodes.Body11010.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body12010.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body13009.geometry} material={materials['229,235,237']} scale={0.394} />
          <mesh geometry={nodes.Body14008.geometry} material={materials['64,64,64']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body15005_1.geometry} material={materials['73,169,84']} />
            <mesh geometry={nodes.Body15005_2.geometry} material={materials['255,255,255']} />
          </group>
          <mesh geometry={nodes.Body16008.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body17006.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body18002.geometry} material={materials['26,26,26']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body19002_1.geometry} material={materials['229,229,229']} />
            <mesh geometry={nodes.Body19002_2.geometry} material={materials['127,127,127']} />
          </group>
          <mesh geometry={nodes.Body2035.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body21002.geometry} material={materials['177,25,25']} scale={0.394} />
          <mesh geometry={nodes.Body22002.geometry} material={materials['177,25,25']} scale={0.394} />
          <mesh geometry={nodes.Body23002.geometry} material={materials['64,64,64']} scale={0.394} />
          <mesh geometry={nodes.Body24002.geometry} material={materials['76,76,76']} scale={0.394} />
          <mesh geometry={nodes.Body25002.geometry} material={materials['255,255,255']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body3008_1.geometry} material={materials['255,255,255']} />
            <mesh geometry={nodes.Body3008_2.geometry} material={materials['229,235,237']} />
          </group>
          <group scale={0.394}>
            <mesh geometry={nodes.Body4008_1.geometry} material={materials['177,25,25']} />
            <mesh geometry={nodes.Body4008_2.geometry} material={materials['76,76,76']} />
          </group>
          <mesh geometry={nodes.Body5014.geometry} material={materials['229,235,237']} scale={0.394} />
          <mesh geometry={nodes.Body6014.geometry} material={materials['166,158,150']} scale={0.394} />
          <mesh geometry={nodes.Body7014.geometry} material={materials['229,235,237']} scale={0.394} />
          <mesh geometry={nodes.Body8014.geometry} material={materials['0,0,0']} scale={0.394} />
          <mesh geometry={nodes.Body9010.geometry} material={materials['255,255,255']} scale={0.394} />
        </group>
        <group position={[34.935, 15.385, 0.069]} rotation={[-Math.PI, 0, 0]}>
          <mesh geometry={nodes.Body1556.geometry} material={materials['0,0,0']} scale={0.394} />
          <mesh geometry={nodes.Body10018.geometry} material={materials['26,26,26']} scale={0.394} />
          <mesh geometry={nodes.Body11018.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body12018.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body13017.geometry} material={materials['229,235,237']} scale={0.394} />
          <mesh geometry={nodes.Body14016.geometry} material={materials['64,64,64']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body15005_1.geometry} material={materials['73,169,84']} />
            <mesh geometry={nodes.Body15005_2.geometry} material={materials['255,255,255']} />
          </group>
          <mesh geometry={nodes.Body16016.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body17014.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body18010.geometry} material={materials['26,26,26']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body19002_1.geometry} material={materials['229,229,229']} />
            <mesh geometry={nodes.Body19002_2.geometry} material={materials['127,127,127']} />
          </group>
          <mesh geometry={nodes.Body2043.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body21010.geometry} material={materials['177,25,25']} scale={0.394} />
          <mesh geometry={nodes.Body22010.geometry} material={materials['177,25,25']} scale={0.394} />
          <mesh geometry={nodes.Body23010.geometry} material={materials['64,64,64']} scale={0.394} />
          <mesh geometry={nodes.Body24010.geometry} material={materials['76,76,76']} scale={0.394} />
          <mesh geometry={nodes.Body25010.geometry} material={materials['255,255,255']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body3008_1.geometry} material={materials['255,255,255']} />
            <mesh geometry={nodes.Body3008_2.geometry} material={materials['229,235,237']} />
          </group>
          <group scale={0.394}>
            <mesh geometry={nodes.Body4008_1.geometry} material={materials['177,25,25']} />
            <mesh geometry={nodes.Body4008_2.geometry} material={materials['76,76,76']} />
          </group>
          <mesh geometry={nodes.Body5022.geometry} material={materials['229,235,237']} scale={0.394} />
          <mesh geometry={nodes.Body6022.geometry} material={materials['166,158,150']} scale={0.394} />
          <mesh geometry={nodes.Body7022.geometry} material={materials['229,235,237']} scale={0.394} />
          <mesh geometry={nodes.Body8022.geometry} material={materials['0,0,0']} scale={0.394} />
          <mesh geometry={nodes.Body9018.geometry} material={materials['255,255,255']} scale={0.394} />
        </group>
        <group position={[21.311, 27.696, 0.069]} rotation={[Math.PI, 0, Math.PI]}>
          <mesh geometry={nodes.Body1548.geometry} material={materials['0,0,0']} scale={0.394} />
          <mesh geometry={nodes.Body10013.geometry} material={materials['26,26,26']} scale={0.394} />
          <mesh geometry={nodes.Body11013.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body12013.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body13012.geometry} material={materials['229,235,237']} scale={0.394} />
          <mesh geometry={nodes.Body14011.geometry} material={materials['64,64,64']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body15005_1.geometry} material={materials['73,169,84']} />
            <mesh geometry={nodes.Body15005_2.geometry} material={materials['255,255,255']} />
          </group>
          <mesh geometry={nodes.Body16011.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body17009.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body18005.geometry} material={materials['26,26,26']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body19002_1.geometry} material={materials['229,229,229']} />
            <mesh geometry={nodes.Body19002_2.geometry} material={materials['127,127,127']} />
          </group>
          <mesh geometry={nodes.Body2038.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body21005.geometry} material={materials['177,25,25']} scale={0.394} />
          <mesh geometry={nodes.Body22005.geometry} material={materials['177,25,25']} scale={0.394} />
          <mesh geometry={nodes.Body23005.geometry} material={materials['64,64,64']} scale={0.394} />
          <mesh geometry={nodes.Body24005.geometry} material={materials['76,76,76']} scale={0.394} />
          <mesh geometry={nodes.Body25005.geometry} material={materials['255,255,255']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body3008_1.geometry} material={materials['255,255,255']} />
            <mesh geometry={nodes.Body3008_2.geometry} material={materials['229,235,237']} />
          </group>
          <group scale={0.394}>
            <mesh geometry={nodes.Body4008_1.geometry} material={materials['177,25,25']} />
            <mesh geometry={nodes.Body4008_2.geometry} material={materials['76,76,76']} />
          </group>
          <mesh geometry={nodes.Body5017.geometry} material={materials['229,235,237']} scale={0.394} />
          <mesh geometry={nodes.Body6017.geometry} material={materials['166,158,150']} scale={0.394} />
          <mesh geometry={nodes.Body7017.geometry} material={materials['229,235,237']} scale={0.394} />
          <mesh geometry={nodes.Body8017.geometry} material={materials['0,0,0']} scale={0.394} />
          <mesh geometry={nodes.Body9013.geometry} material={materials['255,255,255']} scale={0.394} />
        </group>
        <group position={[34.935, 24.778, 0.069]} rotation={[-Math.PI, 0, 0]}>
          <mesh geometry={nodes.Body1549.geometry} material={materials['0,0,0']} scale={0.394} />
          <mesh geometry={nodes.Body10014.geometry} material={materials['26,26,26']} scale={0.394} />
          <mesh geometry={nodes.Body11014.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body12014.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body13013.geometry} material={materials['229,235,237']} scale={0.394} />
          <mesh geometry={nodes.Body14012.geometry} material={materials['64,64,64']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body15005_1.geometry} material={materials['73,169,84']} />
            <mesh geometry={nodes.Body15005_2.geometry} material={materials['255,255,255']} />
          </group>
          <mesh geometry={nodes.Body16012.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body17010.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body18006.geometry} material={materials['26,26,26']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body19002_1.geometry} material={materials['229,229,229']} />
            <mesh geometry={nodes.Body19002_2.geometry} material={materials['127,127,127']} />
          </group>
          <mesh geometry={nodes.Body2039.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body21006.geometry} material={materials['177,25,25']} scale={0.394} />
          <mesh geometry={nodes.Body22006.geometry} material={materials['177,25,25']} scale={0.394} />
          <mesh geometry={nodes.Body23006.geometry} material={materials['64,64,64']} scale={0.394} />
          <mesh geometry={nodes.Body24006.geometry} material={materials['76,76,76']} scale={0.394} />
          <mesh geometry={nodes.Body25006.geometry} material={materials['255,255,255']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body3008_1.geometry} material={materials['255,255,255']} />
            <mesh geometry={nodes.Body3008_2.geometry} material={materials['229,235,237']} />
          </group>
          <group scale={0.394}>
            <mesh geometry={nodes.Body4008_1.geometry} material={materials['177,25,25']} />
            <mesh geometry={nodes.Body4008_2.geometry} material={materials['76,76,76']} />
          </group>
          <mesh geometry={nodes.Body5018.geometry} material={materials['229,235,237']} scale={0.394} />
          <mesh geometry={nodes.Body6018.geometry} material={materials['166,158,150']} scale={0.394} />
          <mesh geometry={nodes.Body7018.geometry} material={materials['229,235,237']} scale={0.394} />
          <mesh geometry={nodes.Body8018.geometry} material={materials['0,0,0']} scale={0.394} />
          <mesh geometry={nodes.Body9014.geometry} material={materials['255,255,255']} scale={0.394} />
        </group>
        <group position={[21.311, 24.778, 0.069]} rotation={[Math.PI, 0, Math.PI]}>
          <mesh geometry={nodes.Body1550.geometry} material={materials['0,0,0']} scale={0.394} />
          <mesh geometry={nodes.Body10015.geometry} material={materials['26,26,26']} scale={0.394} />
          <mesh geometry={nodes.Body11015.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body12015.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body13014.geometry} material={materials['229,235,237']} scale={0.394} />
          <mesh geometry={nodes.Body14013.geometry} material={materials['64,64,64']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body15005_1.geometry} material={materials['73,169,84']} />
            <mesh geometry={nodes.Body15005_2.geometry} material={materials['255,255,255']} />
          </group>
          <mesh geometry={nodes.Body16013.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body17011.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body18007.geometry} material={materials['26,26,26']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body19002_1.geometry} material={materials['229,229,229']} />
            <mesh geometry={nodes.Body19002_2.geometry} material={materials['127,127,127']} />
          </group>
          <mesh geometry={nodes.Body2040.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body21007.geometry} material={materials['177,25,25']} scale={0.394} />
          <mesh geometry={nodes.Body22007.geometry} material={materials['177,25,25']} scale={0.394} />
          <mesh geometry={nodes.Body23007.geometry} material={materials['64,64,64']} scale={0.394} />
          <mesh geometry={nodes.Body24007.geometry} material={materials['76,76,76']} scale={0.394} />
          <mesh geometry={nodes.Body25007.geometry} material={materials['255,255,255']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body3008_1.geometry} material={materials['255,255,255']} />
            <mesh geometry={nodes.Body3008_2.geometry} material={materials['229,235,237']} />
          </group>
          <group scale={0.394}>
            <mesh geometry={nodes.Body4008_1.geometry} material={materials['177,25,25']} />
            <mesh geometry={nodes.Body4008_2.geometry} material={materials['76,76,76']} />
          </group>
          <mesh geometry={nodes.Body5019.geometry} material={materials['229,235,237']} scale={0.394} />
          <mesh geometry={nodes.Body6019.geometry} material={materials['166,158,150']} scale={0.394} />
          <mesh geometry={nodes.Body7019.geometry} material={materials['229,235,237']} scale={0.394} />
          <mesh geometry={nodes.Body8019.geometry} material={materials['0,0,0']} scale={0.394} />
          <mesh geometry={nodes.Body9015.geometry} material={materials['255,255,255']} scale={0.394} />
        </group>
        <group position={[34.935, 27.696, 0.069]} rotation={[-Math.PI, 0, 0]}>
          <mesh geometry={nodes.Body1547.geometry} material={materials['0,0,0']} scale={0.394} />
          <mesh geometry={nodes.Body10012.geometry} material={materials['26,26,26']} scale={0.394} />
          <mesh geometry={nodes.Body11012.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body12012.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body13011.geometry} material={materials['229,235,237']} scale={0.394} />
          <mesh geometry={nodes.Body14010.geometry} material={materials['64,64,64']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body15005_1.geometry} material={materials['73,169,84']} />
            <mesh geometry={nodes.Body15005_2.geometry} material={materials['255,255,255']} />
          </group>
          <mesh geometry={nodes.Body16010.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body17008.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body18004.geometry} material={materials['26,26,26']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body19002_1.geometry} material={materials['229,229,229']} />
            <mesh geometry={nodes.Body19002_2.geometry} material={materials['127,127,127']} />
          </group>
          <mesh geometry={nodes.Body2037.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body21004.geometry} material={materials['177,25,25']} scale={0.394} />
          <mesh geometry={nodes.Body22004.geometry} material={materials['177,25,25']} scale={0.394} />
          <mesh geometry={nodes.Body23004.geometry} material={materials['64,64,64']} scale={0.394} />
          <mesh geometry={nodes.Body24004.geometry} material={materials['76,76,76']} scale={0.394} />
          <mesh geometry={nodes.Body25004.geometry} material={materials['255,255,255']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body3008_1.geometry} material={materials['255,255,255']} />
            <mesh geometry={nodes.Body3008_2.geometry} material={materials['229,235,237']} />
          </group>
          <group scale={0.394}>
            <mesh geometry={nodes.Body4008_1.geometry} material={materials['177,25,25']} />
            <mesh geometry={nodes.Body4008_2.geometry} material={materials['76,76,76']} />
          </group>
          <mesh geometry={nodes.Body5016.geometry} material={materials['229,235,237']} scale={0.394} />
          <mesh geometry={nodes.Body6016.geometry} material={materials['166,158,150']} scale={0.394} />
          <mesh geometry={nodes.Body7016.geometry} material={materials['229,235,237']} scale={0.394} />
          <mesh geometry={nodes.Body8016.geometry} material={materials['0,0,0']} scale={0.394} />
          <mesh geometry={nodes.Body9012.geometry} material={materials['255,255,255']} scale={0.394} />
        </group>
        <group position={[34.935, 26.237, 0.069]} rotation={[-Math.PI, 0, 0]}>
          <mesh geometry={nodes.Body1546.geometry} material={materials['0,0,0']} scale={0.394} />
          <mesh geometry={nodes.Body10011.geometry} material={materials['26,26,26']} scale={0.394} />
          <mesh geometry={nodes.Body11011.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body12011.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body13010.geometry} material={materials['229,235,237']} scale={0.394} />
          <mesh geometry={nodes.Body14009.geometry} material={materials['64,64,64']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body15005_1.geometry} material={materials['73,169,84']} />
            <mesh geometry={nodes.Body15005_2.geometry} material={materials['255,255,255']} />
          </group>
          <mesh geometry={nodes.Body16009.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body17007.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body18003.geometry} material={materials['26,26,26']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body19002_1.geometry} material={materials['229,229,229']} />
            <mesh geometry={nodes.Body19002_2.geometry} material={materials['127,127,127']} />
          </group>
          <mesh geometry={nodes.Body2036.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body21003.geometry} material={materials['177,25,25']} scale={0.394} />
          <mesh geometry={nodes.Body22003.geometry} material={materials['177,25,25']} scale={0.394} />
          <mesh geometry={nodes.Body23003.geometry} material={materials['64,64,64']} scale={0.394} />
          <mesh geometry={nodes.Body24003.geometry} material={materials['76,76,76']} scale={0.394} />
          <mesh geometry={nodes.Body25003.geometry} material={materials['255,255,255']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body3008_1.geometry} material={materials['255,255,255']} />
            <mesh geometry={nodes.Body3008_2.geometry} material={materials['229,235,237']} />
          </group>
          <group scale={0.394}>
            <mesh geometry={nodes.Body4008_1.geometry} material={materials['177,25,25']} />
            <mesh geometry={nodes.Body4008_2.geometry} material={materials['76,76,76']} />
          </group>
          <mesh geometry={nodes.Body5015.geometry} material={materials['229,235,237']} scale={0.394} />
          <mesh geometry={nodes.Body6015.geometry} material={materials['166,158,150']} scale={0.394} />
          <mesh geometry={nodes.Body7015.geometry} material={materials['229,235,237']} scale={0.394} />
          <mesh geometry={nodes.Body8015.geometry} material={materials['0,0,0']} scale={0.394} />
          <mesh geometry={nodes.Body9011.geometry} material={materials['255,255,255']} scale={0.394} />
        </group>
        <group position={[21.311, 16.844, 0.069]} rotation={[-Math.PI, 0, -Math.PI]}>
          <mesh geometry={nodes.Body1559.geometry} material={materials['0,0,0']} scale={0.394} />
          <mesh geometry={nodes.Body10019.geometry} material={materials['26,26,26']} scale={0.394} />
          <mesh geometry={nodes.Body11019.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body12019.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body13018.geometry} material={materials['229,235,237']} scale={0.394} />
          <mesh geometry={nodes.Body14017.geometry} material={materials['64,64,64']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body15005_1.geometry} material={materials['73,169,84']} />
            <mesh geometry={nodes.Body15005_2.geometry} material={materials['255,255,255']} />
          </group>
          <mesh geometry={nodes.Body16017.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body17015.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body18011.geometry} material={materials['26,26,26']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body19002_1.geometry} material={materials['229,229,229']} />
            <mesh geometry={nodes.Body19002_2.geometry} material={materials['127,127,127']} />
          </group>
          <mesh geometry={nodes.Body2044.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body21011.geometry} material={materials['177,25,25']} scale={0.394} />
          <mesh geometry={nodes.Body22011.geometry} material={materials['177,25,25']} scale={0.394} />
          <mesh geometry={nodes.Body23011.geometry} material={materials['64,64,64']} scale={0.394} />
          <mesh geometry={nodes.Body24011.geometry} material={materials['76,76,76']} scale={0.394} />
          <mesh geometry={nodes.Body25011.geometry} material={materials['255,255,255']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body3008_1.geometry} material={materials['255,255,255']} />
            <mesh geometry={nodes.Body3008_2.geometry} material={materials['229,235,237']} />
          </group>
          <group scale={0.394}>
            <mesh geometry={nodes.Body4008_1.geometry} material={materials['177,25,25']} />
            <mesh geometry={nodes.Body4008_2.geometry} material={materials['76,76,76']} />
          </group>
          <mesh geometry={nodes.Body5023.geometry} material={materials['229,235,237']} scale={0.394} />
          <mesh geometry={nodes.Body6023.geometry} material={materials['166,158,150']} scale={0.394} />
          <mesh geometry={nodes.Body7023.geometry} material={materials['229,235,237']} scale={0.394} />
          <mesh geometry={nodes.Body8023.geometry} material={materials['0,0,0']} scale={0.394} />
          <mesh geometry={nodes.Body9019.geometry} material={materials['255,255,255']} scale={0.394} />
        </group>
        <group position={[21.311, 15.385, 0.069]} rotation={[Math.PI, 0, Math.PI]}>
          <mesh geometry={nodes.Body1554.geometry} material={materials['0,0,0']} scale={0.394} />
          <mesh geometry={nodes.Body10016.geometry} material={materials['26,26,26']} scale={0.394} />
          <mesh geometry={nodes.Body11016.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body12016.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body13015.geometry} material={materials['229,235,237']} scale={0.394} />
          <mesh geometry={nodes.Body14014.geometry} material={materials['64,64,64']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body15005_1.geometry} material={materials['73,169,84']} />
            <mesh geometry={nodes.Body15005_2.geometry} material={materials['255,255,255']} />
          </group>
          <mesh geometry={nodes.Body16014.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body17012.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body18008.geometry} material={materials['26,26,26']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body19002_1.geometry} material={materials['229,229,229']} />
            <mesh geometry={nodes.Body19002_2.geometry} material={materials['127,127,127']} />
          </group>
          <mesh geometry={nodes.Body2041.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body21008.geometry} material={materials['177,25,25']} scale={0.394} />
          <mesh geometry={nodes.Body22008.geometry} material={materials['177,25,25']} scale={0.394} />
          <mesh geometry={nodes.Body23008.geometry} material={materials['64,64,64']} scale={0.394} />
          <mesh geometry={nodes.Body24008.geometry} material={materials['76,76,76']} scale={0.394} />
          <mesh geometry={nodes.Body25008.geometry} material={materials['255,255,255']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body3008_1.geometry} material={materials['255,255,255']} />
            <mesh geometry={nodes.Body3008_2.geometry} material={materials['229,235,237']} />
          </group>
          <group scale={0.394}>
            <mesh geometry={nodes.Body4008_1.geometry} material={materials['177,25,25']} />
            <mesh geometry={nodes.Body4008_2.geometry} material={materials['76,76,76']} />
          </group>
          <mesh geometry={nodes.Body5020.geometry} material={materials['229,235,237']} scale={0.394} />
          <mesh geometry={nodes.Body6020.geometry} material={materials['166,158,150']} scale={0.394} />
          <mesh geometry={nodes.Body7020.geometry} material={materials['229,235,237']} scale={0.394} />
          <mesh geometry={nodes.Body8020.geometry} material={materials['0,0,0']} scale={0.394} />
          <mesh geometry={nodes.Body9016.geometry} material={materials['255,255,255']} scale={0.394} />
        </group>
        <group position={[34.935, 16.844, 0.069]} rotation={[-Math.PI, 0, 0]}>
          <mesh geometry={nodes.Body1555.geometry} material={materials['0,0,0']} scale={0.394} />
          <mesh geometry={nodes.Body10017.geometry} material={materials['26,26,26']} scale={0.394} />
          <mesh geometry={nodes.Body11017.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body12017.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body13016.geometry} material={materials['229,235,237']} scale={0.394} />
          <mesh geometry={nodes.Body14015.geometry} material={materials['64,64,64']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body15005_1.geometry} material={materials['73,169,84']} />
            <mesh geometry={nodes.Body15005_2.geometry} material={materials['255,255,255']} />
          </group>
          <mesh geometry={nodes.Body16015.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body17013.geometry} material={materials['255,255,255']} scale={0.394} />
          <mesh geometry={nodes.Body18009.geometry} material={materials['26,26,26']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body19002_1.geometry} material={materials['229,229,229']} />
            <mesh geometry={nodes.Body19002_2.geometry} material={materials['127,127,127']} />
          </group>
          <mesh geometry={nodes.Body2042.geometry} material={materials['198,193,188']} scale={0.394} />
          <mesh geometry={nodes.Body21009.geometry} material={materials['177,25,25']} scale={0.394} />
          <mesh geometry={nodes.Body22009.geometry} material={materials['177,25,25']} scale={0.394} />
          <mesh geometry={nodes.Body23009.geometry} material={materials['64,64,64']} scale={0.394} />
          <mesh geometry={nodes.Body24009.geometry} material={materials['76,76,76']} scale={0.394} />
          <mesh geometry={nodes.Body25009.geometry} material={materials['255,255,255']} scale={0.394} />
          <group scale={0.394}>
            <mesh geometry={nodes.Body3008_1.geometry} material={materials['255,255,255']} />
            <mesh geometry={nodes.Body3008_2.geometry} material={materials['229,235,237']} />
          </group>
          <group scale={0.394}>
            <mesh geometry={nodes.Body4008_1.geometry} material={materials['177,25,25']} />
            <mesh geometry={nodes.Body4008_2.geometry} material={materials['76,76,76']} />
          </group>
          <mesh geometry={nodes.Body5021.geometry} material={materials['229,235,237']} scale={0.394} />
          <mesh geometry={nodes.Body6021.geometry} material={materials['166,158,150']} scale={0.394} />
          <mesh geometry={nodes.Body7021.geometry} material={materials['229,235,237']} scale={0.394} />
          <mesh geometry={nodes.Body8021.geometry} material={materials['0,0,0']} scale={0.394} />
          <mesh geometry={nodes.Body9017.geometry} material={materials['255,255,255']} scale={0.394} />
        </group>
        <group position={[25.759, 18.368, 0.85]} rotation={[-Math.PI / 2, 0, 0]}>
          <mesh geometry={nodes.Body1543.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[25.759, 18.368, 0.85]} rotation={[-Math.PI / 2, 0, 0]}>
          <mesh geometry={nodes.Body1541.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[23.623, 30.186, -0.543]}>
          <mesh geometry={nodes.Body1582.geometry} material={materials['Steel - Satin']} scale={0.394} />
        </group>
        <group position={[23.623, 23.186, -0.543]}>
          <mesh geometry={nodes.Body1581.geometry} material={materials['Steel - Satin']} scale={0.394} />
        </group>
        <group position={[32.623, 23.186, -0.543]}>
          <mesh geometry={nodes.Body1583.geometry} material={materials['Steel - Satin']} scale={0.394} />
        </group>
        <group position={[32.623, 30.186, -0.543]}>
          <mesh geometry={nodes.Body1580.geometry} material={materials['Steel - Satin']} scale={0.394} />
        </group>
        <group position={[13.895, 8.936, -1.463]} rotation={[Math.PI, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.Body1602.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[13.895, 13.936, -1.463]} rotation={[-Math.PI, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.Body1603.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[13.895, 14.436, -1.463]} rotation={[-Math.PI, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.Body1605.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[42.351, 12.936, -1.463]} rotation={[0, 0, Math.PI / 2]}>
          <mesh geometry={nodes.Body1607.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[42.351, 13.436, -1.463]} rotation={[0, 0, Math.PI / 2]}>
          <mesh geometry={nodes.Body1614.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[42.351, 13.936, -1.463]} rotation={[0, 0, Math.PI / 2]}>
          <mesh geometry={nodes.Body1612.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[42.351, 14.436, -1.463]} rotation={[0, 0, Math.PI / 2]}>
          <mesh geometry={nodes.Body1611.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[42.351, 9.436, -1.463]} rotation={[0, 0, Math.PI / 2]}>
          <mesh geometry={nodes.Body1610.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[42.351, 9.936, -1.463]} rotation={[0, 0, Math.PI / 2]}>
          <mesh geometry={nodes.Body1609.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[42.351, 10.436, -1.463]} rotation={[0, 0, Math.PI / 2]}>
          <mesh geometry={nodes.Body1613.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[13.895, 12.436, -1.463]} rotation={[Math.PI, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.Body1606.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[42.351, 12.436, -1.463]} rotation={[0, 0, Math.PI / 2]}>
          <mesh geometry={nodes.Body1608.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[42.351, 8.936, -1.463]} rotation={[0, 0, Math.PI / 2]}>
          <mesh geometry={nodes.Body1617.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[13.895, 9.436, -1.463]} rotation={[-Math.PI, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.Body1619.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[13.895, 9.936, -1.463]} rotation={[-Math.PI, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.Body1618.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[13.895, 10.436, -1.463]} rotation={[-Math.PI, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.Body1616.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[13.895, 12.936, -1.463]} rotation={[-Math.PI, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.Body1615.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[13.895, 13.436, -1.463]} rotation={[-Math.PI, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.Body1604.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[14.336, 18.686, 0.475]} rotation={[Math.PI, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.Body1584.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[14.336, 16.686, -0.525]} rotation={[-Math.PI, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.Body1599.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[41.909, 17.686, 0.475]} rotation={[0, 0, Math.PI / 2]}>
          <mesh geometry={nodes.Body1592.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[41.909, 16.686, 0.475]} rotation={[0, 0, Math.PI / 2]}>
          <mesh geometry={nodes.Body1593.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[41.909, 18.686, -0.025]} rotation={[0, 0, Math.PI / 2]}>
          <mesh geometry={nodes.Body1594.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[41.909, 17.686, -0.025]} rotation={[0, 0, Math.PI / 2]}>
          <mesh geometry={nodes.Body1595.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[41.909, 16.686, -0.025]} rotation={[0, 0, Math.PI / 2]}>
          <mesh geometry={nodes.Body1596.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[41.909, 18.686, -0.525]} rotation={[0, 0, Math.PI / 2]}>
          <mesh geometry={nodes.Body1601.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[41.909, 17.686, -0.525]} rotation={[0, 0, Math.PI / 2]}>
          <mesh geometry={nodes.Body1597.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[41.909, 16.686, -0.525]} rotation={[0, 0, Math.PI / 2]}>
          <mesh geometry={nodes.Body1588.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[41.909, 18.686, 0.475]} rotation={[0, 0, Math.PI / 2]}>
          <mesh geometry={nodes.Body1591.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[14.336, 18.686, -0.025]} rotation={[-Math.PI, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.Body1600.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[14.336, 18.686, -0.525]} rotation={[-Math.PI, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.Body1585.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[14.336, 17.686, 0.475]} rotation={[-Math.PI, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.Body1587.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[14.336, 17.686, -0.025]} rotation={[-Math.PI, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.Body1586.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[14.336, 17.686, -0.525]} rotation={[-Math.PI, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.Body1589.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[14.336, 16.686, 0.475]} rotation={[-Math.PI, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.Body1590.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[14.336, 16.686, -0.025]} rotation={[-Math.PI, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.Body1598.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
        </group>
        <group position={[15.123, 8.686, -1.213]}>
          <mesh geometry={nodes.Body1412.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[41.123, 8.686, -1.213]} rotation={[0, 0, Math.PI / 2]}>
          <mesh geometry={nodes.Body1410.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[41.123, 34.686, -1.213]} rotation={[0, 0, Math.PI]}>
          <mesh geometry={nodes.Body1409.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[15.123, 34.686, -1.213]} rotation={[0, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.Body1411.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[15.123, 34.686, 1.1]}>
          <mesh geometry={nodes.Body2.geometry} material={materials['Millennium Falcon Green']} scale={0.394} />
        </group>
        <group position={[23.123, 9.686, 0.975]}>
          <mesh geometry={nodes.Body1003.geometry} material={materials['Millennium Falcon Green']} scale={0.394} />
        </group>
        <group position={[32.123, 9.686, 0.975]}>
          <mesh geometry={nodes.Body1004.geometry} material={materials['Millennium Falcon Green']} scale={0.394} />
        </group>
        <group position={[19.123, 9.686, 0.975]} rotation={[0, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.Body1005.geometry} material={materials['Millennium Falcon Green']} scale={0.394} />
        </group>
        <group position={[41.123, 24.186, 0.975]}>
          <mesh geometry={nodes.Body1007.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[14.873, 24.186, 0.975]}>
          <mesh geometry={nodes.Body1006.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[37.123, 33.686, 0.975]} rotation={[0, 0, Math.PI / 2]}>
          <mesh geometry={nodes.Body1.geometry} material={materials['Millennium Falcon Green']} scale={0.394} />
        </group>
        <group position={[15.123, 8.686, -3.338]}>
          <mesh geometry={nodes.Body2025.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[15.122, 34.686, -3.338]} rotation={[0, 0, -Math.PI / 2]}>
          <mesh geometry={nodes.Body2026.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[41.122, 34.686, -3.338]} rotation={[0, 0, Math.PI]}>
          <mesh geometry={nodes.Body2027.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[41.123, 8.686, -3.338]} rotation={[0, 0, Math.PI / 2]}>
          <mesh geometry={nodes.Body2028.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[23.123, 30.686, -1.025]}>
          <mesh geometry={nodes.Body1008.geometry} material={materials['Polycarbonate (Smoked)']} scale={0.394} />
        </group>
        <group position={[41.123, 34.686, 2.725]}>
          <group position={[-2.225, -6.25, -6.191]} rotation={[Math.PI / 2, Math.PI / 6, Math.PI]}>
            <mesh geometry={nodes.Body1407.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[-6.25, -2.225, -6.191]} rotation={[Math.PI / 2, -Math.PI / 6, -Math.PI]}>
            <mesh geometry={nodes.Body1408.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[-5.041, -3.278, -3.839]} rotation={[Math.PI / 2, -1.11, Math.PI / 2]}>
            <mesh geometry={nodes.Body1313.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-3.056, -4.879, -5.911]} rotation={[Math.PI / 2, -1.228, Math.PI / 2]}>
            <mesh geometry={nodes.Body1388.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-5.041, -3.278, -5.859]} rotation={[Math.PI / 2, -1.228, Math.PI / 2]}>
            <mesh geometry={nodes.Body1389.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-3.056, -4.879, -3.844]} rotation={[0, 0, -1.76]}>
            <mesh geometry={nodes.Body1314.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-2.625, -2.625, -4.315]} rotation={[0, 0, -0.189]}>
            <mesh geometry={nodes.Body1329.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-2.625, -2.625, -5.213]} rotation={[Math.PI / 2, -1.307, -Math.PI]}>
            <group position={[0, 0.878, 0]}>
              <mesh geometry={nodes.Body1321.geometry} material={materials['64,64,64']} scale={0.394} />
              <mesh geometry={nodes.Body2021.geometry} material={materials['255,223,0']} scale={0.394} />
              <mesh geometry={nodes.Body3006.geometry} material={materials['255,223,0']} scale={0.394} />
              <mesh geometry={nodes.Body4006.geometry} material={materials['255,223,0']} scale={0.394} />
              <mesh geometry={nodes.Body5006.geometry} material={materials['255,223,0']} scale={0.394} />
              <mesh geometry={nodes.Body6006.geometry} material={materials['255,223,0']} scale={0.394} />
              <mesh geometry={nodes.Body7006.geometry} material={materials['255,223,0']} scale={0.394} />
              <mesh geometry={nodes.Body8006.geometry} material={materials['0,0,0']} scale={0.394} />
            </group>
            <mesh geometry={nodes.Body1323.geometry} material={materials['239,239,239']} scale={0.394} />
            <mesh geometry={nodes.Body1322.geometry} material={materials['128,128,128']} scale={0.394} />
            <mesh geometry={nodes.Body1320.geometry} material={materials['239,239,239']} scale={0.394} />
          </group>
          <group position={[-2.225, -6.25, -4.865]} rotation={[-Math.PI / 2, -Math.PI / 6, Math.PI / 2]}>
            <mesh geometry={nodes.Body1326.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-6.25, -2.225, -5.263]} rotation={[-Math.PI / 2, Math.PI / 6, Math.PI / 2]}>
            <mesh geometry={nodes.Body1393.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-5.041, -3.278, -5.506]} rotation={[-Math.PI / 2, 0.168, 0]}>
            <mesh geometry={nodes.Body1315.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-3.056, -4.879, -5.058]} rotation={[Math.PI / 2, -0.27, -Math.PI]}>
            <mesh geometry={nodes.Body1390.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-6.326, -2.356, -5.513]} rotation={[0, 0, 2.618]}>
            <mesh geometry={nodes.Body1328.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-2.149, -6.381, -5.115]} rotation={[0, 0, Math.PI / 6]}>
            <mesh geometry={nodes.Body1394.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-2.625, -2.625, 0]} rotation={[0, 0, 2.356]}>
            <group position={[0, 0, -4.65]} rotation={[0, 0, -0.179]}>
              <mesh geometry={nodes.Body1361.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, 0, -5.454]} rotation={[0, 0, -0.179]}>
              <mesh geometry={nodes.Body1373.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, 0, -5.822]} rotation={[0, 0, -0.179]}>
              <mesh geometry={nodes.Body1374.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -4.121]} rotation={[0, 0, 0.381]}>
              <mesh geometry={nodes.Body1375.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -2.814]} rotation={[0, -1.571, 0]}>
              <mesh geometry={nodes.Body1372.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, 0, -3.75]} rotation={[0, 0, 0.206]}>
              <mesh geometry={nodes.Body1358.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, 0.699, -1.998]} rotation={[-Math.PI / 2, 0, 0.187]}>
              <mesh geometry={nodes.Body1360.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, -0.507, -1.998]} rotation={[-Math.PI / 2, 0, -1.345]}>
              <mesh geometry={nodes.Body1359.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -3.327]} rotation={[-Math.PI, 0, 2.371]}>
              <mesh geometry={nodes.Body1353.geometry} material={materials['53,51,48']} scale={0.394} />
            </group>
            <group position={[0, 0, -4.336]} rotation={[Math.PI / 2, 0.752, Math.PI]}>
              <mesh geometry={nodes.Body1348.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, 0, -4.336]} rotation={[Math.PI / 2, 0.752, Math.PI]}>
              <mesh geometry={nodes.Body1349.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, 0, -4.336]} rotation={[Math.PI / 2, 0.752, Math.PI]}>
              <mesh geometry={nodes.Body1347.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -4.8]} rotation={[-Math.PI / 2, -1.323, -Math.PI / 2]}>
              <mesh geometry={nodes.Body1357.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -4.539]} rotation={[-Math.PI / 2, 0.8, Math.PI / 2]}>
              <mesh geometry={nodes.Body1352.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -4.539]} rotation={[0, 0, 2.341]}>
              <mesh geometry={nodes.Body1351.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -4.539]} rotation={[-Math.PI / 2, -0.77, -Math.PI]}>
              <mesh geometry={nodes.Body1350.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, 0, -5.54]}>
              <mesh geometry={nodes.Body1366.geometry} material={materials['198,193,188']} scale={0.394} />
            </group>
            <group position={[0, 0, -3.875]}>
              <mesh geometry={nodes.Body1368.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            </group>
            <group position={[0, 0, -4.452]}>
              <mesh geometry={nodes.Body1365.geometry} material={materials['170,178,196']} scale={0.394} />
            </group>
            <group position={[0, 0.002, -1.998]} rotation={[-Math.PI, 0.918, Math.PI / 2]}>
              <group position={[0.663, 0, 0]} rotation={[0, -Math.PI / 2, 0]}>
                <mesh geometry={nodes.Body1332.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[0.438, 0.282, 1.601]} rotation={[-1.745, 0.668, Math.PI]}>
                <mesh geometry={nodes.Body1334.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.438, 0.282, 1.601]} rotation={[-1.745, 0.88, -Math.PI]}>
                <mesh geometry={nodes.Body1340.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.438, -0.282, 1.601]} rotation={[1.745, 0.758, 0]}>
                <mesh geometry={nodes.Body1341.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[0.438, -0.282, 1.601]} rotation={[1.745, 0.571, 0]}>
                <mesh geometry={nodes.Body1342.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.438, -1.408, -0.813]} rotation={[-2.618, 0.637, 0]}>
                <mesh geometry={nodes.Body1343.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[0.437, -1.408, -0.813]} rotation={[-2.618, 0.385, 0]}>
                <mesh geometry={nodes.Body1344.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[0.437, 1.408, -0.813]} rotation={[-Math.PI / 6, 1.369, 0]}>
                <mesh geometry={nodes.Body1345.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.437, 1.408, -0.813]} rotation={[-Math.PI / 6, 1.03, 0]}>
                <mesh geometry={nodes.Body1346.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.19, 0.813, 0]} rotation={[2.131, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body1333.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.19, 0.406, 0.704]} rotation={[1.61, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body1335.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.19, -0.406, 0.704]} rotation={[1.737, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body1336.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.19, -0.812, 0]} rotation={[1.831, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body1337.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.19, -0.406, -0.704]} rotation={[1.885, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body1338.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.19, 0.406, -0.704]} rotation={[1.895, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body1339.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <mesh geometry={nodes.Body1331.geometry} material={materials['76,76,76']} scale={0.394} />
              <mesh geometry={nodes.Body1330.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -2.691]} rotation={[0, 0, 2.341]}>
              <mesh geometry={nodes.Body1367.geometry} material={materials['170,178,196']} scale={0.394} />
            </group>
            <group position={[0, 0.558, -1.998]} rotation={[0, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body1364.geometry} material={materials['170,178,196']} scale={0.394} />
            </group>
            <group position={[-0.8, -1.2, -3.279]} rotation={[-Math.PI / 2, -0.655, Math.PI]}>
              <mesh geometry={nodes.Body1355.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0.8, -1.2, -3.279]} rotation={[-Math.PI / 2, -0.453, -Math.PI]}>
              <mesh geometry={nodes.Body1370.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, 0, -4.969]} rotation={[Math.PI / 2, 0.315, -Math.PI]}>
              <mesh geometry={nodes.Body1371.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[-0.8, 1.2, -4]} rotation={[-Math.PI / 2, 0.19, 0]}>
              <mesh geometry={nodes.Body1354.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0.8, 1.2, -4]} rotation={[-Math.PI / 2, 0.379, 0]}>
              <mesh geometry={nodes.Body1369.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, 1.513, -1.998]} rotation={[Math.PI / 2, 0, 1.614]}>
              <mesh geometry={nodes.Body1356.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -2.912]}>
              <mesh geometry={nodes.Body1376.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, 1.2, -1.998]} rotation={[0, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body1363.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            </group>
            <group position={[0, -1.2, -1.998]} rotation={[0, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body1362.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            </group>
          </group>
          <group position={[-5.041, -3.278, -3.938]}>
            <mesh geometry={nodes.Body1318.geometry} material={materials['170,178,196']} scale={0.394} />
          </group>
          <group position={[-3.056, -4.879, -5.307]} rotation={[0, 0, -2.387]}>
            <mesh geometry={nodes.Body1317.geometry} material={materials['170,178,196']} scale={0.394} />
          </group>
          <group position={[-3.056, -4.879, -6.004]} rotation={[0, 0, -0.052]}>
            <mesh geometry={nodes.Body1316.geometry} material={materials['170,178,196']} scale={0.394} />
            <mesh geometry={nodes.Body2020.geometry} material={materials['170,178,196']} scale={0.394} />
          </group>
          <group position={[-6.25, -2.225, -4.688]} rotation={[-Math.PI / 2, Math.PI / 6, 0]}>
            <group scale={0.394}>
              <mesh geometry={nodes.Body1021_1.geometry} material={materials['165,158,150']} />
              <mesh geometry={nodes.Body1021_2.geometry} material={materials['26,26,26']} />
            </group>
          </group>
          <group position={[-2.225, -6.25, -4.688]} rotation={[-Math.PI / 2, -Math.PI / 6, 0]}>
            <group scale={0.394}>
              <mesh geometry={nodes.Body1021_1.geometry} material={materials['165,158,150']} />
              <mesh geometry={nodes.Body1021_2.geometry} material={materials['26,26,26']} />
            </group>
          </group>
          <group position={[-1.663, -4.514, -3.524]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1309.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-4.124, -4.124, -3.524]} rotation={[Math.PI / 2, -0.19, 0]}>
            <mesh geometry={nodes.Body1379.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-4.514, -1.663, -3.524]} rotation={[Math.PI / 2, -0.407, 0]}>
            <mesh geometry={nodes.Body1380.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-2.501, -0.509, -3.524]} rotation={[Math.PI / 2, -0.644, 0]}>
            <mesh geometry={nodes.Body1381.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-0.509, -2.501, -3.524]} rotation={[Math.PI / 2, -0.885, 0]}>
            <mesh geometry={nodes.Body1382.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-3.091, -6.75, -4.372]} rotation={[-Math.PI / 2, 1.252, Math.PI]}>
            <mesh geometry={nodes.Body1310.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-1.725, -7.116, -4.372]} rotation={[1.571, 1.483, 0]}>
            <mesh geometry={nodes.Body1395.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-1.359, -5.75, -4.373]} rotation={[Math.PI / 2, 1.005, 0]}>
            <mesh geometry={nodes.Body1396.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-5.75, -1.359, -4.373]} rotation={[Math.PI / 2, 0.779, 0]}>
            <mesh geometry={nodes.Body1397.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-7.116, -1.725, -4.373]} rotation={[Math.PI / 2, 0.439, 0]}>
            <mesh geometry={nodes.Body1398.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-6.75, -3.091, -4.372]} rotation={[Math.PI / 2, 0.319, 0]}>
            <mesh geometry={nodes.Body1399.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-4.902, -4.902, -3.623]} rotation={[-Math.PI / 2, 0.722, -Math.PI]}>
            <mesh geometry={nodes.Body1311.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-1.285, -4.5, -3.623]} rotation={[-Math.PI / 2, 0.912, -Math.PI]}>
            <mesh geometry={nodes.Body1383.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-4.5, -1.285, -3.623]} rotation={[-Math.PI / 2, 1.129, -Math.PI]}>
            <mesh geometry={nodes.Body1384.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-0.5, -0.5, -3.878]} rotation={[-Math.PI / 2, 0.442, 0]}>
            <mesh geometry={nodes.Body1385.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-4.5, -0.5, -4.128]} rotation={[Math.PI / 2, 0.579, -Math.PI]}>
            <mesh geometry={nodes.Body1378.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-5.5, -0.5, -4.128]} rotation={[Math.PI / 2, 0.389, Math.PI]}>
            <mesh geometry={nodes.Body1400.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-0.5, -4.5, -4.128]} rotation={[Math.PI / 2, 0.172, -Math.PI]}>
            <mesh geometry={nodes.Body1401.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-0.5, -5.5, -4.128]} rotation={[Math.PI / 2, -0.065, Math.PI]}>
            <mesh geometry={nodes.Body1402.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-0.5, -6.5, -4.128]} rotation={[Math.PI / 2, -0.306, Math.PI]}>
            <mesh geometry={nodes.Body1403.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-6.5, -0.5, -4.128]} rotation={[Math.PI / 2, 0.2, Math.PI]}>
            <mesh geometry={nodes.Body1404.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-2.7, -2.908, -5.925]} rotation={[-Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1319.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-2.55, -2.342, -5.925]} rotation={[-Math.PI / 2, 0.424, 0]}>
            <mesh geometry={nodes.Body1391.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-3.188, -2.209, -6.806]} rotation={[-Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1312.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body2019.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-2.21, -2.209, -6.806]} rotation={[-Math.PI / 2, 0.19, 0]}>
            <mesh geometry={nodes.Body1386.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body2023.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-2.21, -3.188, -6.806]} rotation={[-Math.PI / 2, 0.407, 0]}>
            <mesh geometry={nodes.Body1387.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body2024.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-6.25, -2.225, -5.765]} rotation={[0, 0, -Math.PI / 6]}>
            <mesh geometry={nodes.Body1406.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-2.225, -6.25, -5.365]} rotation={[0, 0, Math.PI / 6]}>
            <mesh geometry={nodes.Body1405.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-2.225, -6.25, -4.825]} rotation={[0, 0, -0.341]}>
            <mesh geometry={nodes.Body1325.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-6.25, -2.225, -4.825]}>
            <mesh geometry={nodes.Body1327.geometry} material={materials['170,178,196']} scale={0.394} />
          </group>
          <mesh geometry={nodes.Body1377.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body10003.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body11003.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body12003.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body13003.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body14003.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body15003.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body16003.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body17003.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body2022.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body3007.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body4007.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body5007.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body6007.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body7007.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body8007.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body9003.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[15.123, 34.686, 2.725]} rotation={[0, 0, Math.PI / 2]}>
          <group position={[-2.225, -6.25, -6.191]} rotation={[Math.PI / 2, Math.PI / 6, Math.PI]}>
            <mesh geometry={nodes.Body1307.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[-6.25, -2.225, -6.191]} rotation={[Math.PI / 2, -Math.PI / 6, -Math.PI]}>
            <mesh geometry={nodes.Body1308.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[-5.041, -3.278, -3.839]} rotation={[Math.PI / 2, -1.11, Math.PI / 2]}>
            <mesh geometry={nodes.Body1213.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-3.056, -4.879, -5.911]} rotation={[Math.PI / 2, -1.228, Math.PI / 2]}>
            <mesh geometry={nodes.Body1288.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-5.041, -3.278, -5.859]} rotation={[Math.PI / 2, -1.228, Math.PI / 2]}>
            <mesh geometry={nodes.Body1289.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-3.056, -4.879, -3.844]} rotation={[0, 0, -1.76]}>
            <mesh geometry={nodes.Body1214.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-2.625, -2.625, -4.315]} rotation={[0, 0, -0.189]}>
            <mesh geometry={nodes.Body1229.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-2.625, -2.625, -5.213]} rotation={[Math.PI / 2, -1.307, -Math.PI]}>
            <group position={[0, 0.878, 0]}>
              <mesh geometry={nodes.Body1221.geometry} material={materials['64,64,64']} scale={0.394} />
              <mesh geometry={nodes.Body2015.geometry} material={materials['255,223,0']} scale={0.394} />
              <mesh geometry={nodes.Body3004.geometry} material={materials['255,223,0']} scale={0.394} />
              <mesh geometry={nodes.Body4004.geometry} material={materials['255,223,0']} scale={0.394} />
              <mesh geometry={nodes.Body5004.geometry} material={materials['255,223,0']} scale={0.394} />
              <mesh geometry={nodes.Body6004.geometry} material={materials['255,223,0']} scale={0.394} />
              <mesh geometry={nodes.Body7004.geometry} material={materials['255,223,0']} scale={0.394} />
              <mesh geometry={nodes.Body8004.geometry} material={materials['0,0,0']} scale={0.394} />
            </group>
            <mesh geometry={nodes.Body1223.geometry} material={materials['239,239,239']} scale={0.394} />
            <mesh geometry={nodes.Body1222.geometry} material={materials['128,128,128']} scale={0.394} />
            <mesh geometry={nodes.Body1220.geometry} material={materials['239,239,239']} scale={0.394} />
          </group>
          <group position={[-2.225, -6.25, -4.865]} rotation={[-Math.PI / 2, -Math.PI / 6, Math.PI / 2]}>
            <mesh geometry={nodes.Body1226.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-6.25, -2.225, -5.263]} rotation={[-Math.PI / 2, Math.PI / 6, Math.PI / 2]}>
            <mesh geometry={nodes.Body1293.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-5.041, -3.278, -5.506]} rotation={[-Math.PI / 2, 0.168, 0]}>
            <mesh geometry={nodes.Body1215.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-3.056, -4.879, -5.058]} rotation={[Math.PI / 2, -0.27, Math.PI]}>
            <mesh geometry={nodes.Body1290.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-6.326, -2.356, -5.513]} rotation={[0, 0, 2.618]}>
            <mesh geometry={nodes.Body1228.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-2.149, -6.381, -5.115]} rotation={[0, 0, Math.PI / 6]}>
            <mesh geometry={nodes.Body1294.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-2.625, -2.625, 0]} rotation={[0, 0, 2.356]}>
            <group position={[0, 0, -4.65]} rotation={[0, 0, -0.179]}>
              <mesh geometry={nodes.Body1261.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, 0, -5.454]} rotation={[0, 0, -0.179]}>
              <mesh geometry={nodes.Body1273.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, 0, -5.822]} rotation={[0, 0, -0.179]}>
              <mesh geometry={nodes.Body1274.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -4.121]} rotation={[0, 0, 0.381]}>
              <mesh geometry={nodes.Body1275.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -2.814]} rotation={[0, -1.571, 0]}>
              <mesh geometry={nodes.Body1272.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, 0, -3.75]} rotation={[0, 0, 0.206]}>
              <mesh geometry={nodes.Body1258.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, 0.699, -1.998]} rotation={[-Math.PI / 2, 0, 0.187]}>
              <mesh geometry={nodes.Body1260.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, -0.507, -1.998]} rotation={[-Math.PI / 2, 0, -1.345]}>
              <mesh geometry={nodes.Body1259.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -3.327]} rotation={[Math.PI, 0, 2.371]}>
              <mesh geometry={nodes.Body1253.geometry} material={materials['53,51,48']} scale={0.394} />
            </group>
            <group position={[0, 0, -4.336]} rotation={[Math.PI / 2, 0.752, Math.PI]}>
              <mesh geometry={nodes.Body1248.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, 0, -4.336]} rotation={[Math.PI / 2, 0.752, Math.PI]}>
              <mesh geometry={nodes.Body1249.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, 0, -4.336]} rotation={[Math.PI / 2, 0.752, Math.PI]}>
              <mesh geometry={nodes.Body1247.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -4.8]} rotation={[-Math.PI / 2, -1.323, -Math.PI / 2]}>
              <mesh geometry={nodes.Body1257.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -4.539]} rotation={[-Math.PI / 2, 0.8, Math.PI / 2]}>
              <mesh geometry={nodes.Body1252.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -4.539]} rotation={[0, 0, 2.341]}>
              <mesh geometry={nodes.Body1251.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -4.539]} rotation={[-Math.PI / 2, -0.77, -Math.PI]}>
              <mesh geometry={nodes.Body1250.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, 0, -5.539]}>
              <mesh geometry={nodes.Body1266.geometry} material={materials['198,193,188']} scale={0.394} />
            </group>
            <group position={[0, 0, -3.875]}>
              <mesh geometry={nodes.Body1268.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            </group>
            <group position={[0, 0, -4.452]}>
              <mesh geometry={nodes.Body1265.geometry} material={materials['170,178,196']} scale={0.394} />
            </group>
            <group position={[0, 0.003, -1.998]} rotation={[-Math.PI, 0.918, Math.PI / 2]}>
              <group position={[0.663, 0, 0]} rotation={[0, -Math.PI / 2, 0]}>
                <mesh geometry={nodes.Body1232.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[0.438, 0.282, 1.601]} rotation={[-1.745, 0.668, Math.PI]}>
                <mesh geometry={nodes.Body1234.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.437, 0.282, 1.601]} rotation={[-1.745, 0.88, -Math.PI]}>
                <mesh geometry={nodes.Body1240.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.437, -0.282, 1.601]} rotation={[1.745, 0.758, 0]}>
                <mesh geometry={nodes.Body1241.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[0.438, -0.282, 1.601]} rotation={[1.745, 0.571, 0]}>
                <mesh geometry={nodes.Body1242.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.437, -1.408, -0.813]} rotation={[-2.618, 0.637, 0]}>
                <mesh geometry={nodes.Body1243.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[0.438, -1.408, -0.813]} rotation={[-2.618, 0.385, 0]}>
                <mesh geometry={nodes.Body1244.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[0.438, 1.408, -0.813]} rotation={[-Math.PI / 6, 1.369, 0]}>
                <mesh geometry={nodes.Body1245.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.437, 1.408, -0.813]} rotation={[-Math.PI / 6, 1.03, 0]}>
                <mesh geometry={nodes.Body1246.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.19, 0.813, 0]} rotation={[2.131, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body1233.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.19, 0.406, 0.704]} rotation={[1.61, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body1235.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.19, -0.406, 0.704]} rotation={[1.737, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body1236.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.19, -0.813, 0]} rotation={[1.831, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body1237.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.19, -0.406, -0.704]} rotation={[1.885, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body1238.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.19, 0.406, -0.704]} rotation={[1.895, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body1239.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <mesh geometry={nodes.Body1231.geometry} material={materials['76,76,76']} scale={0.394} />
              <mesh geometry={nodes.Body1230.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -2.691]} rotation={[0, 0, 2.341]}>
              <mesh geometry={nodes.Body1267.geometry} material={materials['170,178,196']} scale={0.394} />
            </group>
            <group position={[0, 0.558, -1.998]} rotation={[0, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body1264.geometry} material={materials['170,178,196']} scale={0.394} />
            </group>
            <group position={[-0.8, -1.2, -3.279]} rotation={[-Math.PI / 2, -0.655, -Math.PI]}>
              <mesh geometry={nodes.Body1255.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0.8, -1.2, -3.279]} rotation={[-Math.PI / 2, -0.453, -Math.PI]}>
              <mesh geometry={nodes.Body1270.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, 0, -4.969]} rotation={[Math.PI / 2, 0.315, Math.PI]}>
              <mesh geometry={nodes.Body1271.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[-0.8, 1.2, -4]} rotation={[-Math.PI / 2, 0.19, 0]}>
              <mesh geometry={nodes.Body1254.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0.8, 1.2, -4]} rotation={[-Math.PI / 2, 0.379, 0]}>
              <mesh geometry={nodes.Body1269.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, 1.513, -1.998]} rotation={[Math.PI / 2, 0, 1.614]}>
              <mesh geometry={nodes.Body1256.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -2.912]}>
              <mesh geometry={nodes.Body1276.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, 1.2, -1.998]} rotation={[0, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body1263.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            </group>
            <group position={[0, -1.2, -1.998]} rotation={[0, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body1262.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            </group>
          </group>
          <group position={[-5.041, -3.278, -3.938]}>
            <mesh geometry={nodes.Body1218.geometry} material={materials['170,178,196']} scale={0.394} />
          </group>
          <group position={[-3.056, -4.879, -5.307]} rotation={[0, 0, -2.387]}>
            <mesh geometry={nodes.Body1217.geometry} material={materials['170,178,196']} scale={0.394} />
          </group>
          <group position={[-3.056, -4.879, -6.004]} rotation={[0, 0, -0.052]}>
            <mesh geometry={nodes.Body1216.geometry} material={materials['170,178,196']} scale={0.394} />
            <mesh geometry={nodes.Body2014.geometry} material={materials['170,178,196']} scale={0.394} />
          </group>
          <group position={[-6.25, -2.225, -4.688]} rotation={[-Math.PI / 2, Math.PI / 6, 0]}>
            <group scale={0.394}>
              <mesh geometry={nodes.Body1021_1.geometry} material={materials['165,158,150']} />
              <mesh geometry={nodes.Body1021_2.geometry} material={materials['26,26,26']} />
            </group>
          </group>
          <group position={[-2.225, -6.25, -4.688]} rotation={[-Math.PI / 2, -Math.PI / 6, 0]}>
            <group scale={0.394}>
              <mesh geometry={nodes.Body1021_1.geometry} material={materials['165,158,150']} />
              <mesh geometry={nodes.Body1021_2.geometry} material={materials['26,26,26']} />
            </group>
          </group>
          <group position={[-1.663, -4.514, -3.524]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1209.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-4.124, -4.124, -3.524]} rotation={[Math.PI / 2, -0.19, 0]}>
            <mesh geometry={nodes.Body1279.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-4.514, -1.663, -3.524]} rotation={[Math.PI / 2, -0.407, 0]}>
            <mesh geometry={nodes.Body1280.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-2.501, -0.509, -3.524]} rotation={[Math.PI / 2, -0.644, 0]}>
            <mesh geometry={nodes.Body1281.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-0.509, -2.501, -3.524]} rotation={[Math.PI / 2, -0.885, 0]}>
            <mesh geometry={nodes.Body1282.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-3.091, -6.75, -4.372]} rotation={[-Math.PI / 2, 1.252, Math.PI]}>
            <mesh geometry={nodes.Body1210.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-1.725, -7.116, -4.373]} rotation={[1.571, 1.483, 0]}>
            <mesh geometry={nodes.Body1295.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-1.359, -5.75, -4.373]} rotation={[Math.PI / 2, 1.005, 0]}>
            <mesh geometry={nodes.Body1296.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-5.75, -1.359, -4.373]} rotation={[Math.PI / 2, 0.779, 0]}>
            <mesh geometry={nodes.Body1297.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-7.116, -1.725, -4.372]} rotation={[Math.PI / 2, 0.439, 0]}>
            <mesh geometry={nodes.Body1298.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-6.75, -3.091, -4.372]} rotation={[Math.PI / 2, 0.319, 0]}>
            <mesh geometry={nodes.Body1299.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-4.902, -4.902, -3.623]} rotation={[-Math.PI / 2, 0.722, -Math.PI]}>
            <mesh geometry={nodes.Body1211.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-1.285, -4.5, -3.623]} rotation={[-Math.PI / 2, 0.912, Math.PI]}>
            <mesh geometry={nodes.Body1283.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-4.5, -1.285, -3.623]} rotation={[-Math.PI / 2, 1.129, Math.PI]}>
            <mesh geometry={nodes.Body1284.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-0.5, -0.5, -3.878]} rotation={[-Math.PI / 2, 0.442, 0]}>
            <mesh geometry={nodes.Body1285.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-4.5, -0.5, -4.128]} rotation={[Math.PI / 2, 0.579, -Math.PI]}>
            <mesh geometry={nodes.Body1278.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-5.5, -0.5, -4.128]} rotation={[Math.PI / 2, 0.389, Math.PI]}>
            <mesh geometry={nodes.Body1300.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-0.5, -4.5, -4.128]} rotation={[Math.PI / 2, 0.172, -Math.PI]}>
            <mesh geometry={nodes.Body1301.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-0.5, -5.5, -4.128]} rotation={[Math.PI / 2, -0.065, -Math.PI]}>
            <mesh geometry={nodes.Body1302.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-0.5, -6.5, -4.128]} rotation={[Math.PI / 2, -0.306, -Math.PI]}>
            <mesh geometry={nodes.Body1303.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-6.5, -0.5, -4.128]} rotation={[Math.PI / 2, 0.2, Math.PI]}>
            <mesh geometry={nodes.Body1304.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-2.7, -2.908, -5.925]} rotation={[-Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1219.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-2.55, -2.342, -5.925]} rotation={[-Math.PI / 2, 0.424, 0]}>
            <mesh geometry={nodes.Body1291.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-3.188, -2.209, -6.806]} rotation={[-Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1212.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body2013.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-2.21, -2.209, -6.806]} rotation={[-Math.PI / 2, 0.19, 0]}>
            <mesh geometry={nodes.Body1286.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body2017.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-2.21, -3.188, -6.806]} rotation={[-Math.PI / 2, 0.407, 0]}>
            <mesh geometry={nodes.Body1287.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body2018.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-6.25, -2.225, -5.765]} rotation={[0, 0, -Math.PI / 6]}>
            <mesh geometry={nodes.Body1306.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-2.225, -6.25, -5.365]} rotation={[0, 0, Math.PI / 6]}>
            <mesh geometry={nodes.Body1305.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-2.225, -6.25, -4.825]} rotation={[0, 0, -0.341]}>
            <mesh geometry={nodes.Body1225.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-6.25, -2.225, -4.825]}>
            <mesh geometry={nodes.Body1227.geometry} material={materials['170,178,196']} scale={0.394} />
          </group>
          <mesh geometry={nodes.Body1277.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body10002.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body11002.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body12002.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body13002.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body14002.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body15002.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body16002.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body17002.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body2016.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body3005.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body4005.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body5005.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body6005.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body7005.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body8005.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body9002.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[15.123, 8.686, 2.725]} rotation={[0, 0, -Math.PI]}>
          <group position={[-2.225, -6.25, -6.191]} rotation={[Math.PI / 2, Math.PI / 6, Math.PI]}>
            <mesh geometry={nodes.Body1207.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[-6.25, -2.225, -6.191]} rotation={[Math.PI / 2, -Math.PI / 6, -Math.PI]}>
            <mesh geometry={nodes.Body1208.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[-5.041, -3.278, -3.839]} rotation={[Math.PI / 2, -1.11, Math.PI / 2]}>
            <mesh geometry={nodes.Body1113.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-3.056, -4.879, -5.911]} rotation={[Math.PI / 2, -1.228, Math.PI / 2]}>
            <mesh geometry={nodes.Body1188.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-5.041, -3.278, -5.859]} rotation={[Math.PI / 2, -1.228, Math.PI / 2]}>
            <mesh geometry={nodes.Body1189.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-3.056, -4.879, -3.844]} rotation={[0, 0, -1.76]}>
            <mesh geometry={nodes.Body1114.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-2.625, -2.625, -4.315]} rotation={[0, 0, -0.189]}>
            <mesh geometry={nodes.Body1129.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-2.625, -2.625, -5.213]} rotation={[Math.PI / 2, -1.307, -Math.PI]}>
            <group position={[0, 0.878, 0]}>
              <mesh geometry={nodes.Body1121.geometry} material={materials['64,64,64']} scale={0.394} />
              <mesh geometry={nodes.Body2009.geometry} material={materials['255,223,0']} scale={0.394} />
              <mesh geometry={nodes.Body3002.geometry} material={materials['255,223,0']} scale={0.394} />
              <mesh geometry={nodes.Body4002.geometry} material={materials['255,223,0']} scale={0.394} />
              <mesh geometry={nodes.Body5002.geometry} material={materials['255,223,0']} scale={0.394} />
              <mesh geometry={nodes.Body6002.geometry} material={materials['255,223,0']} scale={0.394} />
              <mesh geometry={nodes.Body7002.geometry} material={materials['255,223,0']} scale={0.394} />
              <mesh geometry={nodes.Body8002.geometry} material={materials['0,0,0']} scale={0.394} />
            </group>
            <mesh geometry={nodes.Body1123.geometry} material={materials['239,239,239']} scale={0.394} />
            <mesh geometry={nodes.Body1122.geometry} material={materials['128,128,128']} scale={0.394} />
            <mesh geometry={nodes.Body1120.geometry} material={materials['239,239,239']} scale={0.394} />
          </group>
          <group position={[-2.225, -6.25, -4.865]} rotation={[-Math.PI / 2, -Math.PI / 6, Math.PI / 2]}>
            <mesh geometry={nodes.Body1126.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-6.25, -2.225, -5.263]} rotation={[-Math.PI / 2, Math.PI / 6, Math.PI / 2]}>
            <mesh geometry={nodes.Body1193.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-5.041, -3.278, -5.506]} rotation={[-Math.PI / 2, 0.168, 0]}>
            <mesh geometry={nodes.Body1115.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-3.056, -4.879, -5.058]} rotation={[Math.PI / 2, -0.27, Math.PI]}>
            <mesh geometry={nodes.Body1190.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-6.326, -2.356, -5.513]} rotation={[0, 0, 2.618]}>
            <mesh geometry={nodes.Body1128.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-2.149, -6.381, -5.115]} rotation={[0, 0, Math.PI / 6]}>
            <mesh geometry={nodes.Body1194.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-2.625, -2.625, 0]} rotation={[0, 0, 2.356]}>
            <group position={[0, 0, -4.65]} rotation={[0, 0, -0.179]}>
              <mesh geometry={nodes.Body1161.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, 0, -5.454]} rotation={[0, 0, -0.179]}>
              <mesh geometry={nodes.Body1173.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, 0, -5.822]} rotation={[0, 0, -0.179]}>
              <mesh geometry={nodes.Body1174.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -4.121]} rotation={[0, 0, 0.381]}>
              <mesh geometry={nodes.Body1175.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -2.814]} rotation={[0, -1.571, 0]}>
              <mesh geometry={nodes.Body1172.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, 0, -3.75]} rotation={[0, 0, 0.206]}>
              <mesh geometry={nodes.Body1158.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, 0.699, -1.998]} rotation={[-Math.PI / 2, 0, 0.187]}>
              <mesh geometry={nodes.Body1160.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, -0.507, -1.998]} rotation={[-Math.PI / 2, 0, -1.345]}>
              <mesh geometry={nodes.Body1159.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -3.327]} rotation={[-Math.PI, 0, 2.371]}>
              <mesh geometry={nodes.Body1153.geometry} material={materials['53,51,48']} scale={0.394} />
            </group>
            <group position={[0, 0, -4.336]} rotation={[Math.PI / 2, 0.752, -Math.PI]}>
              <mesh geometry={nodes.Body1148.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, 0, -4.336]} rotation={[Math.PI / 2, 0.752, -Math.PI]}>
              <mesh geometry={nodes.Body1149.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, 0, -4.336]} rotation={[Math.PI / 2, 0.752, -Math.PI]}>
              <mesh geometry={nodes.Body1147.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -4.8]} rotation={[-Math.PI / 2, -1.323, -Math.PI / 2]}>
              <mesh geometry={nodes.Body1157.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -4.539]} rotation={[-Math.PI / 2, 0.8, Math.PI / 2]}>
              <mesh geometry={nodes.Body1152.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -4.539]} rotation={[0, 0, 2.341]}>
              <mesh geometry={nodes.Body1151.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -4.539]} rotation={[-Math.PI / 2, -0.77, -Math.PI]}>
              <mesh geometry={nodes.Body1150.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, 0, -5.539]}>
              <mesh geometry={nodes.Body1166.geometry} material={materials['198,193,188']} scale={0.394} />
            </group>
            <group position={[0, 0, -3.875]}>
              <mesh geometry={nodes.Body1168.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            </group>
            <group position={[0, 0, -4.452]}>
              <mesh geometry={nodes.Body1165.geometry} material={materials['170,178,196']} scale={0.394} />
            </group>
            <group position={[0, 0.003, -1.998]} rotation={[-Math.PI, 0.918, Math.PI / 2]}>
              <group position={[0.663, 0, 0]} rotation={[0, -1.571, 0]}>
                <mesh geometry={nodes.Body1132.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[0.438, 0.282, 1.601]} rotation={[-1.745, 0.668, Math.PI]}>
                <mesh geometry={nodes.Body1134.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.437, 0.282, 1.601]} rotation={[-1.745, 0.88, -Math.PI]}>
                <mesh geometry={nodes.Body1140.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.437, -0.282, 1.601]} rotation={[1.745, 0.758, 0]}>
                <mesh geometry={nodes.Body1141.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[0.438, -0.282, 1.601]} rotation={[1.745, 0.571, 0]}>
                <mesh geometry={nodes.Body1142.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.438, -1.408, -0.813]} rotation={[-2.618, 0.637, 0]}>
                <mesh geometry={nodes.Body1143.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[0.438, -1.408, -0.813]} rotation={[-2.618, 0.385, 0]}>
                <mesh geometry={nodes.Body1144.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[0.438, 1.408, -0.813]} rotation={[-Math.PI / 6, 1.369, 0]}>
                <mesh geometry={nodes.Body1145.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.437, 1.408, -0.813]} rotation={[-Math.PI / 6, 1.03, 0]}>
                <mesh geometry={nodes.Body1146.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.19, 0.813, 0]} rotation={[2.131, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body1133.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.19, 0.406, 0.704]} rotation={[1.61, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body1135.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.19, -0.406, 0.704]} rotation={[1.737, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body1136.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.19, -0.813, 0]} rotation={[1.831, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body1137.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.19, -0.406, -0.704]} rotation={[1.885, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body1138.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.19, 0.406, -0.704]} rotation={[1.895, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body1139.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <mesh geometry={nodes.Body1131.geometry} material={materials['76,76,76']} scale={0.394} />
              <mesh geometry={nodes.Body1130.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -2.691]} rotation={[0, 0, 2.341]}>
              <mesh geometry={nodes.Body1167.geometry} material={materials['170,178,196']} scale={0.394} />
            </group>
            <group position={[0, 0.558, -1.998]} rotation={[0, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body1164.geometry} material={materials['170,178,196']} scale={0.394} />
            </group>
            <group position={[-0.8, -1.2, -3.279]} rotation={[-Math.PI / 2, -0.655, Math.PI]}>
              <mesh geometry={nodes.Body1155.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0.8, -1.2, -3.279]} rotation={[-Math.PI / 2, -0.453, Math.PI]}>
              <mesh geometry={nodes.Body1170.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, 0, -4.969]} rotation={[Math.PI / 2, 0.315, Math.PI]}>
              <mesh geometry={nodes.Body1171.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[-0.8, 1.2, -4]} rotation={[-Math.PI / 2, 0.19, 0]}>
              <mesh geometry={nodes.Body1154.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0.8, 1.2, -4]} rotation={[-Math.PI / 2, 0.379, 0]}>
              <mesh geometry={nodes.Body1169.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, 1.513, -1.998]} rotation={[Math.PI / 2, 0, 1.614]}>
              <mesh geometry={nodes.Body1156.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -2.912]}>
              <mesh geometry={nodes.Body1176.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, 1.2, -1.998]} rotation={[0, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body1163.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            </group>
            <group position={[0, -1.2, -1.998]} rotation={[0, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body1162.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            </group>
          </group>
          <group position={[-5.041, -3.278, -3.937]}>
            <mesh geometry={nodes.Body1118.geometry} material={materials['170,178,196']} scale={0.394} />
          </group>
          <group position={[-3.056, -4.879, -5.308]} rotation={[0, 0, -2.387]}>
            <mesh geometry={nodes.Body1117.geometry} material={materials['170,178,196']} scale={0.394} />
          </group>
          <group position={[-3.056, -4.879, -6.004]} rotation={[0, 0, -0.052]}>
            <mesh geometry={nodes.Body1116.geometry} material={materials['170,178,196']} scale={0.394} />
            <mesh geometry={nodes.Body2008.geometry} material={materials['170,178,196']} scale={0.394} />
          </group>
          <group position={[-6.25, -2.225, -4.688]} rotation={[-Math.PI / 2, Math.PI / 6, 0]}>
            <group scale={0.394}>
              <mesh geometry={nodes.Body1021_1.geometry} material={materials['165,158,150']} />
              <mesh geometry={nodes.Body1021_2.geometry} material={materials['26,26,26']} />
            </group>
          </group>
          <group position={[-2.225, -6.25, -4.688]} rotation={[-Math.PI / 2, -Math.PI / 6, 0]}>
            <group scale={0.394}>
              <mesh geometry={nodes.Body1021_1.geometry} material={materials['165,158,150']} />
              <mesh geometry={nodes.Body1021_2.geometry} material={materials['26,26,26']} />
            </group>
          </group>
          <group position={[-1.663, -4.514, -3.524]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1109.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-4.124, -4.124, -3.524]} rotation={[Math.PI / 2, -0.19, 0]}>
            <mesh geometry={nodes.Body1179.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-4.514, -1.663, -3.524]} rotation={[Math.PI / 2, -0.407, 0]}>
            <mesh geometry={nodes.Body1180.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-2.501, -0.509, -3.524]} rotation={[Math.PI / 2, -0.644, 0]}>
            <mesh geometry={nodes.Body1181.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-0.509, -2.501, -3.524]} rotation={[Math.PI / 2, -0.885, 0]}>
            <mesh geometry={nodes.Body1182.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-3.091, -6.75, -4.372]} rotation={[-Math.PI / 2, 1.252, Math.PI]}>
            <mesh geometry={nodes.Body1110.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-1.725, -7.116, -4.372]} rotation={[1.571, 1.483, 0]}>
            <mesh geometry={nodes.Body1195.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-1.359, -5.75, -4.373]} rotation={[Math.PI / 2, 1.005, 0]}>
            <mesh geometry={nodes.Body1196.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-5.75, -1.359, -4.372]} rotation={[Math.PI / 2, 0.779, 0]}>
            <mesh geometry={nodes.Body1197.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-7.116, -1.725, -4.372]} rotation={[Math.PI / 2, 0.439, 0]}>
            <mesh geometry={nodes.Body1198.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-6.75, -3.091, -4.372]} rotation={[Math.PI / 2, 0.319, 0]}>
            <mesh geometry={nodes.Body1199.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-4.902, -4.902, -3.623]} rotation={[-Math.PI / 2, 0.722, -Math.PI]}>
            <mesh geometry={nodes.Body1111.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-1.285, -4.5, -3.623]} rotation={[-Math.PI / 2, 0.912, Math.PI]}>
            <mesh geometry={nodes.Body1183.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-4.5, -1.285, -3.623]} rotation={[-Math.PI / 2, 1.129, Math.PI]}>
            <mesh geometry={nodes.Body1184.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-0.5, -0.5, -3.878]} rotation={[-Math.PI / 2, 0.442, 0]}>
            <mesh geometry={nodes.Body1185.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-4.5, -0.5, -4.128]} rotation={[Math.PI / 2, 0.579, Math.PI]}>
            <mesh geometry={nodes.Body1178.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-5.5, -0.5, -4.128]} rotation={[Math.PI / 2, 0.389, Math.PI]}>
            <mesh geometry={nodes.Body1200.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-0.5, -4.5, -4.128]} rotation={[Math.PI / 2, 0.172, Math.PI]}>
            <mesh geometry={nodes.Body1201.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-0.5, -5.5, -4.128]} rotation={[Math.PI / 2, -0.065, Math.PI]}>
            <mesh geometry={nodes.Body1202.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-0.5, -6.5, -4.128]} rotation={[Math.PI / 2, -0.306, Math.PI]}>
            <mesh geometry={nodes.Body1203.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-6.5, -0.5, -4.128]} rotation={[Math.PI / 2, 0.2, Math.PI]}>
            <mesh geometry={nodes.Body1204.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-2.7, -2.908, -5.925]} rotation={[-Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1119.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-2.55, -2.342, -5.925]} rotation={[-Math.PI / 2, 0.424, 0]}>
            <mesh geometry={nodes.Body1191.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-3.188, -2.209, -6.806]} rotation={[-Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1112.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body2007.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-2.21, -2.209, -6.806]} rotation={[-Math.PI / 2, 0.19, 0]}>
            <mesh geometry={nodes.Body1186.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body2011.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-2.21, -3.188, -6.806]} rotation={[-Math.PI / 2, 0.407, 0]}>
            <mesh geometry={nodes.Body1187.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body2012.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-6.25, -2.225, -5.765]} rotation={[0, 0, -Math.PI / 6]}>
            <mesh geometry={nodes.Body1206.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-2.225, -6.25, -5.365]} rotation={[0, 0, Math.PI / 6]}>
            <mesh geometry={nodes.Body1205.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-2.225, -6.25, -4.825]} rotation={[0, 0, -0.341]}>
            <mesh geometry={nodes.Body1125.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-6.25, -2.225, -4.825]}>
            <mesh geometry={nodes.Body1127.geometry} material={materials['170,178,196']} scale={0.394} />
          </group>
          <mesh geometry={nodes.Body1177.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body10001.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body11001.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body12001.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body13001.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body14001.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body15001.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body16001.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body17001.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body2010.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body3003.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body4003.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body5003.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body6003.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body7003.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body8003.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body9001.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[41.123, 8.686, 2.725]} rotation={[0, 0, -Math.PI / 2]}>
          <group position={[-2.225, -6.25, -6.191]} rotation={[Math.PI / 2, Math.PI / 6, -Math.PI]}>
            <mesh geometry={nodes.Body1107.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[-6.25, -2.225, -6.191]} rotation={[Math.PI / 2, -Math.PI / 6, -Math.PI]}>
            <mesh geometry={nodes.Body1108.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[-5.041, -3.278, -3.839]} rotation={[Math.PI / 2, -1.11, Math.PI / 2]}>
            <mesh geometry={nodes.Body1013.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-3.056, -4.879, -5.911]} rotation={[Math.PI / 2, -1.228, Math.PI / 2]}>
            <mesh geometry={nodes.Body1088.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-5.041, -3.278, -5.859]} rotation={[Math.PI / 2, -1.228, Math.PI / 2]}>
            <mesh geometry={nodes.Body1089.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-3.056, -4.879, -3.844]} rotation={[0, 0, -1.76]}>
            <mesh geometry={nodes.Body1014.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-2.625, -2.625, -4.315]} rotation={[0, 0, -0.189]}>
            <mesh geometry={nodes.Body1029.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-2.625, -2.625, -5.213]} rotation={[Math.PI / 2, -1.307, -Math.PI]}>
            <group position={[0, 0.878, 0]}>
              <mesh geometry={nodes.Body1021.geometry} material={materials['64,64,64']} scale={0.394} />
              <mesh geometry={nodes.Body2003.geometry} material={materials['255,223,0']} scale={0.394} />
              <mesh geometry={nodes.Body3.geometry} material={materials['255,223,0']} scale={0.394} />
              <mesh geometry={nodes.Body4.geometry} material={materials['255,223,0']} scale={0.394} />
              <mesh geometry={nodes.Body5.geometry} material={materials['255,223,0']} scale={0.394} />
              <mesh geometry={nodes.Body6.geometry} material={materials['255,223,0']} scale={0.394} />
              <mesh geometry={nodes.Body7.geometry} material={materials['255,223,0']} scale={0.394} />
              <mesh geometry={nodes.Body8.geometry} material={materials['0,0,0']} scale={0.394} />
            </group>
            <mesh geometry={nodes.Body1023.geometry} material={materials['239,239,239']} scale={0.394} />
            <mesh geometry={nodes.Body1022.geometry} material={materials['128,128,128']} scale={0.394} />
            <mesh geometry={nodes.Body1020.geometry} material={materials['239,239,239']} scale={0.394} />
          </group>
          <group position={[-2.225, -6.25, -4.865]} rotation={[-Math.PI / 2, -Math.PI / 6, Math.PI / 2]}>
            <mesh geometry={nodes.Body1026.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-6.25, -2.225, -5.263]} rotation={[-Math.PI / 2, Math.PI / 6, Math.PI / 2]}>
            <mesh geometry={nodes.Body1093.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-5.041, -3.278, -5.507]} rotation={[-Math.PI / 2, 0.168, 0]}>
            <mesh geometry={nodes.Body1015.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-3.056, -4.879, -5.058]} rotation={[Math.PI / 2, -0.27, -Math.PI]}>
            <mesh geometry={nodes.Body1090.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-6.326, -2.356, -5.513]} rotation={[0, 0, 2.618]}>
            <mesh geometry={nodes.Body1028.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-2.149, -6.381, -5.115]} rotation={[0, 0, Math.PI / 6]}>
            <mesh geometry={nodes.Body1094.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-2.625, -2.625, 0]} rotation={[0, 0, 2.356]}>
            <group position={[0, 0, -4.65]} rotation={[0, 0, -0.179]}>
              <mesh geometry={nodes.Body1061.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, 0, -5.454]} rotation={[0, 0, -0.179]}>
              <mesh geometry={nodes.Body1073.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, 0, -5.822]} rotation={[0, 0, -0.179]}>
              <mesh geometry={nodes.Body1074.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -4.121]} rotation={[0, 0, 0.381]}>
              <mesh geometry={nodes.Body1075.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -2.814]} rotation={[0, -1.571, 0]}>
              <mesh geometry={nodes.Body1072.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, 0, -3.75]} rotation={[0, 0, 0.206]}>
              <mesh geometry={nodes.Body1058.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, 0.699, -1.998]} rotation={[-Math.PI / 2, 0, 0.187]}>
              <mesh geometry={nodes.Body1060.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, -0.507, -1.998]} rotation={[-Math.PI / 2, 0, -1.345]}>
              <mesh geometry={nodes.Body1059.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -3.327]} rotation={[-Math.PI, 0, 2.371]}>
              <mesh geometry={nodes.Body1053.geometry} material={materials['53,51,48']} scale={0.394} />
            </group>
            <group position={[0, 0, -4.336]} rotation={[Math.PI / 2, 0.752, Math.PI]}>
              <mesh geometry={nodes.Body1048.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, 0, -4.336]} rotation={[Math.PI / 2, 0.752, Math.PI]}>
              <mesh geometry={nodes.Body1049.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, 0, -4.336]} rotation={[Math.PI / 2, 0.752, Math.PI]}>
              <mesh geometry={nodes.Body1047.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -4.8]} rotation={[-Math.PI / 2, -1.323, -Math.PI / 2]}>
              <mesh geometry={nodes.Body1057.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -4.539]} rotation={[-Math.PI / 2, 0.8, Math.PI / 2]}>
              <mesh geometry={nodes.Body1052.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -4.539]} rotation={[0, 0, 2.341]}>
              <mesh geometry={nodes.Body1051.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -4.539]} rotation={[-Math.PI / 2, -0.77, -Math.PI]}>
              <mesh geometry={nodes.Body1050.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, 0, -5.54]}>
              <mesh geometry={nodes.Body1066.geometry} material={materials['198,193,188']} scale={0.394} />
            </group>
            <group position={[0, 0, -3.875]}>
              <mesh geometry={nodes.Body1068.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            </group>
            <group position={[0, 0, -4.452]}>
              <mesh geometry={nodes.Body1065.geometry} material={materials['170,178,196']} scale={0.394} />
            </group>
            <group position={[0, 0.003, -1.998]} rotation={[-Math.PI, 0.918, Math.PI / 2]}>
              <group position={[0.663, 0, 0]} rotation={[0, -Math.PI / 2, 0]}>
                <mesh geometry={nodes.Body1032.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[0.438, 0.282, 1.601]} rotation={[-1.745, 0.668, Math.PI]}>
                <mesh geometry={nodes.Body1034.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.437, 0.282, 1.601]} rotation={[-1.745, 0.88, -Math.PI]}>
                <mesh geometry={nodes.Body1040.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.437, -0.282, 1.601]} rotation={[1.745, 0.758, 0]}>
                <mesh geometry={nodes.Body1041.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[0.438, -0.282, 1.601]} rotation={[1.745, 0.571, 0]}>
                <mesh geometry={nodes.Body1042.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.437, -1.408, -0.813]} rotation={[-2.618, 0.637, 0]}>
                <mesh geometry={nodes.Body1043.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[0.438, -1.408, -0.813]} rotation={[-2.618, 0.385, 0]}>
                <mesh geometry={nodes.Body1044.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[0.438, 1.408, -0.813]} rotation={[-Math.PI / 6, 1.369, 0]}>
                <mesh geometry={nodes.Body1045.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.437, 1.408, -0.813]} rotation={[-Math.PI / 6, 1.03, 0]}>
                <mesh geometry={nodes.Body1046.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.19, 0.812, 0]} rotation={[2.131, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body1033.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.19, 0.406, 0.704]} rotation={[1.61, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body1035.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.19, -0.406, 0.704]} rotation={[1.737, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body1036.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.19, -0.813, 0]} rotation={[1.831, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body1037.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.19, -0.406, -0.704]} rotation={[1.885, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body1038.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <group position={[-0.19, 0.406, -0.704]} rotation={[1.895, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body1039.geometry} material={materials['76,76,76']} scale={0.394} />
              </group>
              <mesh geometry={nodes.Body1031.geometry} material={materials['76,76,76']} scale={0.394} />
              <mesh geometry={nodes.Body1030.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -2.691]} rotation={[0, 0, 2.341]}>
              <mesh geometry={nodes.Body1067.geometry} material={materials['170,178,196']} scale={0.394} />
            </group>
            <group position={[0, 0.558, -1.998]} rotation={[0, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body1064.geometry} material={materials['170,178,196']} scale={0.394} />
            </group>
            <group position={[-0.8, -1.2, -3.279]} rotation={[-Math.PI / 2, -0.655, Math.PI]}>
              <mesh geometry={nodes.Body1055.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0.8, -1.2, -3.279]} rotation={[-Math.PI / 2, -0.453, -Math.PI]}>
              <mesh geometry={nodes.Body1070.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, 0, -4.969]} rotation={[Math.PI / 2, 0.315, Math.PI]}>
              <mesh geometry={nodes.Body1071.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[-0.8, 1.2, -4]} rotation={[-Math.PI / 2, 0.19, 0]}>
              <mesh geometry={nodes.Body1054.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0.8, 1.2, -4]} rotation={[-Math.PI / 2, 0.379, 0]}>
              <mesh geometry={nodes.Body1069.geometry} material={materials['76,76,76']} scale={0.394} />
            </group>
            <group position={[0, 1.513, -1.998]} rotation={[Math.PI / 2, 0, 1.614]}>
              <mesh geometry={nodes.Body1056.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, -1.103, -2.912]}>
              <mesh geometry={nodes.Body1076.geometry} material={materials['165,158,150']} scale={0.394} />
            </group>
            <group position={[0, 1.2, -1.998]} rotation={[0, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body1063.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            </group>
            <group position={[0, -1.2, -1.998]} rotation={[0, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body1062.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            </group>
          </group>
          <group position={[-5.041, -3.278, -3.938]}>
            <mesh geometry={nodes.Body1018.geometry} material={materials['170,178,196']} scale={0.394} />
          </group>
          <group position={[-3.056, -4.879, -5.307]} rotation={[0, 0, -2.387]}>
            <mesh geometry={nodes.Body1017.geometry} material={materials['170,178,196']} scale={0.394} />
          </group>
          <group position={[-3.056, -4.879, -6.004]} rotation={[0, 0, -0.052]}>
            <mesh geometry={nodes.Body1016.geometry} material={materials['170,178,196']} scale={0.394} />
            <mesh geometry={nodes.Body2002.geometry} material={materials['170,178,196']} scale={0.394} />
          </group>
          <group position={[-6.25, -2.225, -4.688]} rotation={[-Math.PI / 2, Math.PI / 6, 0]}>
            <group scale={0.394}>
              <mesh geometry={nodes.Body1021_1.geometry} material={materials['165,158,150']} />
              <mesh geometry={nodes.Body1021_2.geometry} material={materials['26,26,26']} />
            </group>
          </group>
          <group position={[-2.225, -6.25, -4.688]} rotation={[-Math.PI / 2, -Math.PI / 6, 0]}>
            <group scale={0.394}>
              <mesh geometry={nodes.Body1021_1.geometry} material={materials['165,158,150']} />
              <mesh geometry={nodes.Body1021_2.geometry} material={materials['26,26,26']} />
            </group>
          </group>
          <group position={[-1.663, -4.514, -3.524]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1009.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-4.124, -4.124, -3.524]} rotation={[Math.PI / 2, -0.19, 0]}>
            <mesh geometry={nodes.Body1079.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-4.514, -1.663, -3.524]} rotation={[Math.PI / 2, -0.407, 0]}>
            <mesh geometry={nodes.Body1080.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-2.501, -0.509, -3.524]} rotation={[Math.PI / 2, -0.644, 0]}>
            <mesh geometry={nodes.Body1081.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-0.509, -2.501, -3.524]} rotation={[Math.PI / 2, -0.885, 0]}>
            <mesh geometry={nodes.Body1082.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-3.091, -6.75, -4.373]} rotation={[-Math.PI / 2, 1.252, Math.PI]}>
            <mesh geometry={nodes.Body1010.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-1.725, -7.116, -4.373]} rotation={[1.571, 1.483, 0]}>
            <mesh geometry={nodes.Body1095.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-1.359, -5.75, -4.373]} rotation={[Math.PI / 2, 1.005, 0]}>
            <mesh geometry={nodes.Body1096.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-5.75, -1.359, -4.372]} rotation={[Math.PI / 2, 0.779, 0]}>
            <mesh geometry={nodes.Body1097.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-7.116, -1.725, -4.373]} rotation={[Math.PI / 2, 0.439, 0]}>
            <mesh geometry={nodes.Body1098.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-6.75, -3.091, -4.373]} rotation={[Math.PI / 2, 0.319, 0]}>
            <mesh geometry={nodes.Body1099.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-4.902, -4.902, -3.623]} rotation={[-Math.PI / 2, 0.722, Math.PI]}>
            <mesh geometry={nodes.Body1011.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-1.285, -4.5, -3.623]} rotation={[-Math.PI / 2, 0.912, Math.PI]}>
            <mesh geometry={nodes.Body1083.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-4.5, -1.285, -3.623]} rotation={[-Math.PI / 2, 1.129, Math.PI]}>
            <mesh geometry={nodes.Body1084.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-0.5, -0.5, -3.878]} rotation={[-Math.PI / 2, 0.442, 0]}>
            <mesh geometry={nodes.Body1085.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-4.5, -0.5, -4.128]} rotation={[Math.PI / 2, 0.579, -Math.PI]}>
            <mesh geometry={nodes.Body1078.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-5.5, -0.5, -4.128]} rotation={[Math.PI / 2, 0.389, -Math.PI]}>
            <mesh geometry={nodes.Body1100.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-0.5, -4.5, -4.128]} rotation={[Math.PI / 2, 0.172, -Math.PI]}>
            <mesh geometry={nodes.Body1101.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-0.5, -5.5, -4.128]} rotation={[Math.PI / 2, -0.065, -Math.PI]}>
            <mesh geometry={nodes.Body1102.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-0.5, -6.5, -4.128]} rotation={[Math.PI / 2, -0.306, -Math.PI]}>
            <mesh geometry={nodes.Body1103.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-6.5, -0.5, -4.128]} rotation={[Math.PI / 2, 0.2, -Math.PI]}>
            <mesh geometry={nodes.Body1104.geometry} material={materials['76,76,76']} scale={0.394} />
          </group>
          <group position={[-2.7, -2.908, -5.925]} rotation={[-Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1019.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-2.55, -2.342, -5.925]} rotation={[-Math.PI / 2, 0.424, 0]}>
            <mesh geometry={nodes.Body1091.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-3.188, -2.209, -6.806]} rotation={[-Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.Body1012.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body2001.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-2.21, -2.209, -6.806]} rotation={[-Math.PI / 2, 0.19, 0]}>
            <mesh geometry={nodes.Body1086.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body2005.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-2.21, -3.188, -6.806]} rotation={[-Math.PI / 2, 0.407, 0]}>
            <mesh geometry={nodes.Body1087.geometry} material={materials['76,76,76']} scale={0.394} />
            <mesh geometry={nodes.Body2006.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-6.25, -2.225, -5.765]} rotation={[0, 0, -Math.PI / 6]}>
            <mesh geometry={nodes.Body1106.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-2.225, -6.25, -5.365]} rotation={[0, 0, Math.PI / 6]}>
            <mesh geometry={nodes.Body1105.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[-2.225, -6.25, -4.825]} rotation={[0, 0, -0.341]}>
            <mesh geometry={nodes.Body1025.geometry} material={materials['165,158,150']} scale={0.394} />
          </group>
          <group position={[-6.25, -2.225, -4.825]}>
            <mesh geometry={nodes.Body1027.geometry} material={materials['170,178,196']} scale={0.394} />
          </group>
          <mesh geometry={nodes.Body1077.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body10.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body11.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body12.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body13.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body14.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body15.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body16.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body17.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body2004.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body3001.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body4001.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body5001.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body6001.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body7001.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body8001.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          <mesh geometry={nodes.Body9.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
        </group>
        <group position={[15.123, 12.686, 0.975]}>
          <mesh geometry={nodes.Body1001.geometry} material={materials['Millennium Falcon Green']} scale={0.394} />
        </group>
        <group position={[40.123, 12.686, 0.975]}>
          <mesh geometry={nodes.Body1002.geometry} material={materials['Millennium Falcon Green']} scale={0.394} />
        </group>
        <group rotation={[-Math.PI, 0, -0.087]}>
          <group position={[37.599, -12.43, 23.71]} rotation={[1.396, 0.086, 0.015]}>
            <mesh geometry={nodes.Body1934.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[20.103, -13.952, 23.759]} rotation={[1.396, 0.086, 0.015]}>
            <mesh geometry={nodes.Body1938.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[37.101, -12.474, 23.71]} rotation={[1.396, 0.086, 0.015]}>
            <mesh geometry={nodes.Body1937.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[38.187, -12.553, 22.725]} rotation={[1.396, 0.086, -3.126]}>
            <mesh geometry={nodes.Body1952.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[20.604, -14.091, 22.725]} rotation={[1.396, 0.086, -3.126]}>
            <mesh geometry={nodes.Body1951.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[36.603, -12.517, 23.71]} rotation={[1.396, 0.086, 0.015]}>
            <mesh geometry={nodes.Body1935.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[24.068, -18.043, 5.112]} rotation={[Math.PI / 2, -1.484, -2.967]}>
            <mesh geometry={nodes.Body1969.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[23.194, -19.884, 3.474]} rotation={[-1.746, -0.086, -0.015]}>
            <mesh geometry={nodes.Body1980.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[24.189, -19.431, 5.357]} rotation={[Math.PI / 2, -1.484, -2.967]}>
            <mesh geometry={nodes.Body1973.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[24.19, -19.797, 3.474]} rotation={[-1.746, -0.086, -0.015]}>
            <mesh geometry={nodes.Body1977.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[22.158, -13.781, 23.71]} rotation={[1.396, 0.086, 0.015]}>
            <mesh geometry={nodes.Body1936.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[23.692, -19.84, 3.474]} rotation={[-1.746, -0.086, -0.015]}>
            <mesh geometry={nodes.Body1978.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[24.046, -17.799, 6.504]} rotation={[Math.PI / 2, -1.484, -2.967]}>
            <mesh geometry={nodes.Body1968.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[24.168, -19.186, 6.75]} rotation={[Math.PI / 2, -1.484, -2.967]}>
            <mesh geometry={nodes.Body1971.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[22.696, -19.927, 3.474]} rotation={[-1.746, -0.086, -0.015]}>
            <mesh geometry={nodes.Body1972.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[38.657, -12.329, 23.759]} rotation={[1.396, 0.086, 0.015]}>
            <mesh geometry={nodes.Body1941.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[38.035, -12.392, 23.71]} rotation={[1.396, 0.086, 0.015]}>
            <mesh geometry={nodes.Body1940.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[21.162, -13.868, 23.71]} rotation={[1.396, 0.086, 0.015]}>
            <mesh geometry={nodes.Body1943.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[20.726, -13.906, 23.71]} rotation={[1.396, 0.086, 0.015]}>
            <mesh geometry={nodes.Body1939.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[21.66, -13.825, 23.71]} rotation={[1.396, 0.086, 0.015]}>
            <mesh geometry={nodes.Body1942.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[22.295, -21.084, 3.671]} rotation={[-0.174, -0.015, 0.086]}>
            <mesh geometry={nodes.Body1944.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[37.736, -19.733, 3.671]} rotation={[-0.174, -0.015, 0.086]}>
            <mesh geometry={nodes.Body1946.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[37.311, -14.871, 2.811]} rotation={[2.968, 0.015, -0.086]}>
            <mesh geometry={nodes.Body1947.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[21.87, -16.222, 2.811]} rotation={[2.968, 0.015, -0.086]}>
            <mesh geometry={nodes.Body1945.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[39.375, -15.048, 10.743]} rotation={[-0.174, -0.015, 1.657]}>
            <mesh geometry={nodes.Body1961.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[39.481, -16.26, 12.481]} rotation={[-0.174, -0.015, 1.657]}>
            <mesh geometry={nodes.Body1962.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[39.503, -16.519, 11.003]} rotation={[-0.174, -0.015, 1.657]}>
            <mesh geometry={nodes.Body1960.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[39.428, -15.654, 11.612]} rotation={[-0.174, -0.015, 1.657]}>
            <mesh geometry={nodes.Body1954.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[19.921, -17.36, 11.612]} rotation={[2.968, 0.015, -1.657]}>
            <mesh geometry={nodes.Body1955.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[19.868, -16.754, 10.743]} rotation={[2.968, 0.015, -1.657]}>
            <mesh geometry={nodes.Body1956.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[19.845, -16.495, 12.22]} rotation={[2.968, 0.015, -1.657]}>
            <mesh geometry={nodes.Body1963.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[19.974, -17.966, 12.481]} rotation={[2.968, 0.015, -1.657]}>
            <mesh geometry={nodes.Body1958.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[19.997, -18.226, 11.003]} rotation={[2.968, 0.015, -1.657]}>
            <mesh geometry={nodes.Body1959.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[39.352, -14.788, 12.22]} rotation={[-0.174, -0.015, 1.657]}>
            <mesh geometry={nodes.Body1957.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[22.63, -19.567, 5.357]} rotation={[2.968, 0.015, -1.657]}>
            <mesh geometry={nodes.Body1964.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[22.487, -17.935, 6.504]} rotation={[2.968, 0.015, -1.657]}>
            <mesh geometry={nodes.Body1970.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[22.609, -19.322, 6.75]} rotation={[2.968, 0.015, -1.657]}>
            <mesh geometry={nodes.Body1966.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[22.509, -18.18, 5.112]} rotation={[2.968, 0.015, -1.657]}>
            <mesh geometry={nodes.Body1967.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[20.887, -15.751, 24.037]} rotation={[-0.174, -0.015, 0.086]}>
            <mesh geometry={nodes.Body1924.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[38.818, -14.173, 24.086]} rotation={[-0.174, -0.015, 0.086]}>
            <mesh geometry={nodes.Body1932.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[20.766, -15.936, 23.052]} rotation={[-0.174, -0.015, 0.086]}>
            <mesh geometry={nodes.Body1950.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[38.348, -14.398, 23.052]} rotation={[-0.174, -0.015, 0.086]}>
            <mesh geometry={nodes.Body1953.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[21.323, -15.713, 24.037]} rotation={[-0.174, -0.015, 0.086]}>
            <mesh geometry={nodes.Body1926.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[21.821, -15.669, 24.037]} rotation={[-0.174, -0.015, 0.086]}>
            <mesh geometry={nodes.Body1925.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[22.319, -15.626, 24.037]} rotation={[-0.174, -0.015, 0.086]}>
            <mesh geometry={nodes.Body1933.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[20.264, -15.797, 24.086]} rotation={[-0.174, -0.015, 0.086]}>
            <mesh geometry={nodes.Body1928.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[37.262, -14.318, 24.037]} rotation={[-0.174, -0.015, 0.086]}>
            <mesh geometry={nodes.Body1929.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[36.764, -14.362, 24.037]} rotation={[-0.174, -0.015, 0.086]}>
            <mesh geometry={nodes.Body1931.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[37.76, -14.275, 24.037]} rotation={[-0.174, -0.015, 0.086]}>
            <mesh geometry={nodes.Body1930.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[38.196, -14.237, 24.037]} rotation={[-0.174, -0.015, 0.086]}>
            <mesh geometry={nodes.Body1927.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[22.556, -18.328, 3.191]} rotation={[2.968, 0.015, -0.086]}>
            <mesh geometry={nodes.Body1965.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[23.054, -18.284, 3.191]} rotation={[2.968, 0.015, -0.086]}>
            <mesh geometry={nodes.Body1976.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[24.05, -18.197, 3.191]} rotation={[2.968, 0.015, -0.086]}>
            <mesh geometry={nodes.Body1975.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[23.552, -18.241, 3.191]} rotation={[2.968, 0.015, -0.086]}>
            <mesh geometry={nodes.Body1981.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[38.517, -17.186, 2.474]} rotation={[-0.174, -0.015, -3.056]}>
            <mesh geometry={nodes.Body1982.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[37.946, -15.783, 11.612]} rotation={[-Math.PI / 2, 1.484, -1.745]}>
            <mesh geometry={nodes.Body1948.geometry} material={materials['Opaque(221,232,255)']} scale={0.394} />
          </group>
          <group position={[21.403, -17.231, 11.612]} rotation={[Math.PI / 2, -1.484, 1.745]}>
            <mesh geometry={nodes.Body1949.geometry} material={materials['Opaque(221,232,255)']} scale={0.394} />
          </group>
          <group position={[22.306, -18.773, 5.931]} rotation={[2.968, 0.015, -1.657]}>
            <mesh geometry={nodes.Body1979.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            <mesh geometry={nodes.Body2072.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[24.149, -18.612, 5.931]} rotation={[0.348, 0.03, 1.653]}>
            <mesh geometry={nodes.Body1974.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            <mesh geometry={nodes.Body2071.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
        </group>
        <group rotation={[-Math.PI, 0, -0.087]}>
          <group position={[37.419, -17.064, 5.355]} rotation={[2.968, 0.015, -0.086]}>
            <mesh geometry={nodes.Body11044.geometry} material={materials['Steel - Satin']} scale={0.394} />
          </group>
          <group position={[37.566, -16.99, 5.344]} rotation={[2.445, 0.056, 1.504]}>
            <mesh geometry={nodes.Body1983.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[40.088, -17.217, 5.931]} rotation={[-2.794, -0.03, -0.082]}>
            <mesh geometry={nodes.Body11099.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
          </group>
          <group position={[37.878, -16.962, 5.344]} rotation={[-0.696, -0.056, 1.638]}>
            <mesh geometry={nodes.Body11043.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            <mesh geometry={nodes.Body2075.geometry} material={materials['Coating - Black Oxide.001']} scale={0.394} />
          </group>
          <group position={[23.06, -18.707, 5.931]} rotation={[2.968, 0.015, 1.485]}>
            <mesh geometry={nodes.Body11047.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
          </group>
          <group position={[22.481, -18.758, 5.931]} rotation={[2.968, 0.015, -1.657]}>
            <mesh geometry={nodes.Body11046.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[22.501, -18.756, 5.931]} rotation={[2.968, 0.015, -0.086]}>
            <mesh geometry={nodes.Body11045.geometry} material={materials['Rubber - Soft']} scale={0.394} />
          </group>
          <group position={[0, 0, 16]} rotation={[-Math.PI, 0, 1.396]}>
            <group position={[23.325, -20.256, 10.11]} rotation={[3.126, -0.174, -0.089]}>
              <mesh geometry={nodes.Body11073.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
            </group>
            <group position={[23.388, -20.25, 9.971]} rotation={[-0.015, 0.174, -3.053]}>
              <mesh geometry={nodes.Body11063.geometry} material={materials['Opaque(252,188,132)']} scale={0.394} />
            </group>
            <group position={[23.22, -20.213, 10.054]} rotation={[-0.015, 0.174, 0.089]}>
              <mesh geometry={nodes.Body11093.geometry} material={materials['Opaque(252,188,132)']} scale={0.394} />
            </group>
            <group position={[23.488, -20.241, 9.712]} rotation={[-2.683, 1.376, -0.467]}>
              <mesh geometry={nodes.Body11080.geometry} material={materials['Opaque(252,188,132)']} scale={0.394} />
            </group>
            <group position={[23.466, -20.243, 9.895]} rotation={[-2.683, 1.376, -0.467]}>
              <mesh geometry={nodes.Body11081.geometry} material={materials['Opaque(252,188,132)']} scale={0.394} />
            </group>
            <group position={[23.152, -20.271, 9.887]} rotation={[3.126, -0.174, -0.089]}>
              <mesh geometry={nodes.Body11082.geometry} material={materials['Opaque(252,188,132)']} scale={0.394} />
            </group>
            <group position={[23.319, -20.256, 9.985]} rotation={[-0.015, 0.174, -3.053]}>
              <mesh geometry={nodes.Body11083.geometry} material={materials['Opaque(252,188,132)']} scale={0.394} />
            </group>
            <group position={[23.261, -20.261, 9.701]} rotation={[-0.015, 0.174, -3.053]}>
              <mesh geometry={nodes.Body11084.geometry} material={materials['Opaque(252,188,132)']} scale={0.394} />
            </group>
            <group position={[23.231, -20.264, 10.129]} rotation={[-2.683, 1.376, -0.467]}>
              <mesh geometry={nodes.Body11085.geometry} material={materials['Opaque(252,188,132)']} scale={0.394} />
            </group>
            <group position={[23.385, -20.25, 10.185]} rotation={[-0.015, 0.174, -3.053]}>
              <mesh geometry={nodes.Body11086.geometry} material={materials['Opaque(252,188,132)']} scale={0.394} />
            </group>
            <group position={[23.484, -20.19, 10.111]} rotation={[3.126, -0.174, 3.053]}>
              <mesh geometry={nodes.Body11092.geometry} material={materials['Opaque(252,188,132)']} scale={0.394} />
            </group>
            <group position={[23.556, -20.235, 10.055]} rotation={[-2.683, 1.376, -0.467]}>
              <group scale={0.394}>
                <mesh geometry={nodes.Body1333_1.geometry} material={materials['Opaque(64,64,64)']} />
                <mesh geometry={nodes.Body1333_2.geometry} material={materials['Opaque(192,192,192)']} />
              </group>
            </group>
            <group position={[23.44, -20.246, 10.017]} rotation={[-0.015, 0.174, -3.053]}>
              <group scale={0.394}>
                <mesh geometry={nodes.Body1333_1.geometry} material={materials['Opaque(64,64,64)']} />
                <mesh geometry={nodes.Body1333_2.geometry} material={materials['Opaque(192,192,192)']} />
              </group>
            </group>
            <group position={[23.743, -20.167, 9.945]} rotation={[-2.683, 1.376, 2.675]}>
              <group scale={0.394}>
                <mesh geometry={nodes.Body1333_1.geometry} material={materials['Opaque(64,64,64)']} />
                <mesh geometry={nodes.Body1333_2.geometry} material={materials['Opaque(192,192,192)']} />
              </group>
            </group>
            <group position={[23.774, -20.216, 10.014]} rotation={[-2.683, 1.376, -0.467]}>
              <group scale={0.394}>
                <mesh geometry={nodes.Body1334_1.geometry} material={materials['Opaque(192,192,192)']} />
                <mesh geometry={nodes.Body1334_2.geometry} material={materials['Opaque(64,64,64)']} />
              </group>
            </group>
            <group position={[23.383, -20.198, 9.913]} rotation={[-2.683, 1.376, -0.467]}>
              <mesh geometry={nodes.Body11061.geometry} material={materials['Opaque(0,128,0)']} scale={0.394} />
              <mesh geometry={nodes.Body2077.geometry} material={materials['Opaque(255,223,0)']} scale={0.394} />
              <mesh geometry={nodes.Body3037.geometry} material={materials['Opaque(255,223,0)']} scale={0.394} />
              <mesh geometry={nodes.Body4028.geometry} material={materials['Opaque(255,223,0)']} scale={0.394} />
              <mesh geometry={nodes.Body5028.geometry} material={materials['Opaque(255,223,0)']} scale={0.394} />
              <mesh geometry={nodes.Body6028.geometry} material={materials['Opaque(255,223,0)']} scale={0.394} />
              <mesh geometry={nodes.Body7028.geometry} material={materials['Opaque(255,223,0)']} scale={0.394} />
            </group>
            <group position={[23.585, -20.233, 9.704]} rotation={[0.459, -1.376, -2.675]}>
              <mesh geometry={nodes.Body11067.geometry} material={materials['Opaque(49,49,49)']} scale={0.394} />
              <mesh geometry={nodes.Body10024.geometry} material={materials['Opaque(184,184,184)']} scale={0.394} />
              <mesh geometry={nodes.Body11024.geometry} material={materials['Opaque(149,149,149)']} scale={0.394} />
              <mesh geometry={nodes.Body2078.geometry} material={materials['Opaque(149,149,149)']} scale={0.394} />
              <mesh geometry={nodes.Body3038.geometry} material={materials['Opaque(184,184,184)']} scale={0.394} />
              <mesh geometry={nodes.Body4029.geometry} material={materials['Opaque(184,184,184)']} scale={0.394} />
              <mesh geometry={nodes.Body5029.geometry} material={materials['Opaque(184,184,184)']} scale={0.394} />
              <mesh geometry={nodes.Body6029.geometry} material={materials['Opaque(184,184,184)']} scale={0.394} />
              <mesh geometry={nodes.Body7029.geometry} material={materials['Opaque(184,184,184)']} scale={0.394} />
              <mesh geometry={nodes.Body8028.geometry} material={materials['Opaque(184,184,184)']} scale={0.394} />
              <mesh geometry={nodes.Body9024.geometry} material={materials['Opaque(184,184,184)']} scale={0.394} />
            </group>
            <group position={[23.135, -20.272, 9.957]} rotation={[3.126, -0.174, -0.089]}>
              <group scale={0.394}>
                <mesh geometry={nodes.Body1339_1.geometry} material={materials['Opaque(255,255,255)']} />
                <mesh geometry={nodes.Body1339_2.geometry} material={materials['Opaque(76,76,76)']} />
              </group>
            </group>
            <group position={[23.796, -20.162, 10.017]} rotation={[3.126, -0.174, 3.053]}>
              <group scale={0.394}>
                <mesh geometry={nodes.Body1346_1.geometry} material={materials['Opaque(228,233,237)']} />
                <mesh geometry={nodes.Body1346_2.geometry} material={materials['Opaque(76,76,76)']} />
              </group>
              <group scale={0.394}>
                <mesh geometry={nodes.Body2027_1.geometry} material={materials['Opaque(228,233,237)']} />
                <mesh geometry={nodes.Body2027_2.geometry} material={materials['Opaque(76,76,76)']} />
              </group>
              <group scale={0.394}>
                <mesh geometry={nodes.Body3019_1.geometry} material={materials['Opaque(76,76,76)']} />
                <mesh geometry={nodes.Body3019_2.geometry} material={materials['Opaque(255,255,255)']} />
                <mesh geometry={nodes.Body3019_3.geometry} material={materials['Opaque(215,208,192)']} />
              </group>
            </group>
            <group position={[23.187, -20.268, 10.052]} rotation={[3.126, -0.174, -0.089]}>
              <group scale={0.394}>
                <mesh geometry={nodes.Body1341_1.geometry} material={materials['Opaque(64,64,64)']} />
                <mesh geometry={nodes.Body1341_2.geometry} material={materials['Opaque(203,210,239)']} />
              </group>
            </group>
            <group position={[23.181, -20.268, 10.014]} rotation={[3.126, -0.174, -0.089]}>
              <group scale={0.394}>
                <mesh geometry={nodes.Body1341_1.geometry} material={materials['Opaque(64,64,64)']} />
                <mesh geometry={nodes.Body1341_2.geometry} material={materials['Opaque(203,210,239)']} />
              </group>
            </group>
            <group position={[23.479, -20.242, 9.799]} rotation={[3.126, -0.174, -0.089]}>
              <group scale={0.394}>
                <mesh geometry={nodes.Body1341_1.geometry} material={materials['Opaque(64,64,64)']} />
                <mesh geometry={nodes.Body1341_2.geometry} material={materials['Opaque(203,210,239)']} />
              </group>
            </group>
            <group position={[23.201, -20.214, 9.842]} rotation={[-0.015, 0.174, 0.089]}>
              <group scale={0.394}>
                <mesh geometry={nodes.Body1341_1.geometry} material={materials['Opaque(64,64,64)']} />
                <mesh geometry={nodes.Body1341_2.geometry} material={materials['Opaque(203,210,239)']} />
              </group>
            </group>
            <group position={[23.124, -20.221, 9.961]} rotation={[-2.683, 1.376, 2.675]}>
              <group scale={0.394}>
                <mesh geometry={nodes.Body1341_1.geometry} material={materials['Opaque(64,64,64)']} />
                <mesh geometry={nodes.Body1341_2.geometry} material={materials['Opaque(203,210,239)']} />
              </group>
            </group>
            <group position={[23.583, -20.181, 10.014]} rotation={[-0.015, 0.174, 0.089]}>
              <group scale={0.394}>
                <mesh geometry={nodes.Body1341_1.geometry} material={materials['Opaque(64,64,64)']} />
                <mesh geometry={nodes.Body1341_2.geometry} material={materials['Opaque(203,210,239)']} />
              </group>
            </group>
            <group position={[23.656, -20.174, 10.001]} rotation={[-0.015, 0.174, 0.089]}>
              <group scale={0.394}>
                <mesh geometry={nodes.Body1341_1.geometry} material={materials['Opaque(64,64,64)']} />
                <mesh geometry={nodes.Body1341_2.geometry} material={materials['Opaque(203,210,239)']} />
              </group>
            </group>
            <group position={[23.659, -20.174, 10.048]} rotation={[-0.015, 0.174, 0.089]}>
              <group scale={0.394}>
                <mesh geometry={nodes.Body1343_1.geometry} material={materials['Opaque(192,192,192)']} />
                <mesh geometry={nodes.Body1343_2.geometry} material={materials['Opaque(64,64,64)']} />
              </group>
            </group>
            <group position={[23.662, -20.226, 10.042]} rotation={[0.459, -1.376, -2.675]}>
              <group scale={0.394}>
                <mesh geometry={nodes.Body1338_1.geometry} material={materials['Opaque(58,43,5)']} />
                <mesh geometry={nodes.Body1338_2.geometry} material={materials['Opaque(192,192,192)']} />
              </group>
            </group>
            <group position={[23.199, -20.21, 9.946]} rotation={[3.126, -0.174, 3.053]}>
              <group scale={0.394}>
                <mesh geometry={nodes.Body1345_1.geometry} material={materials['Opaque(63,63,63)']} />
                <mesh geometry={nodes.Body1345_2.geometry} material={materials['Opaque(192,192,192)']} />
              </group>
            </group>
            <group position={[23.471, -20.243, 10.106]} rotation={[3.126, -0.174, -0.089]}>
              <group scale={0.394}>
                <mesh geometry={nodes.Body1337_1.geometry} material={materials['Opaque(28,28,28)']} />
                <mesh geometry={nodes.Body1337_2.geometry} material={materials['Opaque(196,196,196)']} />
                <mesh geometry={nodes.Body1337_3.geometry} material={materials['Opaque(255,255,255)']} />
              </group>
            </group>
            <group position={[23.751, -20.218, 9.676]} rotation={[0.459, -1.376, -2.675]}>
              <group scale={0.394}>
                <mesh geometry={nodes.Body1337_1.geometry} material={materials['Opaque(28,28,28)']} />
                <mesh geometry={nodes.Body1337_2.geometry} material={materials['Opaque(196,196,196)']} />
                <mesh geometry={nodes.Body1337_3.geometry} material={materials['Opaque(255,255,255)']} />
              </group>
            </group>
            <group position={[23.383, -20.198, 9.913]} rotation={[3.126, -0.174, 3.053]}>
              <mesh geometry={nodes.Body11075.geometry} material={materials['Opaque(40,40,40)']} scale={0.394} />
              <mesh geometry={nodes.Body10025.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              <mesh geometry={nodes.Body11025.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              <mesh geometry={nodes.Body12024.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              <mesh geometry={nodes.Body13023.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              <mesh geometry={nodes.Body14023.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              <mesh geometry={nodes.Body15022.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              <mesh geometry={nodes.Body16022.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              <mesh geometry={nodes.Body17018.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              <mesh geometry={nodes.Body2079.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              <mesh geometry={nodes.Body3039.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              <mesh geometry={nodes.Body4030.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              <mesh geometry={nodes.Body5030.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              <mesh geometry={nodes.Body6030.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              <mesh geometry={nodes.Body7030.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              <mesh geometry={nodes.Body8029.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              <mesh geometry={nodes.Body9025.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
            </group>
            <group position={[23.128, -20.273, 9.809]} rotation={[-2.683, 1.376, -0.467]}>
              <mesh geometry={nodes.Body11071.geometry} material={materials['Opaque(255,215,0)']} scale={0.394} />
            </group>
            <group position={[23.104, -20.275, 9.901]} rotation={[-2.683, 1.376, -0.467]}>
              <mesh geometry={nodes.Body11088.geometry} material={materials['Opaque(255,215,0)']} scale={0.394} />
            </group>
            <group position={[23.476, -20.242, 9.841]} rotation={[-2.683, 1.376, -0.467]}>
              <mesh geometry={nodes.Body11062.geometry} material={materials['Opaque(255,215,0)']} scale={0.394} />
            </group>
            <group position={[23.521, -20.238, 9.955]} rotation={[-2.683, 1.376, -0.467]}>
              <mesh geometry={nodes.Body11078.geometry} material={materials['Opaque(255,215,0)']} scale={0.394} />
            </group>
            <group position={[23.693, -20.223, 9.955]} rotation={[-2.683, 1.376, -0.467]}>
              <mesh geometry={nodes.Body11079.geometry} material={materials['Opaque(255,215,0)']} scale={0.394} />
            </group>
            <group position={[23.298, -20.258, 9.835]} rotation={[-0.015, 0.174, -3.053]}>
              <mesh geometry={nodes.Body11066.geometry} material={materials['Opaque(220,150,0)']} scale={0.394} />
            </group>
          </group>
          <group position={[34.443, -18.441, 4.316]} rotation={[2.968, 0.015, -1.657]}>
            <group position={[1.684, -0.2, -0.51]} rotation={[-Math.PI, -1.004, 0]}>
              <mesh geometry={nodes.Body1998.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
            </group>
            <group position={[0.27, -0.2, -1.924]} rotation={[-Math.PI, -1.163, 0]}>
              <mesh geometry={nodes.Body1999.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
            </group>
            <group position={[1.684, 2.425, -1.924]} rotation={[0, 1.471, Math.PI]}>
              <mesh geometry={nodes.Body11000.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
            </group>
            <group position={[0.27, 2.425, -0.51]} rotation={[-Math.PI, 1.512, 0]}>
              <mesh geometry={nodes.Body11001_1.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
            </group>
            <group position={[-4.508, 5.025, -2.092]} rotation={[0, -0.049, Math.PI]}>
              <group position={[-6.039, 3.386, 0.607]} rotation={[-Math.PI, -0.393, -Math.PI / 2]}>
                <mesh geometry={nodes.Body11009_1.geometry} material={materials['Opaque(198,193,188)']} scale={0.394} />
              </group>
              <group position={[-5.522, 3.386, 0.088]} rotation={[Math.PI, 1.178, -Math.PI / 2]}>
                <mesh geometry={nodes.Body11010_1.geometry} material={materials['Opaque(198,193,188)']} scale={0.394} />
              </group>
              <group position={[-5.003, 3.386, 0.605]} rotation={[0, 0.393, Math.PI / 2]}>
                <mesh geometry={nodes.Body11011_1.geometry} material={materials['Opaque(198,193,188)']} scale={0.394} />
              </group>
              <group position={[-5.52, 3.386, 1.123]} rotation={[0, -1.178, Math.PI / 2]}>
                <mesh geometry={nodes.Body11012_1.geometry} material={materials['Opaque(198,193,188)']} scale={0.394} />
              </group>
              <group position={[-5.521, 2.913, 0.606]}>
                <mesh geometry={nodes.Body11008_1.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              </group>
            </group>
            <group position={[0.977, 1.367, -1.217]} rotation={[0, -1.018, Math.PI]}>
              <group position={[-0.552, 0.579, 0.146]} rotation={[-Math.PI, -0.393, -Math.PI / 2]}>
                <mesh geometry={nodes.Body11003_1.geometry} material={materials['Opaque(198,193,188)']} scale={0.394} />
              </group>
              <group position={[-0.028, 0.579, -0.348]} rotation={[-Math.PI, -0.552, -Math.PI / 2]}>
                <mesh geometry={nodes.Body11004_1.geometry} material={materials['Opaque(198,193,188)']} scale={0.394} />
              </group>
              <group position={[0.492, 0.579, 0.155]} rotation={[-Math.PI, -0.68, -Math.PI / 2]}>
                <mesh geometry={nodes.Body11005_1.geometry} material={materials['Opaque(198,193,188)']} scale={0.394} />
              </group>
              <group position={[0.006, 0.579, 0.655]} rotation={[-Math.PI, -0.773, -Math.PI / 2]}>
                <mesh geometry={nodes.Body11006_1.geometry} material={materials['Opaque(198,193,188)']} scale={0.394} />
              </group>
              <group position={[-0.003, -0.162, -0.021]} rotation={[0, 0.433, -Math.PI / 2]}>
                <mesh geometry={nodes.Body11007_1.geometry} material={materials['Opaque(198,193,188)']} scale={0.394} />
              </group>
              <mesh geometry={nodes.Body11002_1.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
            </group>
            <group position={[0.969, 0.445, -1.236]} rotation={[0, 0.124, Math.PI / 2]}>
              <mesh geometry={nodes.Body11013_1.geometry} material={materials['Opaque(198,193,188)']} scale={0.394} />
            </group>
            <group position={[0.727, -1.454, -1.2]} rotation={[0, -1.309, 0]}>
              <group position={[-0.054, -1.405, -0.718]} rotation={[0, -0.442, Math.PI]}>
                <mesh geometry={nodes.Body11034_1.geometry} material={materials['Opaque(165,158,150)']} scale={0.394} />
              </group>
              <group position={[0.509, -1.405, -0.393]} rotation={[0, -0.125, -Math.PI]}>
                <mesh geometry={nodes.Body11035.geometry} material={materials['Opaque(165,158,150)']} scale={0.394} />
              </group>
              <group position={[0.049, 1.376, -1.246]}>
                <mesh geometry={nodes.Body11036.geometry} material={materials['Opaque(192,192,255)']} scale={0.394} />
              </group>
              <group position={[1.126, 0.423, -0.246]} rotation={[-1.179, 0, -Math.PI / 2]}>
                <mesh geometry={nodes.Body11037.geometry} material={materials['Opaque(192,192,255)']} scale={0.394} />
              </group>
              <group position={[0.915, 1.376, -0.746]} rotation={[0, -Math.PI / 3, 0]}>
                <mesh geometry={nodes.Body11038.geometry} material={materials['Opaque(192,192,255)']} scale={0.394} />
              </group>
              <group position={[0.915, 1.376, 0.254]} rotation={[Math.PI, -Math.PI / 3, Math.PI]}>
                <mesh geometry={nodes.Body11039.geometry} material={materials['Opaque(192,192,255)']} scale={0.394} />
              </group>
              <group position={[0.049, 1.376, 0.754]} rotation={[Math.PI, 0, Math.PI]}>
                <mesh geometry={nodes.Body11040.geometry} material={materials['Opaque(192,192,255)']} scale={0.394} />
              </group>
              <group position={[-0.817, 1.376, 0.254]} rotation={[-Math.PI, Math.PI / 3, -Math.PI]}>
                <mesh geometry={nodes.Body11041.geometry} material={materials['Opaque(192,192,255)']} scale={0.394} />
              </group>
              <group position={[-0.817, 1.376, -0.746]} rotation={[0, Math.PI / 3, 0]}>
                <mesh geometry={nodes.Body11042.geometry} material={materials['Opaque(192,192,255)']} scale={0.394} />
              </group>
              <group position={[0.049, 1.218, -0.246]} rotation={[0, Math.PI / 3, 0]}>
                <mesh geometry={nodes.Body11025_1.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <group position={[0.049, 0.423, -0.246]} rotation={[0, -1.571, 0]}>
                <mesh geometry={nodes.Body11018_1.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <group position={[0.049, 1.474, -0.246]}>
                <mesh geometry={nodes.Body11026_1.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
                <mesh geometry={nodes.Body10022.geometry} material={materials['Opaque(202,209,238)']} scale={0.394} />
                <mesh geometry={nodes.Body11022.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
                <mesh geometry={nodes.Body12022.geometry} material={materials['Opaque(202,209,238)']} scale={0.394} />
                <mesh geometry={nodes.Body13021.geometry} material={materials['Opaque(202,209,238)']} scale={0.394} />
                <mesh geometry={nodes.Body14021.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
                <mesh geometry={nodes.Body15020.geometry} material={materials['Opaque(202,209,238)']} scale={0.394} />
                <mesh geometry={nodes.Body16020.geometry} material={materials['Opaque(202,209,238)']} scale={0.394} />
                <mesh geometry={nodes.Body2074.geometry} material={materials['Opaque(202,209,238)']} scale={0.394} />
                <mesh geometry={nodes.Body3035.geometry} material={materials['Opaque(202,209,238)']} scale={0.394} />
                <mesh geometry={nodes.Body4026.geometry} material={materials['Opaque(202,209,238)']} scale={0.394} />
                <mesh geometry={nodes.Body5026.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
                <mesh geometry={nodes.Body6026.geometry} material={materials['Opaque(202,209,238)']} scale={0.394} />
                <mesh geometry={nodes.Body7026.geometry} material={materials['Opaque(202,209,238)']} scale={0.394} />
                <mesh geometry={nodes.Body8026.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
                <mesh geometry={nodes.Body9022.geometry} material={materials['Opaque(202,209,238)']} scale={0.394} />
              </group>
              <group position={[0.049, 1.379, -0.246]}>
                <mesh geometry={nodes.Body11024_1.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <group position={[0.278, -1.51, -0.644]} rotation={[-Math.PI / 2, 0, -0.524]}>
                <mesh geometry={nodes.Body11033_1.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
              </group>
              <group position={[0.049, -0.589, -0.246]}>
                <mesh geometry={nodes.Body11015_1.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body10021.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body100002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body101002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body102002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body103002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body104002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body105002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body106002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body107002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body108002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body109002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body11021.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body110001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body111001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body112001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body113001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body114001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body115001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body116001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body117001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body118001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body119001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body12021.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body120001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body121001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body122001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body123001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body124001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body125001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body126001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body127001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body128001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body129001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body13020.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body130001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body131001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body132001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body133001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body134001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body135001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body136001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body137001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body138001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body139001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body14020.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body140001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body141001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body142001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body143001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body144001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body145001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body146001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body147001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body148001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body149001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body15019.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body150001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body151001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body152001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body153001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body154001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body155001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body156001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body157001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body158001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body159001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body16019.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body160001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body161001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body162001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body163001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body164001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body165001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body166001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body167001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body168001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body169001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body17017.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body170001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body171001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body172001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body173001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body174001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body175001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body176001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body177001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body178001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body179001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body18013.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body180001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body181001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body182001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body183001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body184001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body185001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body186001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body187001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body188001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body189001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body19013.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body190001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body191001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body192001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body193001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body194001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body195001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body196001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body197001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body198001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body199001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <group scale={0.394}>
                  <mesh geometry={nodes.Body2021_1.geometry} material={materials['Opaque(255,255,0)']} />
                  <mesh geometry={nodes.Body2021_2.geometry} material={materials['Opaque(0,255,0)']} />
                  <mesh geometry={nodes.Body2021_3.geometry} material={materials['Opaque(255,0,0)']} />
                  <mesh geometry={nodes.Body2021_4.geometry} material={materials['Opaque(63,63,63)']} />
                  <mesh geometry={nodes.Body2021_5.geometry} material={materials['Opaque(192,192,192)']} />
                  <mesh geometry={nodes.Body2021_6.geometry} material={materials['Opaque(252,188,132)']} />
                  <mesh geometry={nodes.Body2021_7.geometry} material={materials['Opaque(151,215,0)']} />
                  <mesh geometry={nodes.Body2021_8.geometry} material={materials['Opaque(255,255,255)']} />
                </group>
                <mesh geometry={nodes.Body20002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body200001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body201001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body202001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body203001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body204001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body205001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body206001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body207001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body208001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body209001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body21013.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body210001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body211001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body212001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body213001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body214001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body215001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body216001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body217001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body218001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body219001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body22013.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body220001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body221001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body222001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body223001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body224001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body225001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body226001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body227001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body228001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body229001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body23013.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body230001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body231001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body232001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body233001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body234001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body235001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body236001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body237001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body238001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body239001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body24013.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body240001.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body25013.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body26002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body27002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body28002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body29002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body3034.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body30002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body31002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body32002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body33002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body34002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body35002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body36002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body37002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body38002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body39002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body4025.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body40002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body41002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body42002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body43002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body44002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body45002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body46002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body47002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body48002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body49002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body5025.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body50002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body51002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body52002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body53002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body54002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body55002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body56002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body57002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body58002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body59002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body6025.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body60002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body61002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body62002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body63002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body64002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body65002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body66002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body67002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body68002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body69002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body7025.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body70002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body71002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body72002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body73002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body74002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body75002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body76002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body77002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body78002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body79002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body8025.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body80002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body81002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body82002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body83002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body84002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body85003.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body86002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body87002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body88002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body89002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body9021.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body90002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body91002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body92002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body93002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body94002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body95002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body96002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body97002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body98002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
                <mesh geometry={nodes.Body99002.geometry} material={materials['Opaque(60,60,60)']} scale={0.394} />
              </group>
              <group position={[0.049, 1.163, -0.246]} rotation={[0, Math.PI / 5, 0]}>
                <group rotation={[Math.PI / 2, 0, Math.PI]}>
                  <mesh geometry={nodes.Body11016_1.geometry} material={materials['Opaque(199,194,189)']} scale={0.394} />
                </group>
                <group position={[0, 0.419, 0]} rotation={[-Math.PI, 0, -Math.PI]}>
                  <mesh geometry={nodes.Body11017_1.geometry} material={materials['Opaque(198,193,188)']} scale={0.394} />
                </group>
              </group>
              <group position={[-0.214, 1.025, -0.331]} rotation={[0, -0.348, Math.PI]}>
                <mesh geometry={nodes.Body11019_1.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <group position={[0.049, 1.025, -0.521]} rotation={[Math.PI, -1.537, 0]}>
                <mesh geometry={nodes.Body11020_1.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <group position={[0.311, 1.025, -0.331]} rotation={[-Math.PI, -0.281, 0]}>
                <mesh geometry={nodes.Body11021_1.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <group position={[0.211, 1.025, -0.023]} rotation={[Math.PI, 0.976, 0]}>
                <mesh geometry={nodes.Body11022_1.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <group position={[-0.113, 1.025, -0.023]} rotation={[0, 0.909, Math.PI]}>
                <mesh geometry={nodes.Body11023.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <group position={[0.565, -0.585, -1.141]} rotation={[0, 0.393, Math.PI]}>
                <mesh geometry={nodes.Body11030_1.geometry} material={materials['Opaque(165,158,150)']} scale={0.394} />
              </group>
              <group position={[-0.985, -0.585, -0.246]} rotation={[-Math.PI, 0.654, 0]}>
                <mesh geometry={nodes.Body11031_1.geometry} material={materials['Opaque(165,158,150)']} scale={0.394} />
              </group>
              <group position={[0.565, -0.585, 0.65]} rotation={[Math.PI, -1.44, 0]}>
                <mesh geometry={nodes.Body11032_1.geometry} material={materials['Opaque(165,158,150)']} scale={0.394} />
              </group>
              <group position={[0.383, -1.497, -0.825]} rotation={[0, 0.393, Math.PI]}>
                <mesh geometry={nodes.Body11027_1.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <group position={[-0.621, -1.497, -0.245]} rotation={[-Math.PI, 0.654, 0]}>
                <mesh geometry={nodes.Body11028_1.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <group position={[0.383, -1.497, 0.334]} rotation={[Math.PI, -1.44, 0]}>
                <mesh geometry={nodes.Body11029_1.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
            </group>
            <group position={[0.977, 0.795, -1.217]} rotation={[0, 0.294, Math.PI]}>
              <group position={[-0.438, 0.326, 0.054]} rotation={[-Math.PI, -0.393, -Math.PI / 2]}>
                <mesh geometry={nodes.Body1991.geometry} material={materials['Opaque(198,193,188)']} scale={0.394} />
              </group>
              <group position={[-0.054, 0.326, -0.438]} rotation={[Math.PI, 1.178, -Math.PI / 2]}>
                <mesh geometry={nodes.Body1992.geometry} material={materials['Opaque(198,193,188)']} scale={0.394} />
              </group>
              <group position={[0.438, 0.326, -0.054]} rotation={[0, 0.393, Math.PI / 2]}>
                <mesh geometry={nodes.Body1993.geometry} material={materials['Opaque(198,193,188)']} scale={0.394} />
              </group>
              <group position={[0.054, 0.326, 0.438]} rotation={[0, -1.178, Math.PI / 2]}>
                <mesh geometry={nodes.Body1994.geometry} material={materials['Opaque(198,193,188)']} scale={0.394} />
              </group>
              <group position={[0.084, -0.055, 0.02]} rotation={[-Math.PI, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body1990.geometry} material={materials['Opaque(198,193,188)']} scale={0.394} />
              </group>
              <group position={[0, 0.002, -0.021]} rotation={[0, 0.292, -Math.PI / 2]}>
                <mesh geometry={nodes.Body1995.geometry} material={materials['Opaque(198,193,188)']} scale={0.394} />
              </group>
              <mesh geometry={nodes.Body1989.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
            </group>
            <group position={[0.977, 1.43, -1.217]}>
              <mesh geometry={nodes.Body1996.geometry} material={materials['Opaque(51,102,178)']} scale={0.394} />
            </group>
            <group position={[0.977, 2.8, -1.217]}>
              <group position={[0, 0.242, 0]} rotation={[0, Math.PI / 3, 0]}>
                <mesh geometry={nodes.Body1984.geometry} material={materials['Opaque(198,193,188)']} scale={0.394} />
              </group>
              <group position={[0, -0.07, 0]} rotation={[-Math.PI, -1.109, -Math.PI / 2]}>
                <mesh geometry={nodes.Body1986.geometry} material={materials['Opaque(165,132,0)']} scale={0.394} />
              </group>
              <group position={[0, -0.543, 0]} rotation={[-Math.PI, -1.109, -Math.PI / 2]}>
                <mesh geometry={nodes.Body1988.geometry} material={materials['Opaque(165,132,0)']} scale={0.394} />
              </group>
              <group position={[0, -0.571, 0]} rotation={[0, -0.017, Math.PI / 2]}>
                <mesh geometry={nodes.Body1987.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              </group>
              <mesh geometry={nodes.Body1985.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
            </group>
            <group position={[0.977, 0.185, -1.217]}>
              <mesh geometry={nodes.Body11014_1.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
            </group>
            <group position={[0.977, 0.239, -1.217]} rotation={[0, -0.469, 0]}>
              <mesh geometry={nodes.Body1997.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
            </group>
          </group>
          <group position={[23.651, -18.655, 5.931]} rotation={[-1.746, -0.086, -1.586]}>
            <group position={[0.003, 0.334, -0.896]}>
              <mesh geometry={nodes.Body11059.geometry} material={materials['Opaque(37,40,42)']} scale={0.394} />
            </group>
            <group position={[0.003, 0.25, -1.123]}>
              <mesh geometry={nodes.Body11060.geometry} material={materials['Opaque(128,128,128)']} scale={0.394} />
            </group>
            <group position={[0, 0.375, 0]}>
              <group position={[0, -0.691, 0]} rotation={[0, -0.092, Math.PI / 2]}>
                <mesh geometry={nodes.Body11055.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              </group>
              <group position={[0, -0.256, 0]} rotation={[0, -0.001, Math.PI / 2]}>
                <mesh geometry={nodes.Body11056.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              </group>
              <group position={[0, -0.557, -0.9]} rotation={[0, 1.022, -Math.PI]}>
                <mesh geometry={nodes.Body11057.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
                <mesh geometry={nodes.Body10023.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
                <mesh geometry={nodes.Body11023_1.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
                <mesh geometry={nodes.Body12023.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
                <mesh geometry={nodes.Body13022.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
                <mesh geometry={nodes.Body14022.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
                <mesh geometry={nodes.Body15021.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
                <mesh geometry={nodes.Body16021.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
                <mesh geometry={nodes.Body2076.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
                <mesh geometry={nodes.Body3036.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
                <mesh geometry={nodes.Body4027.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
                <mesh geometry={nodes.Body5027.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
                <mesh geometry={nodes.Body6027.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
                <mesh geometry={nodes.Body7027.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
                <mesh geometry={nodes.Body8027.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
                <mesh geometry={nodes.Body9023.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              </group>
              <group position={[0, -0.339, -0.9]} rotation={[0, -0.001, 0]}>
                <group scale={0.394}>
                  <mesh geometry={nodes.Body1327_1.geometry} material={materials['Opaque(255,192,192)']} />
                  <mesh geometry={nodes.Body1327_2.geometry} material={materials['Opaque(192,192,255)']} />
                </group>
              </group>
              <group position={[0, -0.375, 0]}>
                <mesh geometry={nodes.Body11048.geometry} material={materials['Opaque(37,40,42)']} scale={0.394} />
              </group>
              <group position={[0, -0.375, 0]}>
                <mesh geometry={nodes.Body11049.geometry} material={materials['Opaque(37,40,42)']} scale={0.394} />
              </group>
              <group position={[0, -0.375, 0]} rotation={[Math.PI, -Math.PI / 6, Math.PI]}>
                <mesh geometry={nodes.Body11050.geometry} material={materials['Opaque(37,40,42)']} scale={0.394} />
              </group>
              <group position={[0, -0.375, -0.9]} rotation={[Math.PI, 0.923, 0]}>
                <mesh geometry={nodes.Body11051.geometry} material={materials['Opaque(37,40,42)']} scale={0.394} />
              </group>
              <group position={[0, -0.079, 0.669]} rotation={[Math.PI / 2, 0, -3.075]}>
                <mesh geometry={nodes.Body11052.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <group position={[0.58, -0.079, -0.335]} rotation={[Math.PI / 2, 0, 0.001]}>
                <mesh geometry={nodes.Body11053.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <group position={[-0.58, -0.079, -0.335]} rotation={[Math.PI / 2, 0, 0.001]}>
                <mesh geometry={nodes.Body11054.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
            </group>
          </group>
          <group position={[-0.959, 10.958, 0]} rotation={[Math.PI, 0, -0.087]}>
            <group position={[40.623, 27.686, -1.4]} rotation={[0, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11190.geometry} material={materials['Opaque(221,232,255)']} scale={0.394} />
            </group>
            <group position={[40.623, 29.061, -6.025]} rotation={[Math.PI / 2, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11191.geometry} material={materials['Opaque(221,232,255)']} scale={0.394} />
            </group>
            <group position={[40.623, 33.686, -1.4]} rotation={[0, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11192.geometry} material={materials['Opaque(221,232,255)']} scale={0.394} />
            </group>
            <group position={[40.623, 32.311, -6.025]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]}>
              <mesh geometry={nodes.Body11193.geometry} material={materials['Opaque(221,232,255)']} scale={0.394} />
            </group>
            <group position={[48.044, 22.245, -11.73]} rotation={[0, 0, -0.087]}>
              <group position={[-9.236, 4.645, -0.179]}>
                <mesh geometry={nodes.Body11177.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <group position={[-9.236, 4.645, -0.181]}>
                <mesh geometry={nodes.Body11183.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <group position={[-9.236, 4.645, -0.39]} rotation={[-Math.PI, 0, -3.141]}>
                <group position={[0.156, 1.052, -0.569]} rotation={[Math.PI, -1.571, 0]}>
                  <mesh geometry={nodes.Body11179.geometry} material={materials['Opaque(202,209,238)']} scale={0.394} />
                </group>
                <group position={[-0.156, 1.052, -0.569]} rotation={[Math.PI, -1.571, 0]}>
                  <mesh geometry={nodes.Body11180.geometry} material={materials['Opaque(202,209,238)']} scale={0.394} />
                </group>
                <group position={[-0.331, 2.88, -0.093]} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
                  <mesh geometry={nodes.Body11181.geometry} material={materials['Opaque(203,210,239)']} scale={0.394} />
                </group>
                <group position={[-0.019, 2.88, -0.093]} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
                  <mesh geometry={nodes.Body11182.geometry} material={materials['Opaque(203,210,239)']} scale={0.394} />
                </group>
                <mesh geometry={nodes.Body11178.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
              </group>
              <group position={[-6.031, 5.92, -0.486]} rotation={[Math.PI / 2, 0.975, -Math.PI]}>
                <mesh geometry={nodes.Body11184.geometry} material={materials['Opaque(255,242,232)']} scale={0.394} />
              </group>
              <group position={[-7.961, 1.44, -0.486]} rotation={[Math.PI / 2, -0.596, -Math.PI]}>
                <mesh geometry={nodes.Body11185.geometry} material={materials['Opaque(255,242,232)']} scale={0.394} />
              </group>
              <group position={[-12.442, 3.371, -0.486]} rotation={[-Math.PI / 2, -0.975, 0]}>
                <mesh geometry={nodes.Body11186.geometry} material={materials['Opaque(255,242,232)']} scale={0.394} />
              </group>
              <group position={[-10.511, 7.851, -0.486]} rotation={[-Math.PI / 2, 0.596, 0]}>
                <mesh geometry={nodes.Body11187.geometry} material={materials['Opaque(255,242,232)']} scale={0.394} />
              </group>
            </group>
            <group position={[39.248, 27.678, -9.307]} rotation={[Math.PI / 2, 0, -Math.PI / 2]}>
              <group scale={0.394}>
                <mesh geometry={nodes.Body1384_1.geometry} material={materials['Opaque(64,64,64)']} />
                <mesh geometry={nodes.Body1384_2.geometry} material={materials['Opaque(128,128,128)']} />
                <mesh geometry={nodes.Body1384_3.geometry} material={materials['Opaque(217,225,221)']} />
                <mesh geometry={nodes.Body1384_4.geometry} material={materials['Opaque(128,128,0)']} />
                <mesh geometry={nodes.Body1384_5.geometry} material={materials['Opaque(192,0,0)']} />
              </group>
            </group>
            <group position={[41.123, 26.686, -1.025]} rotation={[0, 0, Math.PI]}>
              <mesh geometry={nodes.Body11189.geometry} material={materials['Millennium Falcon Green']} scale={0.394} />
            </group>
            <group position={[40.123, 28.686, -5.025]} rotation={[0, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11188.geometry} material={materials['Millennium Falcon Green']} scale={0.394} />
            </group>
            <group position={[41.123, 32.686, -1.025]} rotation={[0, 0, Math.PI]}>
              <mesh geometry={nodes.Body11194.geometry} material={materials['Millennium Falcon Green']} scale={0.394} />
            </group>
            <group position={[39.248, 29.863, -6.025]} rotation={[0, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11195.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            </group>
            <group rotation={[Math.PI / 2, 0, 0]}>
              <group position={[39.248, -6.025, -29.479]} rotation={[-Math.PI / 2, 0, 0]}>
                <mesh geometry={nodes.Body11146.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
              </group>
              <group position={[39.245, -6.039, -29.478]} rotation={[-Math.PI, 0, -2.174]}>
                <mesh geometry={nodes.Body11145.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[39.248, -6.025, -29.158]} rotation={[1.571, 1.553, -1.571]}>
                <mesh geometry={nodes.Body11143.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              </group>
              <group position={[39.248, -6.025, -28.93]} rotation={[Math.PI / 2, 0, 0]}>
                <mesh geometry={nodes.Body11142.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <group position={[39.248, -6.025, -29.509]} rotation={[0, 1.571, 0]}>
                <mesh geometry={nodes.Body11147.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              </group>
              <group position={[39.248, -6.025, -29.194]} rotation={[Math.PI / 2, 1.478, -Math.PI / 2]}>
                <mesh geometry={nodes.Body11144.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              </group>
            </group>
            <group position={[39.248, 27.678, -8.139]} rotation={[-Math.PI / 2, -Math.PI / 2, 0]}>
              <group position={[0, 0.264, 0]} rotation={[0, 0.12, 0]}>
                <mesh geometry={nodes.Body11155.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
                <mesh geometry={nodes.Body2089.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[0.342, 0.25, 0.21]} rotation={[0, 0.369, 0]}>
                <mesh geometry={nodes.Body11152.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[-0.342, 0.25, 0.21]} rotation={[Math.PI, -0.369, Math.PI]}>
                <mesh geometry={nodes.Body11153.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[0, 0.25, -0.401]} rotation={[0, 0.466, 0]}>
                <mesh geometry={nodes.Body11154.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[0, 0.144, -0.401]} rotation={[0, 0.019, 0]}>
                <mesh geometry={nodes.Body11149.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[0.342, 0.144, 0.21]}>
                <mesh geometry={nodes.Body11150.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[-0.342, 0.144, 0.21]}>
                <mesh geometry={nodes.Body11151.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <mesh geometry={nodes.Body11148.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              <group position={[0, 0.071, 0]} rotation={[-Math.PI, 1.571, 0]}>
                <mesh geometry={nodes.Body11156.geometry} material={materials['Opaque(64,64,64).001']} scale={0.394} />
              </group>
            </group>
            <group position={[39.248, 27.678, -7.639]} rotation={[-Math.PI / 2, -Math.PI / 2, 0]}>
              <group position={[0, 0.264, 0]} rotation={[0, 0.12, 0]}>
                <mesh geometry={nodes.Body11164.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
                <mesh geometry={nodes.Body2090.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[0.342, 0.25, 0.21]} rotation={[0, 0.369, 0]}>
                <mesh geometry={nodes.Body11161.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[-0.342, 0.25, 0.21]} rotation={[Math.PI, -0.369, Math.PI]}>
                <mesh geometry={nodes.Body11162.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[0, 0.25, -0.401]} rotation={[0, 0.466, 0]}>
                <mesh geometry={nodes.Body11163.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[0, 0.144, -0.401]} rotation={[0, 0.019, 0]}>
                <mesh geometry={nodes.Body11158.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[0.342, 0.144, 0.21]}>
                <mesh geometry={nodes.Body11159.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[-0.342, 0.144, 0.21]}>
                <mesh geometry={nodes.Body11160.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <mesh geometry={nodes.Body11157.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              <group position={[0, 0.071, 0]} rotation={[-Math.PI, 1.571, 0]}>
                <mesh geometry={nodes.Body11165.geometry} material={materials['Opaque(64,64,64).001']} scale={0.394} />
              </group>
            </group>
            <group position={[39.248, 27.678, -8.873]} rotation={[Math.PI / 2, 0, -Math.PI]}>
              <group position={[0, -0.137, 0]} rotation={[Math.PI, 1.571, 0]}>
                <mesh geometry={nodes.Body11167.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[0, 0.049, 0]}>
                <mesh geometry={nodes.Body11168.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[0, 0.364, 0]}>
                <mesh geometry={nodes.Body11175.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <group position={[-0.689, 0.413, 0.689]} rotation={[Math.PI / 2, 0, 1.562]}>
                <mesh geometry={nodes.Body11169.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[0.689, 0.413, 0.689]} rotation={[Math.PI / 2, 0, -0.009]}>
                <mesh geometry={nodes.Body11171.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[0.689, 0.413, -0.689]} rotation={[Math.PI / 2, 0, -1.58]}>
                <mesh geometry={nodes.Body11172.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[0, 0.295, -0.571]} rotation={[-Math.PI / 2, 0, 0.232]}>
                <mesh geometry={nodes.Body11173.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[0, 0.295, 0.571]} rotation={[-Math.PI / 2, 0, -0.815]}>
                <mesh geometry={nodes.Body11174.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[-0.272, 0.079, 0]} rotation={[1.181, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body11170.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
              </group>
              <mesh geometry={nodes.Body11166.geometry} material={materials['Opaque(64,64,64).001']} scale={0.394} />
            </group>
            <group position={[39.248, 27.678, -7.184]} rotation={[-Math.PI, 0, 0]}>
              <group position={[0, -0.9, -1.159]} rotation={[0, -1.247, 0]}>
                <mesh geometry={nodes.Body11133.geometry} material={materials['Opaque(165,158,150)']} scale={0.394} />
              </group>
              <group position={[0.001, -0.011, -0.008]} rotation={[0, 0, 0.333]}>
                <mesh geometry={nodes.Body11135.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[-0.001, -1.114, -1.148]} rotation={[1.57, 0.004, 0.158]}>
                <mesh geometry={nodes.Body11141.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[0, 0, 0.291]} rotation={[0, 1.571, 0]}>
                <mesh geometry={nodes.Body11138.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              </group>
              <group position={[0, -1.35, -1.159]} rotation={[0, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body11127.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              </group>
              <group position={[0, 0, -0.059]} rotation={[-Math.PI / 2, 1.39, Math.PI / 2]}>
                <mesh geometry={nodes.Body11125.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              </group>
              <group position={[0, -1.103, -1.159]} rotation={[0, 1.479, 1.571]}>
                <mesh geometry={nodes.Body11131.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              </group>
              <group position={[0, 0, 0.267]} rotation={[-Math.PI / 2, 1.39, Math.PI / 2]}>
                <mesh geometry={nodes.Body11134.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              </group>
              <group position={[0, -0.827, -0.855]} rotation={[-Math.PI / 2, 0, -Math.PI]}>
                <mesh geometry={nodes.Body11126.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <group position={[0, 0, -1.16]} rotation={[-Math.PI / 2, 0.266, 0]}>
                <mesh geometry={nodes.Body11128.geometry} material={materials['Opaque(255,242,232)']} scale={0.394} />
              </group>
              <group position={[0, -0.002, -1.159]} rotation={[0, -0.542, 0]}>
                <mesh geometry={nodes.Body11129.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <group position={[0, -0.99, -1.159]}>
                <mesh geometry={nodes.Body11130.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <group position={[0, -0.525, -1.159]} rotation={[-Math.PI / 2, 0, 0.363]}>
                <mesh geometry={nodes.Body11132.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <group position={[-0.5, -0.792, -0.138]} rotation={[0, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body11139.geometry} material={materials['Opaque(202,209,238)']} scale={0.394} />
              </group>
              <group position={[0.5, -0.792, 0.098]} rotation={[0, 0.111, Math.PI / 2]}>
                <mesh geometry={nodes.Body11140.geometry} material={materials['Opaque(202,209,238)']} scale={0.394} />
              </group>
              <group position={[0.5, -0.602, -0.118]}>
                <mesh geometry={nodes.Body11136.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <group position={[-0.5, -0.602, 0.079]} rotation={[0, 0.178, 0]}>
                <mesh geometry={nodes.Body11137.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <mesh geometry={nodes.Body11124.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
            </group>
          </group>
          <group rotation={[Math.PI, 0, -0.087]}>
            <group position={[15.623, 16.686, -1.4]} rotation={[0, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11262.geometry} material={materials['Opaque(221,232,255)']} scale={0.394} />
            </group>
            <group position={[15.623, 18.061, -6.025]} rotation={[Math.PI / 2, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11263.geometry} material={materials['Opaque(221,232,255)']} scale={0.394} />
            </group>
            <group position={[15.623, 22.686, -1.4]} rotation={[0, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11264.geometry} material={materials['Opaque(221,232,255)']} scale={0.394} />
            </group>
            <group position={[15.623, 21.311, -6.025]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]}>
              <mesh geometry={nodes.Body11265.geometry} material={materials['Opaque(221,232,255)']} scale={0.394} />
            </group>
            <group position={[25.794, 11.245, -11.73]} rotation={[0, 0, -0.087]}>
              <group position={[-9.236, 4.645, -0.179]}>
                <mesh geometry={nodes.Body11249.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <group position={[-9.236, 4.645, -0.181]}>
                <mesh geometry={nodes.Body11255.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <group position={[-9.236, 4.645, -0.39]} rotation={[-Math.PI, 0, -3.141]}>
                <group position={[0.156, 1.052, -0.569]} rotation={[Math.PI, -1.571, 0]}>
                  <mesh geometry={nodes.Body11251.geometry} material={materials['Opaque(202,209,238)']} scale={0.394} />
                </group>
                <group position={[-0.156, 1.052, -0.569]} rotation={[Math.PI, -1.571, 0]}>
                  <mesh geometry={nodes.Body11252.geometry} material={materials['Opaque(202,209,238)']} scale={0.394} />
                </group>
                <group position={[-0.331, 2.88, -0.093]} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
                  <mesh geometry={nodes.Body11253.geometry} material={materials['Opaque(203,210,239)']} scale={0.394} />
                </group>
                <group position={[-0.019, 2.88, -0.093]} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
                  <mesh geometry={nodes.Body11254.geometry} material={materials['Opaque(203,210,239)']} scale={0.394} />
                </group>
                <mesh geometry={nodes.Body11250.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
              </group>
              <group position={[-6.031, 5.92, -0.486]} rotation={[Math.PI / 2, 0.975, Math.PI]}>
                <mesh geometry={nodes.Body11256.geometry} material={materials['Opaque(255,242,232)']} scale={0.394} />
              </group>
              <group position={[-7.961, 1.44, -0.486]} rotation={[Math.PI / 2, -0.596, -Math.PI]}>
                <mesh geometry={nodes.Body11257.geometry} material={materials['Opaque(255,242,232)']} scale={0.394} />
              </group>
              <group position={[-12.442, 3.371, -0.486]} rotation={[-Math.PI / 2, -0.975, 0]}>
                <mesh geometry={nodes.Body11258.geometry} material={materials['Opaque(255,242,232)']} scale={0.394} />
              </group>
              <group position={[-10.511, 7.851, -0.486]} rotation={[-Math.PI / 2, 0.596, 0]}>
                <mesh geometry={nodes.Body11259.geometry} material={materials['Opaque(255,242,232)']} scale={0.394} />
              </group>
            </group>
            <group position={[16.998, 16.678, -9.307]} rotation={[Math.PI / 2, 0, -Math.PI / 2]}>
              <group scale={0.394}>
                <mesh geometry={nodes.Body1384_1.geometry} material={materials['Opaque(64,64,64)']} />
                <mesh geometry={nodes.Body1384_2.geometry} material={materials['Opaque(128,128,128)']} />
                <mesh geometry={nodes.Body1384_3.geometry} material={materials['Opaque(217,225,221)']} />
                <mesh geometry={nodes.Body1384_4.geometry} material={materials['Opaque(128,128,0)']} />
                <mesh geometry={nodes.Body1384_5.geometry} material={materials['Opaque(192,0,0)']} />
              </group>
            </group>
            <group position={[16.123, 15.686, -1.025]} rotation={[0, 0, Math.PI]}>
              <mesh geometry={nodes.Body11261.geometry} material={materials['Millennium Falcon Green']} scale={0.394} />
            </group>
            <group position={[15.123, 17.686, -5.025]} rotation={[0, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11260.geometry} material={materials['Millennium Falcon Green']} scale={0.394} />
            </group>
            <group position={[16.123, 21.686, -1.025]} rotation={[0, 0, Math.PI]}>
              <mesh geometry={nodes.Body11266.geometry} material={materials['Millennium Falcon Green']} scale={0.394} />
            </group>
            <group position={[16.998, 18.863, -6.025]} rotation={[0, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11267.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
            </group>
            <group rotation={[Math.PI / 2, 0, 0]}>
              <group position={[16.998, -6.025, -18.479]} rotation={[-Math.PI / 2, 0, 0]}>
                <mesh geometry={nodes.Body11218.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
              </group>
              <group position={[16.995, -6.039, -18.478]} rotation={[-Math.PI, 0, -2.174]}>
                <mesh geometry={nodes.Body11217.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[16.998, -6.025, -18.158]} rotation={[1.571, 1.553, -1.571]}>
                <mesh geometry={nodes.Body11215.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              </group>
              <group position={[16.998, -6.025, -17.93]} rotation={[Math.PI / 2, 0, 0]}>
                <mesh geometry={nodes.Body11214.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <group position={[16.998, -6.025, -18.509]} rotation={[0, 1.571, 0]}>
                <mesh geometry={nodes.Body11219.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              </group>
              <group position={[16.998, -6.025, -18.194]} rotation={[Math.PI / 2, 1.478, -Math.PI / 2]}>
                <mesh geometry={nodes.Body11216.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              </group>
            </group>
            <group position={[16.998, 16.678, -8.139]} rotation={[-Math.PI / 2, -Math.PI / 2, 0]}>
              <group position={[0, 0.264, 0]} rotation={[0, 0.12, 0]}>
                <mesh geometry={nodes.Body11227.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
                <mesh geometry={nodes.Body2091.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[0.342, 0.25, 0.21]} rotation={[0, 0.369, 0]}>
                <mesh geometry={nodes.Body11224.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[-0.342, 0.25, 0.21]} rotation={[Math.PI, -0.369, Math.PI]}>
                <mesh geometry={nodes.Body11225.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[0, 0.25, -0.401]} rotation={[0, 0.466, 0]}>
                <mesh geometry={nodes.Body11226.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[0, 0.144, -0.401]} rotation={[0, 0.019, 0]}>
                <mesh geometry={nodes.Body11221.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[0.342, 0.144, 0.21]}>
                <mesh geometry={nodes.Body11222.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[-0.342, 0.144, 0.21]}>
                <mesh geometry={nodes.Body11223.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <mesh geometry={nodes.Body11220.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              <group position={[0, 0.071, 0]} rotation={[-Math.PI, 1.571, 0]}>
                <mesh geometry={nodes.Body11228.geometry} material={materials['Opaque(64,64,64).001']} scale={0.394} />
              </group>
            </group>
            <group position={[16.998, 16.678, -7.639]} rotation={[-Math.PI / 2, -Math.PI / 2, 0]}>
              <group position={[0, 0.264, 0]} rotation={[0, 0.12, 0]}>
                <mesh geometry={nodes.Body11236.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
                <mesh geometry={nodes.Body2092.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[0.342, 0.25, 0.21]} rotation={[0, 0.369, 0]}>
                <mesh geometry={nodes.Body11233.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[-0.342, 0.25, 0.21]} rotation={[Math.PI, -0.369, Math.PI]}>
                <mesh geometry={nodes.Body11234.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[0, 0.25, -0.401]} rotation={[0, 0.466, 0]}>
                <mesh geometry={nodes.Body11235.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[0, 0.144, -0.401]} rotation={[0, 0.019, 0]}>
                <mesh geometry={nodes.Body11230.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[0.342, 0.144, 0.21]}>
                <mesh geometry={nodes.Body11231.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[-0.342, 0.144, 0.21]}>
                <mesh geometry={nodes.Body11232.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <mesh geometry={nodes.Body11229.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              <group position={[0, 0.071, 0]} rotation={[-Math.PI, 1.571, 0]}>
                <mesh geometry={nodes.Body11237.geometry} material={materials['Opaque(64,64,64).001']} scale={0.394} />
              </group>
            </group>
            <group position={[16.998, 16.678, -8.873]} rotation={[Math.PI / 2, 0, -Math.PI]}>
              <group position={[0, -0.137, 0]} rotation={[Math.PI, 1.571, 0]}>
                <mesh geometry={nodes.Body11239.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[0, 0.049, 0]}>
                <mesh geometry={nodes.Body11240.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[0, 0.364, 0]}>
                <mesh geometry={nodes.Body11247.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <group position={[-0.689, 0.413, 0.689]} rotation={[Math.PI / 2, 0, 1.562]}>
                <mesh geometry={nodes.Body11241.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[0.689, 0.413, 0.689]} rotation={[Math.PI / 2, 0, -0.009]}>
                <mesh geometry={nodes.Body11243.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[0.689, 0.413, -0.689]} rotation={[Math.PI / 2, 0, -1.58]}>
                <mesh geometry={nodes.Body11244.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[0, 0.295, -0.571]} rotation={[-Math.PI / 2, 0, 0.232]}>
                <mesh geometry={nodes.Body11245.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[0, 0.295, 0.571]} rotation={[-Math.PI / 2, 0, -0.815]}>
                <mesh geometry={nodes.Body11246.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[-0.272, 0.079, 0]} rotation={[1.181, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body11242.geometry} material={materials['Opaque(255,255,255)']} scale={0.394} />
              </group>
              <mesh geometry={nodes.Body11238.geometry} material={materials['Opaque(64,64,64).001']} scale={0.394} />
            </group>
            <group position={[16.998, 16.678, -7.184]} rotation={[-Math.PI, 0, 0]}>
              <group position={[0, -0.9, -1.159]} rotation={[0, -1.247, 0]}>
                <mesh geometry={nodes.Body11205.geometry} material={materials['Opaque(165,158,150)']} scale={0.394} />
              </group>
              <group position={[0.001, -0.011, -0.008]} rotation={[0, 0, 0.333]}>
                <mesh geometry={nodes.Body11207.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[-0.001, -1.114, -1.148]} rotation={[1.57, 0.004, 0.158]}>
                <mesh geometry={nodes.Body11213.geometry} material={materials['Opaque(135,140,140)']} scale={0.394} />
              </group>
              <group position={[0, 0, 0.291]} rotation={[0, 1.571, 0]}>
                <mesh geometry={nodes.Body11210.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              </group>
              <group position={[0, -1.35, -1.159]} rotation={[0, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body11199.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              </group>
              <group position={[0, 0, -0.059]} rotation={[-Math.PI / 2, 1.39, Math.PI / 2]}>
                <mesh geometry={nodes.Body11197.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              </group>
              <group position={[0, -1.103, -1.159]} rotation={[0, 1.479, 1.571]}>
                <mesh geometry={nodes.Body11203.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              </group>
              <group position={[0, 0, 0.267]} rotation={[-Math.PI / 2, 1.39, Math.PI / 2]}>
                <mesh geometry={nodes.Body11206.geometry} material={materials['Opaque(192,192,192)']} scale={0.394} />
              </group>
              <group position={[0, -0.827, -0.855]} rotation={[-Math.PI / 2, 0, -Math.PI]}>
                <mesh geometry={nodes.Body11198.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <group position={[0, 0, -1.16]} rotation={[-Math.PI / 2, 0.266, 0]}>
                <mesh geometry={nodes.Body11200.geometry} material={materials['Opaque(255,242,232)']} scale={0.394} />
              </group>
              <group position={[0, -0.002, -1.159]} rotation={[0, -0.542, 0]}>
                <mesh geometry={nodes.Body11201.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <group position={[0, -0.99, -1.159]}>
                <mesh geometry={nodes.Body11202.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <group position={[0, -0.525, -1.159]} rotation={[-Math.PI / 2, 0, 0.363]}>
                <mesh geometry={nodes.Body11204.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <group position={[-0.5, -0.792, -0.138]} rotation={[0, 0, Math.PI / 2]}>
                <mesh geometry={nodes.Body11211.geometry} material={materials['Opaque(202,209,238)']} scale={0.394} />
              </group>
              <group position={[0.5, -0.792, 0.098]} rotation={[0, 0.111, Math.PI / 2]}>
                <mesh geometry={nodes.Body11212.geometry} material={materials['Opaque(202,209,238)']} scale={0.394} />
              </group>
              <group position={[0.5, -0.602, -0.118]}>
                <mesh geometry={nodes.Body11208.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <group position={[-0.5, -0.602, 0.079]} rotation={[0, 0.178, 0]}>
                <mesh geometry={nodes.Body11209.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
              </group>
              <mesh geometry={nodes.Body11196.geometry} material={materials['Opaque(64,64,64)']} scale={0.394} />
            </group>
          </group>
          <group position={[20.135, -16.5, 22.126]} rotation={[-0.174, -0.015, 1.657]}>
            <group position={[-0.375, 0.609, 0]}>
              <mesh geometry={nodes.Body11114.geometry} material={materials['Opaque(25,25,25)']} scale={0.394} />
            </group>
            <group position={[-0.375, 0.411, 0]} rotation={[Math.PI / 2, 0, 1.654]}>
              <mesh geometry={nodes.Body11115.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
            </group>
            <group position={[0.089, 0.264, 0]} rotation={[-0.312, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11113.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body10030.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body11030.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body12029.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body13028.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body14028.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body15027.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body16027.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body2085.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body3045.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body4035.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body5035.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body6035.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body7035.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body8034.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body9030.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
            </group>
            <group position={[0.246, 0.264, 0]} rotation={[-0.223, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11117.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body10031.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body11031.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body12030.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body13029.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body14029.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body15028.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body16028.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body2086.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body3046.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body4036.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body5036.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body6036.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body7036.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body8035.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body9031.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
            </group>
            <group position={[0.162, 0.264, 0]} rotation={[-0.591, -Math.PI / 2, 0]}>
              <mesh geometry={nodes.Body11116.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
            </group>
            <mesh geometry={nodes.Body11112.geometry} material={materials['Opaque(221,232,255)']} scale={0.394} />
          </group>
          <group position={[39.068, -14.844, 22.126]} rotation={[2.968, 0.015, -1.657]}>
            <group position={[-0.375, 0.609, 0]}>
              <mesh geometry={nodes.Body11120.geometry} material={materials['Opaque(25,25,25)']} scale={0.394} />
            </group>
            <group position={[-0.375, 0.411, 0]} rotation={[Math.PI / 2, 0, 1.654]}>
              <mesh geometry={nodes.Body11121.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
            </group>
            <group position={[0.089, 0.264, 0]} rotation={[-0.312, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11119.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body10032.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body11032.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body12031.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body13030.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body14030.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body15029.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body16029.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body2087.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body3047.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body4037.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body5037.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body6037.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body7037.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body8036.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body9032.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
            </group>
            <group position={[0.246, 0.264, 0]} rotation={[-0.223, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11123.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body10033.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body11033.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body12032.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body13031.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body14031.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body15030.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body16030.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body2088.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body3048.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body4038.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body5038.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body6038.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body7038.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body8037.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body9033.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
            </group>
            <group position={[0.162, 0.264, 0]} rotation={[-0.591, -Math.PI / 2, 0]}>
              <mesh geometry={nodes.Body11122.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
            </group>
            <mesh geometry={nodes.Body11118.geometry} material={materials['Opaque(221,232,255)']} scale={0.394} />
          </group>
          <group position={[19.963, -14.538, 21.779]} rotation={[2.968, 0.015, 1.485]}>
            <group position={[-0.375, 0.609, 0]}>
              <mesh geometry={nodes.Body11108.geometry} material={materials['Opaque(25,25,25)']} scale={0.394} />
            </group>
            <group position={[-0.375, 0.411, 0]} rotation={[Math.PI / 2, 0, 1.654]}>
              <mesh geometry={nodes.Body11109.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
            </group>
            <group position={[0.089, 0.264, 0]} rotation={[-0.312, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11107.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body10028.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body11028.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body12027.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body13026.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body14026.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body15025.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body16025.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body2083.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body3043.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body4033.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body5033.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body6033.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body7033.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body8032.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body9028.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
            </group>
            <group position={[0.246, 0.264, 0]} rotation={[-0.223, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11111.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body10029.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body11029.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body12028.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body13027.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body14027.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body15026.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body16026.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body2084.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body3044.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body4034.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body5034.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body6034.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body7034.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body8033.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body9029.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
            </group>
            <group position={[0.162, 0.264, 0]} rotation={[-0.591, -Math.PI / 2, 0]}>
              <mesh geometry={nodes.Body11110.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
            </group>
            <mesh geometry={nodes.Body11106.geometry} material={materials['Opaque(221,232,255)']} scale={0.394} />
          </group>
          <group position={[38.896, -12.881, 21.779]} rotation={[-0.174, -0.015, -1.485]}>
            <group position={[-0.375, 0.609, 0]}>
              <mesh geometry={nodes.Body11102.geometry} material={materials['Opaque(25,25,25)']} scale={0.394} />
            </group>
            <group position={[-0.375, 0.411, 0]} rotation={[Math.PI / 2, 0, 1.654]}>
              <mesh geometry={nodes.Body11103.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
            </group>
            <group position={[0.089, 0.264, 0]} rotation={[-0.312, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11101.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body10026.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body11026.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body12025.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body13024.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body14024.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body15023.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body16023.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body2081.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body3041.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body4031.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body5031.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body6031.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body7031.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body8030.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body9026.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
            </group>
            <group position={[0.246, 0.264, 0]} rotation={[-0.223, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Body11105.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body10027.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body11027.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body12026.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body13025.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body14025.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body15024.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body16024.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body2082.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body3042.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body4032.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body5032.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body6032.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body7032.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body8031.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
              <mesh geometry={nodes.Body9027.geometry} material={materials['Opaque(229,234,237)']} scale={0.394} />
            </group>
            <group position={[0.162, 0.264, 0]} rotation={[-0.591, -1.571, 0]}>
              <mesh geometry={nodes.Body11104.geometry} material={materials['Opaque(166,158,150)']} scale={0.394} />
            </group>
            <mesh geometry={nodes.Body11100.geometry} material={materials['Opaque(221,232,255)']} scale={0.394} />
          </group>
        </group>
        <group rotation={[-Math.PI, 0, -0.087]}>
          <group position={[38.8, -17.551, 6.604]} rotation={[-0.174, -0.015, 0.086]}>
            <mesh geometry={nodes.Body1919.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[20.868, -19.119, 6.604]} rotation={[-0.174, -0.015, 0.086]}>
            <mesh geometry={nodes.Body1918.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[38.558, -14.783, 22.361]} rotation={[-0.174, -0.015, 0.086]}>
            <mesh geometry={nodes.Body1921.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[20.626, -16.352, 22.361]} rotation={[-0.174, -0.015, 0.086]}>
            <mesh geometry={nodes.Body1920.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[44.617, -15.2, 11.612]} rotation={[2.968, 0.015, -0.086]}>
            <mesh geometry={nodes.Body1900.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
          </group>
          <group position={[21.958, -18.664, 3.241]} rotation={[Math.PI / 2, -1.484, 0.175]}>
            <mesh geometry={nodes.Body1895.geometry} material={materials['Opaque(221,232,255)']} scale={0.394} />
          </group>
          <group position={[37.648, -17.291, 3.241]} rotation={[-Math.PI / 2, 1.484, 2.967]}>
            <mesh geometry={nodes.Body1896.geometry} material={materials['Opaque(221,232,255)']} scale={0.394} />
          </group>
          <group position={[18.12, -19.261, 1.764]} rotation={[-Math.PI, 0, -1.658]}>
            <mesh geometry={nodes.Body1906.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[41.531, -17.213, 1.764]} rotation={[0, 0, 1.658]}>
            <mesh geometry={nodes.Body1905.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[41.282, -17.235, 1.764]} rotation={[-Math.PI, 0, -0.087]}>
            <mesh geometry={nodes.Body1907.geometry} material={materials['Opaque(76,76,76)']} scale={0.394} />
          </group>
          <group position={[44.995, -16.648, 3.241]} rotation={[-1.746, -0.086, -0.015]}>
            <mesh geometry={nodes.Body1892.geometry} material={materials['Millennium Falcon Green']} scale={0.394} />
          </group>
          <group position={[44.179, -13.059, 23.922]} rotation={[1.396, 0.086, 0.015]}>
            <mesh geometry={nodes.Body1893.geometry} material={materials['Millennium Falcon Green']} scale={0.394} />
          </group>
          <group position={[21.958, -18.664, 3.241]} rotation={[Math.PI / 2, -1.484, 0.175]}>
            <mesh geometry={nodes.Body1894.geometry} material={materials['Opaque(221,232,255)']} scale={0.394} />
          </group>
          <group position={[37.648, -17.291, 3.241]} rotation={[-Math.PI / 2, 1.484, 2.967]}>
            <mesh geometry={nodes.Body1897.geometry} material={materials['Opaque(221,232,255)']} scale={0.394} />
          </group>
          <group position={[36.941, -18.305, 5.058]} rotation={[2.968, 0.015, 3.056]}>
            <mesh geometry={nodes.Body1898.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[36.748, -16.098, 4.667]} rotation={[2.968, 0.015, 3.056]}>
            <mesh geometry={nodes.Body1899.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[36.759, -16.221, 4.689]} rotation={[2.968, 0.015, 3.056]}>
            <mesh geometry={nodes.Body1903.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[20.02, -14.297, 23.616]} rotation={[2.968, 0.015, -0.086]}>
            <mesh geometry={nodes.Body1923.geometry} material={materials['Millennium Falcon Green']} scale={0.394} />
          </group>
          <group position={[38.955, -14.444, 23.933]} rotation={[2.968, 0.015, 3.056]}>
            <mesh geometry={nodes.Body1922.geometry} material={materials['Millennium Falcon Green']} scale={0.394} />
          </group>
          <group position={[23.032, -19.471, 3.907]} rotation={[2.968, 0.015, -0.086]}>
            <mesh geometry={nodes.Body1904.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[21.207, -17.248, 11.612]} rotation={[2.968, 0.015, -1.657]}>
            <mesh geometry={nodes.Body1901.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[37.893, -15.788, 11.612]} rotation={[2.968, 0.015, -1.657]}>
            <mesh geometry={nodes.Body1902.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[39.356, -18.167, 3.929]} rotation={[2.968, 0.015, 3.056]}>
            <mesh geometry={nodes.Body1910.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[20.235, -17.615, 3.538]} rotation={[2.968, 0.015, -0.086]}>
            <mesh geometry={nodes.Body1911.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[39.173, -16.082, 3.56]} rotation={[2.968, 0.015, 3.056]}>
            <mesh geometry={nodes.Body1912.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[20.417, -19.7, 3.907]} rotation={[2.968, 0.015, -0.086]}>
            <mesh geometry={nodes.Body1913.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[42.09, -23.607, -0.975]} rotation={[-Math.PI, 0, -1.658]}>
            <mesh geometry={nodes.Body1914.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[18.431, -25.677, -0.975]} rotation={[-Math.PI, 0, -1.658]}>
            <mesh geometry={nodes.Body1915.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[41.78, -17.191, 1.764]} rotation={[-Math.PI, 0, -0.087]}>
            <mesh geometry={nodes.Body1916.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[17.747, -19.294, 1.764]} rotation={[-Math.PI, 0, -0.087]}>
            <mesh geometry={nodes.Body1917.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[20.478, -14.653, 26.63]} rotation={[-1.746, -0.086, -1.586]}>
            <mesh geometry={nodes.Body1886.geometry} material={materials['Millennium Falcon Green']} scale={0.394} />
          </group>
          <group position={[38.409, -13.085, 26.63]} rotation={[1.396, 0.086, 1.586]}>
            <mesh geometry={nodes.Body1887.geometry} material={materials['Millennium Falcon Green']} scale={0.394} />
          </group>
          <group position={[38.145, -18.672, 0.952]} rotation={[2.968, 0.015, -0.086]}>
            <mesh geometry={nodes.Body1908.geometry} material={materials['Millennium Falcon Green']} scale={0.394} />
          </group>
          <group position={[20.712, -20.197, 0.952]} rotation={[2.968, 0.015, -0.086]}>
            <mesh geometry={nodes.Body1909.geometry} material={materials['Millennium Falcon Green']} scale={0.394} />
          </group>
          <group position={[38.856, -12.449, 24.241]} rotation={[-Math.PI / 2, 1.484, 1.396]}>
            <mesh geometry={nodes.Body1888.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[19.917, -13.982, 24.219]} rotation={[Math.PI / 2, -1.484, -1.396]}>
            <mesh geometry={nodes.Body1890.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[39.038, -14.534, 24.61]} rotation={[-Math.PI / 2, 1.484, 1.396]}>
            <mesh geometry={nodes.Body1889.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
          <group position={[20.099, -16.067, 24.588]} rotation={[Math.PI / 2, -1.484, -1.396]}>
            <mesh geometry={nodes.Body1891.geometry} material={materials['Powder Coat (Black)']} scale={0.394} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/MShredder/MShredder.gltf')
